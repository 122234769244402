import PropTypes from 'prop-types';
import React from 'react';

import { makeStyles } from '@material-ui/core';

import {
  ArrayInput,
  Edit,
  FormTab,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import EditToolbar from '../../../../common/EditToolbar';
import PageTitle from '../PageTitle';
import TabbedFormTabs from '../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../layout/VerticalTabbedForm';
import MyEdit from '../../../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
}));

const ClassPageEditForm = ({ lang, ...props }) => {

  const classes = useStyles();

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm
        toolbar={<EditToolbar />}
        redirect={false}
        tabs={<TabbedFormTabs />}
        variant="outlined"
      >
        <FormTab label="Général">
          <TextInput disabled source="id" />

          <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Hero">
          <TextInput source={`sections.hero.cta.description.${lang}`} label="CTA Description" className={classes.textInput} />
          <TextInput source={`sections.hero.cta.descriptionBlock.${lang}`} label="Texte du block CTA" className={classes.textInput} />
          <TextInput source={`sections.hero.cta.labelAllAccess.${lang}`} label="Label Pass" className={classes.textInput} />
          <TextInput source={`sections.hero.cta.labelClass.${lang}`} label="Label Cours seul" className={classes.textInput} />
          <TextInput source={`sections.hero.cta.labelPreSale.${lang}`} label="Label Pré-vente" className={classes.textInput} />
          <TextInput source={`sections.hero.cta.labelSecondary.${lang}`} label="Label Bouton secondaire" className={classes.textInput} initialValue="Je commence gratuitement" />
          <TextInput source={`sections.hero.cta.labelTrailerButton.${lang}`} label="Label Bouton Bande-Annonce" className={classes.textInput} initialValue="Regarder la bande annonce" />
        </FormTab>

        <FormTab label="Bande annonce">
          <TextInput source={`sections.thriller.title.${lang}`} label="Titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Mentor">
          <TextInput source={`sections.mentor.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.mentor.cta.title.${lang}`} label="Titre du bloc CTA" className={classes.textInput} initialValue="Prêt à démarrer une session ?" />
        </FormTab>

        <FormTab label="Récapitulatif">
          <TextInput source={`sections.why.title.${lang}`} label="Titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Promesses">
          <TextInput source={`sections.promises.title.${lang}`} label="Titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Programme">
          <TextInput source={`sections.lessons.title.${lang}`} label="Titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="FAQ">
          <TextInput source={`sections.faq.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.faq.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
          <ArrayInput source="sections.faq.items" label="Liste des questions" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`question.${lang}`} label="Question" className={classes.textInput} />
              <TextInput source={`answer.${lang}`} label="Réponse" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Pass">
          <TextInput source={`sections.pass.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.pass.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />

          <ArrayInput source="sections.pass.items" label="Liste des items" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
              <TextInput source={`text.${lang}`} label="Texte" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>

          <TextInput source={`sections.pass.cta.label.${lang}`} label="Libellé bouton CTA" className={classes.textInput} />
          <TextInput source={`sections.pass.cta.description.${lang}`} label="Description CTA" className={classes.textInput} />
        </FormTab>

        <FormTab label="Autres cours">
          <TextInput source={`sections.otherClasses.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.otherClasses.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Ressources">
          <TextInput source={`sections.resources.title.${lang}`} label="Titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Partage">
          <TextInput source={`sections.share.title.${lang}`} label="Titre" className={classes.textInput} />
        </FormTab>

      </VerticalTabbedForm>
    </MyEdit>
  );
};

ClassPageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default ClassPageEditForm;
