import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';

import { AutocompleteInput, CheckboxGroupInput, Edit, NumberInput, ReferenceInput, SimpleForm, TextInput } from 'react-admin';

import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';
import MyEdit from '../../layout/MyEdit';
import Config from '../../../config';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    height: '2rem',
  },
}));

const TestimonialTitle = ({ record }) => {
  return <span>Témoignage de {record ? `"${record.name}"` : ''}</span>;
};

TestimonialTitle.propTypes = {
  record: PropTypes.any,
};

TestimonialTitle.defaultProps = {
  record: null,
};

const MsplusTestimonialsEdit = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  return (
    <MyEdit {...props} title={<TestimonialTitle />} actions={Actions} className="edit-wrapper">
      <SimpleForm key={lang} toolbar={<EditToolbar withDelete />} redirect={false} variant="outlined">
        <TextInput disabled source="id" label="ID" />
        <TextInput source="name" label="Nom" className={classes.textInput} />
        <TextInput multiline source={`text.${lang}`} label={`Texte (${lang})`} className={classes.textInput} />
        <NumberInput source="rating" label="Note (/5)" className={classes.textInput} />

        <ReferenceInput label="Cours" source="classId" reference="content/mentorshow/classes" filterToQuery={(searchText) => (searchText ? { mentor: { name: searchText } } : null)}>
          <AutocompleteInput optionText="mentor.name" />
        </ReferenceInput>

        <Typography variant="body1" className={classes.separator}>
          Sélectionnez les langues pour lesquelles vous voulez afficher ce cours.
        </Typography>
        <CheckboxGroupInput
          source="languages"
          label=""
          choices={Config.supportedLocales.map((locale) => ({ id: locale, name: locale.toUpperCase() }))}
          row={false}
          helperText="Si aucune case n'est cochée, alors l'élément ne sera jamais affiché sur le site."
        />
      </SimpleForm>
    </MyEdit>
  );
};

MsplusTestimonialsEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export default MsplusTestimonialsEdit;
