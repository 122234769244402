import PropTypes from 'prop-types';
import React from 'react';
import RichTextInput from 'ra-input-rich-text';
import { Typography, makeStyles } from '@material-ui/core';
import { ArrayInput, AutocompleteInput, BooleanInput, CheckboxGroupInput, DateInput, FileField, FileInput, FormTab, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleFormIterator, TextInput, required } from 'react-admin';
import { useSelector } from 'react-redux';

import Config from '../../../config';
import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';
import TabbedFormTabs from '../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../layout/VerticalTabbedForm';
import MyEdit from '../../layout/MyEdit';
import MyClassEdit from '../../layout/MyClassEdit';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  sectionTitle: {
    fontSize: 16,
    marginTop: 30,
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
    width: '100%',
  },
}));

const PageTitle = ({ record }) => {
  return <span>Coach {record ? `"${record.mentor?.name} ${record.mentor?.teaches?.fr}"` : ''}</span>;
};

PageTitle.propTypes = {
  record: PropTypes.any,
};

PageTitle.defaultProps = {
  record: null,
};

const CoachEdit = (props) => {
  const { basePath, record } = props;

  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  /* React.useEffect(async () => {
    const lists = await fetch('https://mentorshow.api-us1.com/api/3/lists', {
      headers: {
        'Api-Token': '64371e1e644b5f359f72f69d4ee55a15896f14c7ce75c364b2190d2cf0fe75330dc55c98',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

    console.log('lists', lists);
  }, []); */

  const Actions = <PageEditActions basePath={basePath} record={record} />;

  return (
    <MyClassEdit {...props} title={<PageTitle />} actions={Actions} className="edit-wrapper">
      <VerticalTabbedForm key={lang} toolbar={<EditToolbar />} tabs={<TabbedFormTabs />} variant="outlined">
        <FormTab label="Général">
          <TextInput source="id" validate={[required()]} disabled/>
          <TextInput source="name" validate={[required()]}/>
          <TextInput source="intro" />

          <RichTextInput source={`description.${lang}`} label="Full description" style={{ width: '100%' }} validate={[required()]} />

          <ArrayInput source="categories" label="Categories" style={{ width: '100%' }} validate={[required()]}>
            <SimpleFormIterator>
              <ReferenceInput label="Catégorie" reference="content/msplus/categories" filterToQuery={(searchText) => (searchText ? { [`name.${lang}`]: searchText } : null)}>
                <AutocompleteInput optionText={`name.${lang}`} />
              </ReferenceInput>            
            </SimpleFormIterator>
          </ArrayInput>

          <ImageInput source="bannerImage" label="Image Banner" accept="image/*" validate={[required()]}>
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput source="cardImage" label="Image Coach" accept="image/*" validate={[required()]}>
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Calendly">
          <TextInput source="calendly.username" label="Calendly Username" validate={[required()]}/>
          <TextInput source="calendly.eventType" label="Calendly EventType" validate={[required()]}/>
          <TextInput source="calendly.description" label="Description (above calendly)" />
        </FormTab>
      </VerticalTabbedForm>
    </MyClassEdit>
  );
};

CoachEdit.propTypes = {
  basePath: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  record: PropTypes.any,
};

CoachEdit.defaultProps = {
  record: null,
};

export default CoachEdit;
