import React from 'react';
import { Title } from 'react-admin';

import PeopleIcon from '@material-ui/icons/People';
import ForumIcon from '@material-ui/icons/Forum';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import DevicesIcon from '@material-ui/icons/Devices';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';

import BrowsersStatsTable from '../components/dashboard/BrowserStatsTable/BrowserStatsTable';
import Card from '../components/dashboard/StatOverviewCard/StatOverviewCard';
import { useFirebase } from '../firebase';
import PageViewsStatsTable from '../components/dashboard/PageViewsStatsTable';

const styles = {
  container: { display: 'flex', width: '100%', marginTop: -80 },
  dashboardBox: {padding: '74px 8px', backgroundColor: 'rgb(28, 28, 56)', height: '284px'},
  dashboardBoxText: { textAlign: 'center', color: 'rgb(255, 255, 255)'},
  flex: { display: 'flex', width: '100%' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const DashboardPage = () => {
  const [stats, setStats] = React.useState({});
  const [checkoutStats, setChekcoutStats] = React.useState([]);
  const [usersWithBrowsers1Percent, setUsersWithBrowsers1Percent] = React.useState(null);
  const [usersWithBrowsers2Percent, setUsersWithBrowsers2Percent] = React.useState(null);
  const [usersWithBrowsers3Percent, setUsersWithBrowsers3Percent] = React.useState(null);
  const [usersWithBrowsersAuth1Percent, setUsersWithBrowsersAuth1Percent] = React.useState(null);
  const [usersWithBrowsersAuth2Percent, setUsersWithBrowsersAuth2Percent] = React.useState(null);
  const [usersWithBrowsersAuth3Percent, setUsersWithBrowsersAuth3Percent] = React.useState(null);
  const [globalRating, setGlobalRating] = React.useState(null);

  const firebase = useFirebase();

  React.useEffect(() => {
    const getStats = async () => {
      const adminStatsSnapshot = await firebase.adminStats().get();
      const adminStats = adminStatsSnapshot.data();
      setStats(adminStats);

      const pagesStatsSnapshot = await firebase.pageCheckoutStats().get();
      const pagesCheckoutConfirmStatsSnapshot = await firebase.pageCheckoutConfirmStats().get();
      const pagesSignupSnapshot = await firebase.pageSignupStats().get();
      const newCheckoutStats = [];
      for (const stat of pagesStatsSnapshot.docs) {
        const dataToPush = {
          ...stat.data(),
        }

        const signupViews = pagesSignupSnapshot.docs.find((i) => {
          return i.data().dateStr === stat.data().dateStr;
        });

        if (signupViews?.exists) {
          dataToPush.views_signup = signupViews.data().views;
        }

        const checkoutConfirmViews = pagesCheckoutConfirmStatsSnapshot.docs.find((i) => {
          return i.data().dateStr === stat.data().dateStr;
        });

        if (checkoutConfirmViews?.exists) {
          dataToPush.views_checkoutConfirm = checkoutConfirmViews.data().views;
        }

        newCheckoutStats.push(dataToPush);
      }
      for (const stat of pagesSignupSnapshot.docs) {
        if (newCheckoutStats.findIndex((i) => i.dateStr === stat.dateStr) < -1) {
          const dataToPush = {
            date: stat.data().date,
            dateStr: stat.data().dateStr,
            views_signup: stat.data().views,
          };
          newCheckoutStats.push(dataToPush);
        }
      }
      for (const stat of pagesCheckoutConfirmStatsSnapshot.docs) {
        if (newCheckoutStats.findIndex((i) => i.dateStr === stat.dateStr) < -1) {
          const dataToPush = {
            date: stat.data().date,
            dateStr: stat.data().dateStr,
            views_checkoutConfirm: stat.data().views,
          };
          newCheckoutStats.push(dataToPush);
        }
      }
      setChekcoutStats(newCheckoutStats.sort((a, b) => a.date < b.date ? 1 : -1));

      if (adminStats.usersWithBrowsers1) {
        setUsersWithBrowsers1Percent(((adminStats.usersWithBrowsers1 / adminStats.nbAllUsers) * 100).toFixed(2));
      }
      if (adminStats.usersWithBrowsers2) {
        setUsersWithBrowsers2Percent(((adminStats.usersWithBrowsers2 / adminStats.nbAllUsers) * 100).toFixed(2));
      }
      if (adminStats.usersWithBrowsers3) {
        setUsersWithBrowsers3Percent(((adminStats.usersWithBrowsers3 / adminStats.nbAllUsers) * 100).toFixed(2));
      }

      if (adminStats.usersWithBrowsersAuth1) {
        setUsersWithBrowsersAuth1Percent(((adminStats.usersWithBrowsersAuth1 / adminStats.nbAllUsers) * 100).toFixed(2));
      }
      if (adminStats.usersWithBrowsersAuth2) {
        setUsersWithBrowsersAuth2Percent(((adminStats.usersWithBrowsersAuth2 / adminStats.nbAllUsers) * 100).toFixed(2));
      }
      if (adminStats.usersWithBrowsersAuth3) {
        setUsersWithBrowsersAuth3Percent(((adminStats.usersWithBrowsersAuth3 / adminStats.nbAllUsers) * 100).toFixed(2));
      }
    };

    getStats();
  }, [firebase]);

  React.useEffect(() => {
    const getRatings = async () => {
      const globalRatingsSnapshot = await firebase.globalRatings().get();
      const globalRatingsData = globalRatingsSnapshot.data();

      setGlobalRating((globalRatingsData.totalPoints / globalRatingsData.nbRatings).toFixed(1));
    };

    getRatings();
  }, [firebase]);

  console.log('checkoutStats', checkoutStats);

  return (
    <>
      <Title title="MentorShow Administration" />

      <section style={styles.dashboardBox}>
        <div style={styles.dashboardBoxText}>
          <h2 style={{ fontWeight: 400, fontSize: 32 }}>Administration MentorShow</h2>
        </div>
      </section>

      <div style={styles.container}>
        {!!stats && !!stats.nbAllUsers && (
          <div style={styles.flex}>
            <Card label="Nombre total d'utilisateurs" value={stats.nbAllUsers} icon={PeopleIcon} link="/users" />
            <Card label="Note globale" value={`${globalRating || 0} / 5`} icon={VideoLabelIcon} />
            <Card label="Utilisateurs avec 2 app" value={`${stats.usersWithBrowsers2 || 0}  (${usersWithBrowsers2Percent || 0}%)`} icon={DevicesIcon} />
            <Card label="Utilisateurs avec 3 app" value={`${stats.usersWithBrowsers3 || 0}  (${usersWithBrowsers3Percent || 0}%)`} icon={DevicesOtherIcon} />
            <Card label="Commentaires non revus" value={stats.unreviewedComments} icon={ForumIcon} link="/comments" />
          </div>
        )}
      </div>

      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <BrowsersStatsTable
            title="Multi-navigateurs"
            subtitle="Utilisateurs se connectant depuis plusieurs navigateurs"
            rows={[
              { label: '1 navigateur', number: stats.usersWithBrowsersAuth1 || 0, percent: usersWithBrowsersAuth1Percent || 0 },
              { label: '2 navigateurs', number: stats.usersWithBrowsersAuth2 || 0, percent: usersWithBrowsersAuth2Percent || 0 },
              { label: '3 navigateurs', number: stats.usersWithBrowsersAuth3 || 0, percent: usersWithBrowsersAuth3Percent || 0 },
            ]}
          />
        </div>

        <div style={styles.rightCol}>
          <BrowsersStatsTable
            title="Multi-navigateurs en visionnage"
            subtitle="Utilisateurs regardant des cours depuis plusieurs navigateurs simultannément"
            rows={[
              { label: '1 navigateur', number: stats.usersWithBrowsers1 || 0, percent: usersWithBrowsers1Percent || 0 },
              { label: '2 navigateurs', number: stats.usersWithBrowsers2 || 0, percent: usersWithBrowsers2Percent || 0 },
              { label: '3 navigateurs', number: stats.usersWithBrowsers3 || 0, percent: usersWithBrowsers3Percent || 0 },
            ]}
          />
        </div>
      </div>

      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <PageViewsStatsTable
            title="Page views"
            subtitle="Par jour sur les 30 derniers jours"
            rows={checkoutStats}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
