import React from 'react';

import {
  Datagrid,
  EditButton,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import MyList from '../../layout/MyList';

const TestimonialsList = (props) => (
  <MyList {...props} title="Témoignages" bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Nom" />
      <TextField source="text.fr" label="Site" />

      <ReferenceField label="Cours" source="classId" reference="content/mentorshow/classes">
        <TextField source="mentor.name" />
      </ReferenceField>
      {/* <EditButton label="" /> */}
    </Datagrid>
  </MyList>
);

export default TestimonialsList;
