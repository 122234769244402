import React from 'react';
// import { Layout } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import Layout from './CustomLayout';
import MyAppBar from './AppBar';
import MyMenu from './Menu';
import MySideBar from './Sidebar';
import UserMenu from './UserMenu';

const useStyles = makeStyles(() => ({
  layout: {
    fontWeight: 100,
    paddingTop: 20,
  },

  content: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 0,
    background: '#f6f7fb',
  }
}));

// const CustomSidebar = (props) => <MySideBar {...props} size={200} />;

const CustomLayout = (props) => {
  const classes = useStyles();

  return (
    <Layout {...props} appBar={MyAppBar} userMenu={UserMenu} sidebar={MySideBar} menu={MyMenu} className={classes.layout} classes={{ content: classes.content }} />
  );
};

export default CustomLayout;
