import PropTypes from 'prop-types';
import React from 'react';
import RichTextInput from 'ra-input-rich-text';

import { Grid, Typography, makeStyles } from '@material-ui/core';

import {
  ArrayInput,
  BooleanInput,
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import Config from '../../../../../config';
import EditToolbar from '../../../../common/EditToolbar';
import PageTitle from '../PageTitle';
import TabbedFormTabs from '../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../layout/VerticalTabbedForm';
import MyEdit from '../../../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
  root: {
    flexGrow: 1,
    width: '100%',
  },
  column: {
    padding: '0 50px',
    border: '1px solid #dedede',
  },
  card: {
    width: '100%',
    margin: '20px auto',
  },
}));

const PassFullAccessWithAndrePageEditForm = ({ lang, ...props }) => {
  const classes = useStyles();

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm
        toolbar={<EditToolbar />}
        redirect={false}
        tabs={<TabbedFormTabs />}
        variant="outlined"
      >
        <FormTab label="Général">
          <TextInput disabled source="id" />
        </FormTab>

        <FormTab label="SEO">
          <div className={classes.separator} />
          <Typography variant="subtitle1" gutterBottom>SEO</Typography>

          <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} />

          <ImageInput source="seo.image" label="Photo d'aperçu SEO" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Offre">
          <Typography variant="subtitle1" gutterBottom className={classes.separator}>Thrivecart</Typography>
          <TextInput source={`offers.andre.thriveProductId.${lang}`} label={`ID Produit ThriveCart (${lang})`} className={classes.textInput} helperText={`ID produit ThriveCart (opération spéciale) (${lang})`} />

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>Checkout</Typography>
          <RichTextInput source={`offers.andre.checkout.intro.${lang}`} label={`Intro (${lang})`} className={classes.textInput} toolbar={Config.richTextToolbar} />
        </FormTab>

        <FormTab label="Hero">

          <TextInput source={`sections.hero.title.${lang}`} multiline label="Titre" className={classes.textInput} />

          <TextInput source={`sections.hero.description.${lang}`} multiline label="Description" className={classes.textInput} />

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>Vidéo</Typography>
          <TextInput source="sections.hero.videoId" label="ID Vidéo Bande annonce" className={classes.textInput} />

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>CTA</Typography>

          <TextInput source={`sections.hero.cta.description.${lang}`} label="CTA Description" className={classes.textInput} />
          <TextInput source={`sections.hero.cta.label.${lang}`} label="Button label" className={classes.textInput} />

          <ArrayInput source="sections.hero.medias" label="Logos Médias" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="image" label="Photo" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>

          <ArrayInput source={`sections.hero.testimonials.${lang}`} label="Témoignages" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="text" label="Texte" className={classes.textInput} />
              <TextInput source="author" label="Auteur" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Cours">
          <TextInput source={`sections.classes.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.classes.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Pricing">
          <TextInput source={`sections.pricing.badgeText.${lang}`} label="Texte du badge" className={classes.textInput} />
          <TextInput source={`sections.pricing.title.${lang}`} label="Titre" className={classes.textInput} />

          <Grid container className={classes.root} spacing={5}>
            <Grid item xs={6} className={classes.column}>
              <BooleanInput source="sections.pricing.monthly.active" label="Actif" />
              <BooleanInput source="sections.pricing.monthly.isFeatured" label="Mis en avant (populaire)" />
              <TextInput source={`sections.pricing.monthly.price.${lang}`} label="Tarif (€)" className={classes.textInput} />
              <TextInput source={`sections.pricing.monthly.periodicity.${lang}`} label="Label périodicité (par mois)" className={classes.textInput} />
              <TextInput source={`sections.pricing.monthly.label.${lang}`} label="Label tarification" className={classes.textInput} />

              <ArrayInput source="sections.pricing.monthly.includes" label="Mensuel" style={{ width: '100%' }}>
                <SimpleFormIterator>
                  <TextInput source={`text.${lang}`} label="Description" className={classes.textInput} />
                </SimpleFormIterator>
              </ArrayInput>

              <TextInput source={`sections.pricing.monthly.buttonLabel.${lang}`} label="Libellé bouton" className={classes.textInput} />
            </Grid>

            <Grid item xs={6} className={classes.column}>
              <BooleanInput source="sections.pricing.yearly.active" label="Actif" />
              <BooleanInput source="sections.pricing.yearly.isFeatured" label="Mis en avant (populaire)" />
              <TextInput source={`sections.pricing.yearly.price.${lang}`} label="Tarif (€)" className={classes.textInput} />
              <TextInput source={`sections.pricing.yearly.periodicity.${lang}`} label="Label périodicité (par mois)" className={classes.textInput} />
              <TextInput source={`sections.pricing.yearly.label.${lang}`} label="Label tarification" className={classes.textInput} />

              <ArrayInput source="sections.pricing.yearly.includes" label="Mensuel" style={{ width: '100%' }}>
                <SimpleFormIterator>
                  <TextInput source={`text.${lang}`} label="Description" className={classes.textInput} />
                </SimpleFormIterator>
              </ArrayInput>
              <TextInput source={`sections.pricing.yearly.buttonLabel.${lang}`} label="Libellé bouton" className={classes.textInput} />
            </Grid>
          </Grid>
        </FormTab>

        <FormTab label="Catégories">
          <TextInput source={`sections.categories.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.categories.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />

          <ArrayInput source="sections.categories.items" label="Items" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="image" label="Icône" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Arguments">
          <TextInput source={`sections.arguments.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.arguments.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />

          <ArrayInput source="sections.arguments.items" label="Descriptions" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
              <TextInput source={`text.${lang}`} label="Texte" className={classes.textInput} />
              <ImageInput source="image" label="Photo d'aperçu" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </VerticalTabbedForm>
    </MyEdit>
  );
};

PassFullAccessWithAndrePageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default PassFullAccessWithAndrePageEditForm;
