const initialState = {
  lang: 'fr',
};

export default (state = initialState, { type, payload }) => {
  if (type === 'SET_LANG') {
    return {
      ...state,
      lang: payload,
    };
  }
  return state;
};
