import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import {
  EditController,
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// import ApproveButton from './utils/ApproveButton';
import { useFirebase } from '../../../firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 60,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      width: 400,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30,
    },
  },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
  repliesWrapper: {
    width: 400,
    padding: '0 10px 30px',
  },
  replyItem: {
    margin: '0 0 20px',
    padding: '10px 20px',
    background: '#ecf4f7',
    borderRadius: '10px',
  },
}));

const CommentEdit = ({ onCancel, ...props }) => {

  const [replies, setReplies] = React.useState([]);

  const { id: commentId } = props;

  const classes = useStyles();
  const firebase = useFirebase();

  React.useEffect(() => {
    const newReplies = [];
    const getReplies = async () => {
      console.log('mounted');
      const query = firebase.comments().where('parentId', '==', commentId).orderBy('date', 'asc');
      const resultsSnap = await query.get();

      resultsSnap.forEach((replyRef) => {
        const reply = replyRef.data();
        newReplies.push({
          id: replyRef.id,
          ...reply,
        });
      });

      setReplies(newReplies);
    };

    getReplies();
  }, [commentId, firebase]);

  return (
    <EditController {...props}>
      {(controllerProps) => (
        controllerProps.record ? (
          <div className={classes.root}>
            <div className={classes.title}>
              <Typography variant="subtitle1">
                Commentaire de {controllerProps.record.user.firstName}
              </Typography>
              <IconButton onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </div>

            {/* <ApproveButton {...props} record={controllerProps.record} /> */}

            <div className={classes.repliesWrapper}>
              <Typography style={{ fontSize: 12, color: '#777' }}>{moment(controllerProps.record.date).format('DD/MM/YYYY à H:mm:s')}</Typography>
              <Typography>{controllerProps.record.text}</Typography>
            </div>

            {/* <SimpleForm
              className={classes.form}
              basePath={controllerProps.basePath}
              record={controllerProps.record}
              save={controllerProps.save}
              version={controllerProps.version}
              redirect="list"
              resource="reviews"
              toolbar={null}
            >
              <DateField
                source="date"
                formClassName={classes.inlineField}
              />

              <TextInput multiline source="text" rowsMax={15} />
            </SimpleForm> */}

            <div className={classes.repliesWrapper}>
              {replies.map((reply, index) => (
                <div className={classes.replyItem} key={index}>
                  <Typography style={{ margin: 0, fontWeight: '500' }}>{reply.user.firstName} {reply.user.lastName}</Typography>
                  <Typography style={{ fontSize: 12, color: '#777' }} className="muted">{moment(reply.date).format('DD/MM/YYYY à H:mm:s')}</Typography>
                  <Typography style={{ marginTop: 10 }}>{reply.text}</Typography>
                </div>
              ))}
            </div>
          </div>
        ) : null
      )}
    </EditController>
  );
};

CommentEdit.propTypes = {
  firebase: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CommentEdit;
