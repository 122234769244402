import React from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  List,
  NumberField,
  TextField,
  TextInput,
} from 'react-admin';
import { useSelector } from 'react-redux';
import MyList from '../../layout/MyList';

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Rechercher une catégorie" source="name" alwaysOn />
  </Filter>
);

const CategoryList = (props) => {
  const { lang } = useSelector((state) => state.app);

  return (
    <MyList {...props} title="Catégories" filters={<PageFilter />} bulkActionButtons={false} sort={{ field: 'order', order: 'ASC' }}>
      <Datagrid rowClick="edit">
        <TextField source={`name.${lang}`} label="Nom" />
        <NumberField source="order" label="Rang" />
        {/* <EditButton label="" /> */}
        <DeleteButton label="" redirect={false} undoable={false} />
      </Datagrid>
    </MyList>
  );
};

export default CategoryList;
