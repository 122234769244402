import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  crudGetList as crudGetListAction,
} from 'react-admin';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    padding: '1em',
    marginBottom: '1em',
  },
  rightAlignedCell: { textAlign: 'right' },
  boldCell: { fontWeight: 'bold' },
}));

const UserTransactionsList = ({
  crudGetList, record, transactions,
}) => {

  const classes = useStyles();

  React.useEffect(() => {
    if (!record) {
      return;
    }

    crudGetList('transactions', { page: 1, perPage: 100 }, { field: 'createdAt', order: 'DESC' }, { userId: record.id });
  }, [record, crudGetList]);

  if (!record) {
    return null;
  }

  return (
    <Paper className={classes.container} style={{ borderRadius: 0 }}>
      <Typography variant="subtitle1" gutterBottom>Transactions</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>Date</Typography>
            </TableCell>
            <TableCell className={classes.rightAlignedCell}>
              <Typography>Produit</Typography>
            </TableCell>
            <TableCell className={classes.rightAlignedCell}>
              <Typography>Montant</Typography>
            </TableCell>
            <TableCell className={classes.rightAlignedCell}>
              <Typography>Statut</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions && transactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography>{transaction.date.toLocaleString()}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{transaction.product}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{`${transaction.amount.toFixed(2)}€`}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{transaction.status}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

UserTransactionsList.propTypes = {
  crudGetList: PropTypes.func.isRequired,
  record: PropTypes.object,
  transactions: PropTypes.array,
};

UserTransactionsList.defaultProps = {
  record: null,
  transactions: [],
};

const mapStateToProps = (state) => {
  const transactions = state.admin.resources.transactions && Object.keys(state.admin.resources.transactions.data)
    .map((key) => {

      const transaction = state.admin.resources.transactions.data[key];

      if (!transaction.id) {
        return null;
      }

      if (transaction.plan.id && transaction.plan.id === 'pass') {
        return {
          product: transaction.plan.nickname,
          amount: transaction.plan.amount / 100,
          date: transaction.createdAt,
          status: transaction.status,
        };
      }

      return {
        product: transaction.plan.nickname,
        amount: transaction.plan.amount / 100,
        date: transaction.createdAt,
        status: transaction.status,
      };
    })
    .filter((item) => !!item);

  return {
    transactions,
  };
};

export default connect(mapStateToProps, { crudGetList: crudGetListAction })(
  UserTransactionsList,
);
