import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import { TopToolbar, ShowButton } from 'react-admin';

import Config from '../../../config';

const PageShowActions = ({
  basePath, data, /* resource, */
}) => {
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.app);

  const onLangChange = (event) => {
    dispatch({
      type: 'SET_LANG',
      payload: event.target.value,
    });
  };

  return (
    <TopToolbar style={{ paddingRight: '30px' }}>
      {/* <ShowButton basePath={basePath} record={data} /> */}

      <FormControl style={{ minWidth: '5em', marginLeft: '1em' }}>
        <InputLabel id="language-select-label">Langue</InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select"
          value={appData.lang}
          onChange={onLangChange}
          variant="standard"
        >
          {Config.supportedLocales.map((locale) => (
            <MenuItem key={locale} value={locale}>{locale.toUpperCase()}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </TopToolbar>
  );
};

PageShowActions.propTypes = {
  basePath: PropTypes.any,
  data: PropTypes.any,
  // resource: PropTypes.any.isRequired,
};

PageShowActions.defaultProps = {
  basePath: null,
  data: null,
};

export default PageShowActions;
