import PropTypes from 'prop-types';
import React from 'react';
import { FormTab, ImageInput, ImageField, TextInput } from 'react-admin';
import { Typography, makeStyles } from '@material-ui/core';

import EditToolbar from '../../../../common/EditToolbar';
import PageTitle from '../PageTitle';
import TabbedFormTabs from '../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../layout/VerticalTabbedForm';
import MyEdit from '../../../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
}));

const AuthPageEditForm = ({ lang, ...props }) => {
  const classes = useStyles();

  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    });
  }, [lang]);

  if (refresh) {
    return null;
  }

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm toolbar={<EditToolbar />} redirect={false} tabs={<TabbedFormTabs />} variant="outlined">
        <FormTab label="Général">
          <TextInput disabled source="id" />
        </FormTab>

        <FormTab label="Inscription">
          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Inscription
          </Typography>
          <TextInput source={`signup.title.${lang}`} label={`Titre de la page (${lang})`} className={classes.textInput} />
          <TextInput source={`signup.subtitle.${lang}`} label={`Titre de la page (${lang})`} className={classes.textInput} />
        </FormTab>

        <FormTab label="Connexion">
          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Connexion
          </Typography>
          {/* <TextInput source={`signin.title.${lang}`} label={`Titre de la page pour le pass (${lang})`} className={classes.textInput} />
          <TextInput source={`signin.subtitle.${lang}`} label={`Titre de la page pour le pass (${lang})`} className={classes.textInput} /> */}
          <ImageInput source={`signin.backgroundImage`} label="Image background" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
      </VerticalTabbedForm>
    </MyEdit>
  );
};

AuthPageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default AuthPageEditForm;
