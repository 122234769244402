import React from 'react';
import { Admin, Resource } from 'react-admin';
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase-lazy-load';

import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import ForumIcon from '@material-ui/icons/Forum';
import DescriptionIcon from '@material-ui/icons/Description';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListIcon from '@material-ui/icons/List';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import RedeemIcon from '@material-ui/icons/Redeem';
import SettingsIcon from '@material-ui/icons/Settings';
import TextsmsIcon from '@material-ui/icons/Textsms';
import AttachMoney from '@material-ui/icons/AttachMoney';

import CustomLoginPage from './components/auth/CustomLoginPage';
import { CategoryCreate, CategoryEdit, CategoryList } from './components/resources/categories';
import { ClassComingSoonCreate, ClassComingSoonEdit, ClassComingSoonList } from './components/resources/classesComingSoon';
import { ClassCreate, ClassEdit, ClassesList } from './components/resources/classes';
import { ProgramCreate, ProgramEdit, ProgramsList } from './components/resources/programs';
import { CommentsList } from './components/resources/comments';
import { CouponCreate, CouponEdit, CouponList } from './components/resources/coupons';
import { GiftList } from './components/resources/gifts';
import { GiftAwaitingList, GiftAwaitingCreate, GiftAwaitingEdit } from './components/resources/giftsAwaiting';
import { MenuEdit, MenuList } from './components/resources/menus';
import { PageList, PageEdit } from './components/resources/pages';
import { SettingsEdit, SettingsList } from './components/resources/settings';
import { StaticPageCreate, StaticPageEdit, StaticPageList } from './components/resources/pages-static';
import { TestimonialsCreate, TestimonialsEdit, TestimonialsList } from './components/resources/testimonials';
import { MsplusTestimonialsCreate, MsplusTestimonialsEdit, MsplusTestimonialsList } from './components/resources/msplusTestimonials';
import { TestimonialsVideoCreate, TestimonialsVideoEdit, TestimonialsVideoList } from './components/resources/testimonialsVideos';
import { TransactionList } from './components/resources/transactions';
import { UserCreate, UserEdit, UserList } from './components/resources/users';
import { MSPlusProgramCreate, MSPlusProgramEdit, MSPlusProgramsList } from './components/resources/msplusPrograms';

import Config from './config';
import CustomReducers from './redux/reducers';
import { useFirebase } from './firebase';

import Dashboard from './pages/Dashboard';
import MyLayout from './components/layout/Layout';

import customRoutes from './customRoutes';
import frMessages from './i18n/fr';

import theme from './theme';

import './styles/list.scss';
import './styles/overrides.scss';
import { CompanyCreate, CompanyEdit, CompanyList } from './components/resources/companies';
import { CoachCreate, CoachEdit, CoachList } from './components/resources/coachs';
import { MSPlusCategoryCreate, MSPlusCategoryEdit, MSPlusCategoryList } from './components/resources/msplusCategories';
import MsplusGroupSessionCreate from './components/resources/msplusGroupSessions/MsplusGroupSessionCreate';
import MsplusGroupSessionList from './components/resources/msplusGroupSessions/MsplusGroupSessionList';
import MsplusGroupSessionEdit from './components/resources/msplusGroupSessions/MsplusGroupSessionEdit';
import MsplusOffersList from './components/resources/msplusOffers/MsplusOffersList';
import MsplusOffersCreate from './components/resources/msplusOffers/MsplusOffersCreate';
import MsplusOffersEdit from './components/resources/msplusOffers/MsplusOffersEdit';

const messages = {
  fr: { ...frenchMessages, ...frMessages },
  en: { ...englishMessages },
};

const App = () => {
  const firebase = useFirebase();

  const config = Config.firebaseConfig;

  const options = {
    app: firebase.instance,
    logging: true,
    useFileNamesInStorage: true,
    // watch: ['content/mentorshow/pages', 'content/mentorshow/classes'],
    persistence: 'local',
    timestamps: false,
    lazyLoading: {
      enabled: true,
    },
    // Logging of all reads performed by app (additional feature, for lazy-loading testing)
    firestoreCostsLogger: {
      enabled: false,
    },
  };

  const dataProvider = FirebaseDataProvider(config, options);
  const authProvider = FirebaseAuthProvider(config, options);
  const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'fr');

  return (
    <>
      <Admin
        authProvider={authProvider}
        customRoutes={customRoutes}
        customReducers={CustomReducers}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        layout={MyLayout}
        theme={theme}
        loginPage={CustomLoginPage}
      >
        {(permissions) => {

          console.log('User permissions', permissions);
          // Check here if permissions.role_admin is true

          return [
            // example of showing a resource based on permission
            permissions.role_admin && <Resource
              name="content/mentorshow/pages"
              icon={InsertDriveFileIcon}
              options={{ label: 'Pages', section: 'mentorshow' }}
              list={PageList}
              edit={PageEdit}
            />,

            <Resource
              name="content/mentorshow/staticPages"
              icon={DescriptionIcon}
              options={{ label: 'Pages Statiques', section: 'mentorshow' }}
              create={StaticPageCreate}
              list={StaticPageList}
              edit={StaticPageEdit}
            />,

            <Resource
              name="content/mentorshow/menus"
              icon={ListIcon}
              options={{ label: 'Menus', section: 'mentorshow' }}
              list={MenuList}
              edit={MenuEdit}
            />,

            <Resource
              name="content/mentorshow/categories"
              icon={ListIcon}
              options={{ label: 'Catégories', section: 'mentorshow' }}
              create={CategoryCreate}
              list={CategoryList}
              edit={CategoryEdit}
            />,

            <Resource
              name="content/mentorshow/classes"
              icon={OndemandVideoIcon}
              options={{ label: 'Cours', section: 'mentorshow' }}
              create={ClassCreate}
              list={ClassesList}
              edit={ClassEdit}
            />,

            <Resource
              name="content/mentorshow/programs"
              icon={OndemandVideoIcon}
              options={{ label: 'Programmes', section: 'mentorshow' }}
              create={ProgramCreate}
              list={ProgramsList}
              edit={ProgramEdit}
            />,

            <Resource
              name="content/mentorshow/classesComingSoon"
              icon={QueuePlayNextIcon}
              options={{ label: 'Bientôt disponible', section: 'mentorshow' }}
              create={ClassComingSoonCreate}
              list={ClassComingSoonList}
              edit={ClassComingSoonEdit}
            />,

            <Resource
              name="content/mentorshow/companies"
              icon={QueuePlayNextIcon}
              options={{ label: 'Entreprises', section: 'mentorshow' }}
              create={CompanyCreate}
              list={CompanyList}
              edit={CompanyEdit}
            />,

            <Resource
              name="content/mentorshow/testimonials"
              icon={TextsmsIcon}
              options={{ label: 'Témoignages', section: 'mentorshow' }}
              create={TestimonialsCreate}
              list={TestimonialsList}
              edit={TestimonialsEdit}
            />,

            <Resource
              name="content/mentorshow/testimonialsVideo"
              icon={TextsmsIcon}
              options={{ label: 'Témoignages vidéo', section: 'mentorshow' }}
              create={TestimonialsVideoCreate}
              list={TestimonialsVideoList}
              edit={TestimonialsVideoEdit}
            />,

            <Resource
              name="comments"
              icon={ForumIcon}
              options={{ label: 'Commentaires', badgeFieldName: 'unreviewedComments', section: 'mentorshow' }}
              list={CommentsList}
            />,

            <Resource
              name="content/msplus/pages"
              icon={InsertDriveFileIcon}
              options={{ label: 'Pages MSPlus', section: 'msplus' }}
              list={PageList}
              edit={PageEdit}
            />,

            <Resource
              name="content/msplus/programs"
              icon={OndemandVideoIcon}
              options={{ label: 'Programmes MSPlus', section: 'msplus' }}
              create={MSPlusProgramCreate}
              list={MSPlusProgramsList}
              edit={MSPlusProgramEdit}
            />,

            <Resource
              name="content/msplus/coachs"
              icon={OndemandVideoIcon}
              options={{ label: 'Coachs', section: 'msplus' }}
              create={CoachCreate}
              list={CoachList}
              edit={CoachEdit}
            />,

            <Resource
              name="content/msplus/group_sessions"
              icon={OndemandVideoIcon}
              options={{ label: 'Sessions de groupe', section: 'msplus' }}
              create={MsplusGroupSessionCreate}
              list={MsplusGroupSessionList}
              edit={MsplusGroupSessionEdit}
            />,

            <Resource
              name="content/msplus/categories"
              icon={ListIcon}
              options={{ label: 'Categories MSPlus', section: 'msplus' }}
              create={MSPlusCategoryCreate}
              list={MSPlusCategoryList}
              edit={MSPlusCategoryEdit}
            />,

            <Resource
              name="content/msplus/testimonials"
              icon={TextsmsIcon}
              options={{ label: 'Témoignages', section: 'msplus' }}
              create={MsplusTestimonialsCreate}
              list={MsplusTestimonialsList}
              edit={MsplusTestimonialsEdit}
            />,

            <Resource
              name="content/msplus/offers_msplus"
              icon={AttachMoney}
              options={{ label: 'Offres MSPlus', section: 'msplus' }}
              create={MsplusOffersCreate}
              list={MsplusOffersList}
              edit={MsplusOffersEdit}
            />,

            <Resource
              name="content/msplus/offers_bundle"
              icon={AttachMoney}
              options={{ label: 'Offres Bundle', section: 'msplus' }}
              create={MsplusOffersCreate}
              list={MsplusOffersList}
              edit={MsplusOffersEdit}
            />,

            <Resource
              name="users"
              icon={PeopleOutlineIcon}
              options={{ label: 'Utilisateurs', section: 'users' }}
              create={UserCreate}
              list={UserList}
              edit={UserEdit}
            />,

            // <Resource
            //   name="transactions"
            //   icon={EuroSymbolIcon}
            //   options={{ label: 'Transactions', section: 'pricing' }}
            //   list={TransactionList}
            // />,

            // <Resource
            //   name="gifts"
            //   icon={RedeemIcon}
            //   options={{ label: 'Cadeaux envoyés', section: 'pricing' }}
            //   list={GiftList}
            // />,

            // <Resource
            //   name="giftsAwaiting"
            //   icon={RedeemIcon}
            //   options={{ label: 'Cadeaux programmés', section: 'pricing' }}
            //   list={GiftAwaitingList}
            //   create={GiftAwaitingCreate}
            //   edit={GiftAwaitingEdit}
            // />,

            // <Resource
            //   name="coupons"
            //   icon={MoneyOffIcon}
            //   options={{ label: 'Réductions', section: 'pricing' }}
            //   list={CouponList}
            //   create={CouponCreate}
            //   edit={CouponEdit}
            // />,

            <Resource
              name="settings"
              icon={SettingsIcon}
              options={{ label: 'Paramètres', link: '/settings/mentorshow', section: 'config' }}
              list={SettingsList}
              edit={SettingsEdit}
            />,
          ];

        }}
      </Admin>
    </>
  );
};

App.propTypes = {
  // firebase: PropTypes.object.isRequired,
};

export default App;
