import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import BusinessPageEditForm from './page-forms/edit/BusinessPageEditForm';
import AllClassesPageEditForm from './page-forms/edit/AllClassesPageEditForm';
import ClassPageEditForm from './page-forms/edit/ClassPageEditForm';
import DashboardPageEditForm from './page-forms/edit/DashboardPageEditForm';
import GuestFreePassPageEditForm from './page-forms/edit/GuestFreePassPageEditForm';
import HomePageEditForm from './page-forms/edit/HomePageEditForm';
import MsForGoodPageEditForm from './page-forms/edit/MsForGoodPageEditForm';
import LandingPageEditForm from './page-forms/edit/LandingPageEditForm';
import PageEditActions from './PageEditActions';
import PassFullAccessPageEditForm from './page-forms/edit/PassFullAccessPageEditForm';
import PassFullAccessWithAndrePageEditForm from './page-forms/edit/PassFullAccessWithAndrePageEditForm';
import ProgramPageEditForm from './page-forms/edit/ProgramPageEditForm';
import ReferralGuestPageEditForm from './page-forms/edit/ReferralGuestPageEditForm';
import ReferralInvitePageEditForm from './page-forms/edit/ReferralInvitePageEditForm';
import SettingsPageEditForm from './page-forms/edit/SettingsPageEditForm';
import AuthPageEditForm from './page-forms/edit/AuthPageEditForm';
import MsplusPageEditForm from './page-forms/edit/msplus/MsplusPageEditForm';

const useStyles = makeStyles(() => ({}));

const PageEdit = (props) => {
  const { id: pageId } = props;

  const { lang } = useSelector((state) => state.app);

  const classes = useStyles();

  const Actions = <PageEditActions />;

  switch (pageId) {
    /* case 'landing':
      return <LandingPageEditForm classes={classes} lang={lang} actions={Actions} {...props} />; */

    case 'home':
      return <HomePageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'passFullAccess':
      return <PassFullAccessPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'passFullAccessWithAndre':
      return <PassFullAccessWithAndrePageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'userDashboard':
      return <DashboardPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'userSettings':
      return <SettingsPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'classDetails':
      return <ClassPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'programDetails':
      return <ProgramPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'referralGuest':
      return <ReferralGuestPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'referralInvite':
      return <ReferralInvitePageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'b2b':
      return <BusinessPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'ms4good':
      return <MsForGoodPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;
  
    case 'allClasses':
      return <AllClassesPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'allPrograms':
      return <AllClassesPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;
    
    case 'guestFreePass':
      return <GuestFreePassPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'authentication':
      return <AuthPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

      case 'msplus':
        return <MsplusPageEditForm key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;


    default:
      return null;
  }
};

PageEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PageEdit;
