import React from 'react';

import {
  BooleanField,
  Datagrid,
  List,
  Filter,
  NumberField,
  TextField,
  TextInput,
  EditButton,
  DeleteButton,
} from 'react-admin';
import ColorStickField from '../../fields/ColorStickField';

import DateTimeField from '../../fields/DateTimeField';
import MyList from '../../layout/MyList';

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Rechercher un coach" source="name" alwaysOn />
  </Filter>
);

const CoachList = (props) => (

<MyList {...props} title="Coachs MS Plus" filters={<PageFilter />} bulkActionButtons={false}>
  <Datagrid rowClick="edit">
    <TextField source="name" label="Coach" />

    {/* <EditButton label="" /> */}
    <DeleteButton label="" redirect={false} undoable={false} />
  </Datagrid>
</MyList>
);

export default CoachList;
