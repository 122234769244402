import React, { useState } from 'react';
import { Notification, useGetPermissions, useLogin, useLogout, useNotify } from 'react-admin';
import { Button, MuiThemeProvider, TextField, Typography } from '@material-ui/core';
import theme from '../../theme';

const CustomLoginPage = (props) => {
  const login = useLogin();
  const notify = useNotify();
  const getPermissions = useGetPermissions();
  const logout = useLogout();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const submit = async (e) => {
    e.preventDefault();

    try {
      const res = await login({ username, password });
      console.log('res', res);

      const permissions = await getPermissions();
      console.log('permissions', permissions);

      if (!permissions.role_admin) {
        logout();
      }
    } catch (err) {
      console.log('err', err);
      notify('Invalid email or password', { type: 'error'})
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 24, backgroundColor: '#F6F7FB', flexGrow: 1 }}>
        <div style={{ height: '440px', width: 'auto', display: 'flex', backgroundColor: 'white', boxShadow: '0 15px 24px 0 rgb(137 138 154 / 15%)' }}>
          <div style={{ backgroundColor: '#4268F6', padding: 48, color: 'white', width: 380, flexGrow: 0, position: 'relative' }}>
            <h2 style={{ marginTop: 4, verticalAlign: 'middle', fontWeight: '200', fontSize: '32px', lineHeight: '40px', marginBottom: 32 }}>Bienvenue</h2>

            <div style={{ fontSize: '14px', lineHeight: '24px', marginTop: 8 }}>Connectez vous pour gérer administrer le site, éditer le contenu, modifier les paramètres, etc...</div>
          </div>

          <form onSubmit={submit} style={{ width: 480, padding: 48, flexGrow: 1 }}>
            <Typography variant="h6" style={{ marginBottom: 32, fontWeight: 'bold', color: '#535369' }}>MentorShow Admin</Typography>

            <div style={{ marginBottom: 16 }}>
              <label style={{ display: 'block', fontSize: '12px', lineHeight: '16px', marginBottom: 8 }}>Email</label>
              <TextField variant="outlined" placeholder="Email" size="small" name="email" onChange={(e) => setUsername(e.target.value)} />
            </div>

            <div style={{ marginBottom: 16 }}>
              <label style={{ display: 'block', fontSize: '12px', lineHeight: '16px', marginBottom: 8 }}>Mot de passe</label>
              <TextField variant="outlined" placeholder="Mot de passe" size="small" type="password" name="password" onChange={(e) => setPassword(e.target.value)} />
            </div>

            <div style={{ marginTop: 24, textAlign: 'center' }}>
              <Button type="submit" variant="contained" color="primary" style={{ fontWeight: 'bold' }}>Connexion</Button>
            </div>
          </form>
        </div>

        <Notification />
      </div>
    </MuiThemeProvider>
  );
};

export default CustomLoginPage;
