import * as React from 'react';
import { Children, cloneElement, isValidElement, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthProvider, useTranslate, useGetIdentity } from 'react-admin';
import {
    Tooltip,
    IconButton,
    Menu,
    Button,
    Avatar,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { AccountCircle, MoreVert } from '@material-ui/icons';

const defaultIcon = <MoreVert />;

const useStyles = makeStyles(
    theme => ({
        user: {},
        userButton: {
            textTransform: 'none',
        },
        avatar: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
    }),
    { name: 'RaUserMenu' }
);

const AnchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

const TransformOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

const UserMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [userEmail, setUserEmail] = useState('');
    const translate = useTranslate();
    const { loaded, identity } = useGetIdentity();
    const { checkAuth } = useAuthProvider();
    const classes = useStyles(props);

    console.log('identity', identity);

    React.useEffect(() => {
      const getAuth = async() => {
        const auth = await checkAuth();
        console.log('auth', auth);
        setUserEmail(auth.email);
      };

      getAuth();
    }, []);

    const {
        children,
        label = 'ra.auth.user_menu',
        icon = defaultIcon,
        logout,
    } = props;

    if (!logout && !children) return null;
    const open = Boolean(anchorEl);

    const handleMenu = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div className={classes.user}>
            {loaded && userEmail ? (
                <Button
                    aria-label={label && translate(label, { _: label })}
                    className={classes.userButton}
                    color="inherit"
                    endIcon={icon}
                    onClick={handleMenu}
                >
                  <div style={{ display: 'flex',flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Typography variant="subtitle2" style={{ fontSize: '13px', lineHeight: '24px' }}>{userEmail}</Typography>
                    <Typography variant="caption" style={{ fontSize: '10px', lineHeight: '12px' }}>Administrateur</Typography>
                  </div>
                </Button>
            ) : (
                <Tooltip title={label && translate(label, { _: label })}>
                    <IconButton
                        aria-label={label && translate(label, { _: label })}
                        aria-owns={open ? 'menu-appbar' : null}
                        aria-haspopup={true}
                        color="inherit"
                        onClick={handleMenu}
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
            )}
            <Menu
                id="menu-appbar"
                disableScrollLock
                anchorEl={anchorEl}
                anchorOrigin={AnchorOrigin}
                transformOrigin={TransformOrigin}
                // Make sure the menu is display under the button and not over the appbar
                // See https://material-ui.com/components/menus/#customized-menus
                getContentAnchorEl={null}
                open={open}
                onClose={handleClose}
            >
                {Children.map(children, menuItem =>
                    isValidElement(menuItem)
                        ? cloneElement<any>(menuItem, {
                              onClick: handleClose,
                          })
                        : null
                )}
                {logout}
            </Menu>
        </div>
    );
};

UserMenu.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    label: PropTypes.string,
    logout: PropTypes.element,
    icon: PropTypes.node,
};

export default UserMenu;