import { createMuiTheme } from "ra-ui-materialui";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5383ff',
    },
    secondary: {
      light: '#6ec6ff',
      main: '#fff',
      dark: '#0069c0',
      contrastText: '#000',
    },
  },
  typography: {
    fontWeight: 100,
    fontFamily: '"Open Sans", Robotto, Arial',
    title: {
      fontWeight: 100,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    body1: {
      fontWeight: 100,
      fontSize: '14px',
    },
    body2: {
      fontWeight: 500,
      color: '#000',
    },
  },
  overrides: {
    MuiAccordion: {
      root: {
        boxShadow: 'none',
        border: '1px solid #ccc',
        marginTop: 16,
        marginBottom: 16,

        '&:first-child': {
          borderRadius: '0 !important',
        },

        '&:last-child': {
          borderRadius: '0 !important',
          borderBottom: '1px solid #ccc',
        },

        '&.Mui-expanded': {
          borderBottom: '1px solid #ccc',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        flexDirection: 'column',
      },
    },
    MuiAccordionSummary: {
      root: {
        '&.Mui-expanded': {
          borderBottom: '1px solid #ccc',
        },
      },
      content: {
        '& > .MuiTypography-root': {
          fontSize: 14,
          fontWeight: '500',
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 100,
        textTransform: 'capitalize',
        borderRadius: 0,
      },
    },
    MuiCollapse: {
      root: {
        borderLeft: '8px solid rgba(83, 131, 255, 0.5)',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
        fontWeight: '400',
        // color: '#1c1c38',
        color: 'rgba(0, 0, 0, 0.7)',
      },
    },
    MuiInput: {
      input: {
        color: '#000',
        backgroundColor: 'transparent',
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: '400',
        fontFamily: 'Roboto, sans-serif',
        background: 'transparent !important',
        borderRadius: '0 !important',
        width: '100%',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgb(192, 192, 202)',
      },
    },
    MuiRadio: {
      root: {
        color: '#5383ff !important',
      },
    },
    MuiTableHead: {
      root: {
        background: 'rgb(246, 247, 251) !important',
      },
    },
    MuiTableCell: {
      root: {
        padding: '16px !important',
        borderBottom: 0,
      },
      head: {
        backgroundColor: 'transparent !important',
        color: 'rgb(137, 138, 154)',
        fontWeight: '600',
        padding: '16px !important',
        fontSize: '12px',
      },
      body: {
        fontWeight: 500,
      },
    },
    MuiTablePagination: {
      input: {
        flex: 1,
        border: '1px solid',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: 'rgb(137, 138, 154) !important',
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    RaDeleteWithConfirmButton: {
      deleteButton: {
        padding: '6px 16px',
        backgroundColor: '#ff4567',
        color: 'white',
        fontWeight: '400',
      },
    },
    RaSaveButton: {
      button: {
        fontWeight: '400',
      },
    },
    RaEdit: {
      main: {
        padding: '20px',
        background: 'white',
      },
      card: {
        boxShadow: 'none',
      },
    },
    RaFileInput: {
      root: {
        paddingLeft: '7px',
        borderLeft: '8px solid rgba(83, 131, 255, 0.5)',
      },
      dropZone: {
        border: '3px dashed #666',
        background: 'transparent',
      },
    },
    RaFormInput: {
      input: {
        marginBottom: '40px',
        width: '100%',

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    RaImageInput: {
      root: {
        paddingLeft: '7px',
        borderLeft: '8px solid rgba(83, 131, 255, 0.5)',
        background: '#e1e1e1',
      },
    },
    RaLayout: {
      root: {
        paddingTop: '0 !important',
      },
      appFrame: {
        marginTop: '0 !important',
      },
    },
    RaList: {
      main: {
        padding: '20px',
        background: 'white',
      },
    },
    RaListToolbar: {
      actions: {
        minHeight: 0,
        paddingTop: '4px',
      },
    },
    RaRichTextInput: {
      label: {
        marginBottom: '8px',
      },
    },
    RaSimpleFormIterator: {
      root: {
        paddingLeft: '5px',
        // borderLeft: '10px solid rgba(83, 131, 255, 0.5)',
      },
      line: {
        paddingTop: '10px',
        paddingLeft: '20px',
        paddingBottom: '20px',
        marginBottom: '30px',
        border: '1px solid rgba(83, 131, 255, 0.5)',
        borderLeft: '8px solid rgba(83, 131, 255, 0.5)',

        '&:last-child': {
          border: 0,
        },
      },
      action: {
        color: 'rgb(66, 104, 246)',
      },
    },
    RaToolbar: {
      toolbar: {
        backgroundColor: 'transparent',
        padding: '0px 32px 32px',
      },
    },
  },
});

export default theme;
