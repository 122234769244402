import PropTypes from 'prop-types';
import React from 'react';
import RichTextInput from 'ra-input-rich-text';
import { Typography, makeStyles } from '@material-ui/core';

import {
  ArrayInput,
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import Config from '../../../../../config';
import EditToolbar from '../../../../common/EditToolbar';
import PageTitle from '../PageTitle';
import TabbedFormTabs from '../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../layout/VerticalTabbedForm';
import MyEdit from '../../../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
}));

const LandingPageEditForm = ({ lang, ...props }) => {
  const classes = useStyles();

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm
        toolbar={<EditToolbar />}
        redirect={false}
        tabs={<TabbedFormTabs />}
        variant="outlined"
      >
        <FormTab label="Général">
          <TextInput disabled source="id" />

          <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />

          <TextInput source="activeCompaign.leadsList" label="AC Liste Prospects" className={classes.textInput} />

          <TextInput source={`activeCompaign.confirmation.title.${lang}`} multiline label="Titre de la pupup de confirmation" className={classes.textInput} />
          <RichTextInput source={`activeCompaign.confirmation.text.${lang}`} label="Texte de la pupup de confirmation" className={classes.textInput} toolbar={Config.richTextToolbar} />
          <TextInput source={`activeCompaign.confirmation.buttonLabel.${lang}`} multiline label="Libellé du bouton de la popup de confirmation" className={classes.textInput} />
          <TextInput source="activeCompaign.confirmation.buttonUrl" multiline label="URL du bouton de la popup de confirmation" className={classes.textInput} />
        </FormTab>

        <FormTab label="SEO">
          <div className={classes.separator} />
          <Typography variant="subtitle1" gutterBottom>SEO</Typography>

          <ImageInput source="seo.image" label="Photo d'aperçu SEO" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Hero">
          <TextInput source={`sections.hero.title.${lang}`} multiline label="Titre" className={classes.textInput} />
          <TextInput source={`sections.hero.description.${lang}`} multiline label="Description" className={classes.textInput} />
          <TextInput source={`sections.hero.cta.emailPlaceholder.${lang}`} label="Email placeholder" className={classes.textInput} />
          <TextInput source={`sections.hero.cta.label.${lang}`} label="Button label" className={classes.textInput} />

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>Image</Typography>

          <ImageInput source="sections.hero.image" label="Photo Arrière plan" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source={`sections.hero.image.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} />
        </FormTab>

        <FormTab label="Bande annonce">
          <TextInput source={`sections.thriller.title.${lang}`} multiline label="Titre" className={classes.textInput} />
          <TextInput source={`sections.thriller.sectionSubtitle.${lang}`} multiline label="Sous-titre" className={classes.textInput} />
          <TextInput source={`sections.thriller.description.${lang}`} multiline label="Description" className={classes.textInput} />
          <TextInput source="sections.thriller.videoId" label="ID Vidéo Wistia" className={classes.textInput} />
        </FormTab>

        <FormTab label="Cours">
          <TextInput source={`sections.classes.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.classes.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
          <ArrayInput source="sections.classes.items" label="Liste des cours" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <Typography variant="subtitle1" gutterBottom className={classes.separator}>Image</Typography>

              <ImageInput source="image" label="Photo" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput source={`image.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} />

              <TextInput source="name" label="Nom" className={classes.textInput} />
              <TextInput source={`domain.${lang}`} label="Domaine" className={classes.textInput} />
              <TextInput source={`teaches.${lang}`} label="Fonction" className={classes.textInput} />
              <TextInput source={`description.${lang}`} label="Description" multiline className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="FAQ">
          <TextInput source={`sections.faq.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.faq.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
          <ArrayInput source="sections.faq.items" label="Liste des cours" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`question.${lang}`} label="Question" className={classes.textInput} />
              <TextInput source={`answer.${lang}`} label="Réponse" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="NewsLetter">
          <ArrayInput source={`sections.newsletter.title.${lang}`} label="Titre (lignes)" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput label="Ligne" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>

          <TextInput source={`sections.newsletter.emailPlaceholder.${lang}`} label="Placeholder Email" className={classes.textInput} />
          <TextInput source={`sections.newsletter.buttonLabel.${lang}`} label="Label bouton" className={classes.textInput} />
        </FormTab>

      </VerticalTabbedForm>
    </MyEdit>
  );
};

LandingPageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default LandingPageEditForm;
