import React from 'react';

import {
  AutocompleteInput,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  ShowButton,
  TextField,
} from 'react-admin';

import AmountField from '../../fields/AmountField';
import CombinedNameField from '../../fields/CombinedNameField';
import DateTimeField from '../../fields/DateTimeField';
import MyList from '../../layout/MyList';

const PageFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Utilisateur" source="userId" reference="users" filterToQuery={(searchText) => ({ displayName: searchText })} alwaysOn>
      <AutocompleteInput optionText="displayName" />
    </ReferenceInput>
  </Filter>
);

const TransactionList = (props) => (
  <MyList
    {...props}
    title="Transactions"
    filters={<PageFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={25}
    bulkActionButtons={false}
  >
    <Datagrid>
      <DateTimeField source="createdAt" />
      <ReferenceField label="Utilisateur" source="userId" reference="users">
        <CombinedNameField source="name" />
      </ReferenceField>
      <TextField source="plan.nickname" label="Produit" />
      <AmountField label="Montant" options={{ style: 'currency', currency: 'EUR' }} />
      <TextField source="status" label="Statut" />
      <ShowButton label="" />
    </Datagrid>
  </MyList>
);

export default TransactionList;
