import React from 'react';

import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import MyList from '../../layout/MyList';

const MsplusGroupSessionList = (props) => (
  <MyList {...props} title="Sessions de groupe" bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="coach.name" label="Organizer" />
      <TextField source="title.fr" label="Title" />

      <DateField source="date" />
      <DeleteButton label="" redirect={false} undoable={false} />
    </Datagrid>
  </MyList>
);

export default MsplusGroupSessionList;
