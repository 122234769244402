import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';

import { ArrayInput, AutocompleteInput, CheckboxGroupInput, Create, DateInput, DateTimeInput, FormTab, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput, required } from 'react-admin';

import Config from '../../../config';
import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';
import { VerticalTabbedForm } from '../../layout/VerticalTabbedForm';
import TabbedFormTabs from '../../layout/TabbedFormTabs';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    height: '2rem',
  },
}));

const MsplusGroupSessionCreate = (props) => {
  const classes = useStyles();
  const { lang } = useSelector((state) => state.app);

  const [id, setId] = useState('');

  const handleIdChange = (event) => {
    setId(event.target.value);
  };

  const Actions = <PageEditActions />;

  return (
    <Create {...props} title="Ajouter un témoignage" actions={Actions} className="edit-wrapper">
      <VerticalTabbedForm key={lang} toolbar={<EditToolbar />} tabs={<TabbedFormTabs />} variant="outlined">
        <FormTab label="Général">
          <TextInput source="id" label="ID" className={classes.textInput} value={id.toLowerCase()} onChange={handleIdChange} validate={[required()]} />

          <ImageInput source="image" label="Image" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>

          <TextInput multiline source={`title.${lang}`} label={`Titre (${lang})`} className={classes.textInput} validate={[required()]} />
          <TextInput multiline source={`description.${lang}`} label={`Description (${lang})`} className={classes.textInput} validate={[required()]} />

          <ArrayInput source="categories" label="Categories" style={{ width: '100%' }} validate={[required()]}>
            <SimpleFormIterator>
              <ReferenceInput label="Catégorie" reference="content/msplus/categories" filterToQuery={(searchText) => (searchText ? { [`name.${lang}`]: searchText } : null)}>
                <AutocompleteInput optionText={`name.${lang}`} />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>

          <DateTimeInput source="date" label="Date" initialValue={new Date()} />

          <SelectInput
            source="type"
            label="Event type"
            choices={[
              { id: 'online', name: 'Online' },
              { id: 'physical', name: 'Offline' },
            ]}
            initialValue="online"
          />

          <TextInput source={`coach.name`} label={`Organizer name`} className={classes.textInput} validate={[required()]} />
          <ImageInput source="coach.avatar" label="Organizer avatar" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>

          <SelectInput 
            source="coach.type"
            label="Organizer type"
            choices={[
              { id: 'coach', name: 'Coach' },
              { id: 'mentor', name: 'Mentor' },
            ]}
            initialValue="coach"
          />

          <CheckboxGroupInput
            source="languages"
            label=""
            choices={Config.supportedLocales.map((locale) => ({ id: locale, name: locale.toUpperCase() }))}
            row={false}
            helperText="Si aucune case n'est cochée, alors l'élément ne sera jamais affiché sur le site."
          />
        </FormTab>

        <FormTab label="Replay">
          <Typography variant="subtitle1" className={classes.separator}>
            Replay
          </Typography>

          <Typography variant="body1" className={classes.separator}>
            For this item to show up on replays list, the date must be in the past and the video id must be set.
          </Typography>

          <TextInput source="replay.videoId" label="VideoId" />
        </FormTab>

        <FormTab label="Calendly">
          <TextInput source="calendly.username" label="Calendly Username" validate={[required()]}/>
          <TextInput source="calendly.eventType" label="Calendly EventType" validate={[required()]}/>
        </FormTab>
      </VerticalTabbedForm>
    </Create>
  );
};

export default MsplusGroupSessionCreate;
