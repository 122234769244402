import * as React from "react";
import { cloneElement, useMemo } from "react";
import PropTypes from "prop-types";
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
} from "react-admin";
import { makeStyles } from '@material-ui/core';
import IconEvent from "@material-ui/icons/Event";

const useStyles = makeStyles({
  button: {
    fontSize: '14px',
    lineHeight: '24px',
    padding: '4px 32px',
    border: '1px solid rgb(66, 104, 246)',
    color: 'rgb(66, 104, 246)',
    fontWeight: '400',
    marginRight: '16px',

    '&:hover, &.add:hover': {
      backgroundColor: '#535b8e',
      color: 'white',
    },

    '&.add': {
      backgroundColor: 'rgb(66, 104, 246)',
      color: 'white',
    }
  },
});

const ListActions = (props) => {
  const { className, filters, maxResults, ...rest } = props;
  
  const { total } = useListContext();
  const classes = useStyles();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, { context: "button" })}

      {props.hasCreate && <CreateButton {...props} basePath={props.basePath} className={`${classes.button} add`} />}
      
      {props.exporter !== false && <ExportButton disabled={total === 0} maxResults={maxResults} className={classes.button} />}
    </TopToolbar>
  );
};

export default ListActions;
