import PropTypes from 'prop-types';
import React from 'react';

import { TopToolbar, CreateButton, ExportButton, RefreshButton, useListContext } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import ImportButton from '../../../utils/ImportCsv/ImportButton';

const useStyles = makeStyles({
  button: {
    fontSize: '14px',
    lineHeight: '24px',
    padding: '4px 32px',
    border: '1px solid rgb(66, 104, 246)',
    color: 'rgb(66, 104, 246)',
    fontWeight: '400',
    marginRight: '16px',

    '&:hover, &.add:hover': {
      backgroundColor: '#535b8e',
      color: 'white',
    },

    '&.add': {
      backgroundColor: 'rgb(66, 104, 246)',
      color: 'white',
    },
  },
});

const csvMapping = {
  first_name: 'firstName',
  last_name: 'lastName',
  email: 'email',
  address_zip: 'zip',
  address_state: 'state',
  address_city: 'city',
  address_country: 'country',
  telephone: 'phone',
};

const UserListActions = (props) => {
  const classes = useStyles();

  const { bulkActions, exporter, filters } = props;

  const { basePath, currentSort, displayedFilters, filterValues, onUnselectItems, resource, selectedIds, showFilter, total } = useListContext();

  return (
    <TopToolbar>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}

      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      <CreateButton basePath={basePath} className={`${classes.button} add`} />

      <ImportButton {...props} logging mappings={csvMapping} className={classes.button} />

      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filter={filterValues} exporter={exporter} className={classes.button} />
      <RefreshButton className={classes.button} />
    </TopToolbar>
  );
};

UserListActions.propTypes = {
  bulkActions: PropTypes.any,
  exporter: PropTypes.any,
  filters: PropTypes.any,
};

UserListActions.defaultProps = {
  bulkActions: null,
  exporter: null,
  filters: null,
};

export default UserListActions;
