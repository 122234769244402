import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';

import {
  Create,
  NumberInput,
  RadioButtonGroupInput,
  SelectArrayInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';
import { useFirebase } from '../../../firebase';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    height: '2rem',
  },
}));

const CouponCreate = (props) => {
  const [classesList, setClassesList] = React.useState([]);

  const firebase = useFirebase();
  const classes = useStyles();

  React.useEffect(() => {
    if (!firebase) {
      return;
    }

    const getClasses = async () => {
      const classesQuery = await firebase.db.collection('content').doc('mentorshow').collection('classes').get();
      const classesTemp = [{ _id: 'pass', name: 'Pass illimité' }];
      classesQuery.forEach((classRef) => {
        // console.log('classRef.data', classRef.data());
        const classData = classRef.data();
        classesTemp.push({
          _id: classRef.id,
          name: classData.mentor.name,
        });
      });

      setClassesList(classesTemp);
    };

    getClasses();
  }, [firebase]);

  const Actions = <PageEditActions />;

  return (
    <Create {...props} actions={Actions} className="edit-wrapper">
      <SimpleForm
        toolbar={<EditToolbar withDelete />}
        variant="outlined"
      >
        <RadioButtonGroupInput
          source="type"
          choices={[
            { id: 'redeemable', name: 'Code Promo' },
            { id: 'automatic', name: 'Automatique' },
          ]}
          defaultValue="redeemable"
        />

        <TextInput source="id" label="ID" className={classes.textInput} parse={(val) => val.toUpperCase()} helperText="L'ID est le code à partager. Exemple: SALE30, PROMO50, ETE40" />
        <TextInput source="name" label="Description" className={classes.textInput} helperText="Décrivez le code promo pour le retrouver facilement dans la liste. Non visible par les utilisateurs" />

        <NumberInput source="percent" label="Pourcentage" className={classes.textInput} helperText="Pourcentage de réduction à appliquer" />

        <SelectArrayInput source="applyTo" label="Appliquer à" choices={classesList} optionText="name" optionValue="_id" />

        <NumberInput source="maxRedemptions" label="Nombre max d'utilisations" helperText="Champ préparé pour un usage futur, pas besoin de le saisir" />

        <TextInput disabled source="createdAt" defaultValue={new Date()} />
      </SimpleForm>
    </Create>
  );
};

export default CouponCreate;
