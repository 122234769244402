import PropTypes from 'prop-types';
import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';

import {
  AutocompleteInput,
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';
import UserEditTitle from './utils/UserEditTitle';
import UserEditAside from './utils/UserEditAside';
import UserTransactionsList from './utils/UserTransactionsList';
import { getCountries } from '../../../utils/countries';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  textInput: {
    width: '100%',
  },
}));

const UserEdit = (props) => {
  const { basePath, record } = props;

  console.log('UserEdit props', props);

  const classes = useStyles();

  const Actions = <PageEditActions basePath={basePath} record={record} />;

  return (
    <Edit
      {...props}
      title={<UserEditTitle />}
      actions={Actions}
      aside={<UserEditAside />}
      className="edit-wrapper"
    >
      <SimpleForm
        toolbar={<EditToolbar withDelete />}
        redirect={false}
        variant="outlined"
      >
        <TextInput disabled source="id" />
        <TextInput disabled source="stripeCustomerId" label="Stripe Customer" />

        <TextInput source="firstName" label="Prénom" className={classes.textInput} />
        <TextInput source="lastName" label="Nom" className={classes.textInput} />
        <TextInput source="city" label="Ville" className={classes.textInput} />
        <SelectInput
          source="country"
          label="Pays"
          choices={getCountries().map((country) => ({
            id: country.key,
            name: country.text,
          }))}
        />
        <TextInput source="phone" label="Téléphone" className={classes.textInput} />
        <SelectInput
          source="lang"
          label="Langue"
          choices={[
            { id: 'fr', name: 'French' },
            { id: 'es', name: 'Spanish' },
            { id: 'en', name: 'English' },
          ]}
        />

        <TextInput source="email" label="Email" />
      </SimpleForm>
    </Edit>
  );
};

UserEdit.propTypes = {
  basePath: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  record: PropTypes.any,
};

UserEdit.defaultProps = {
  record: null,
};

export default UserEdit;
