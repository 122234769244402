import React from 'react';
import Save from '@material-ui/icons/Save';
import classnames from 'classnames';
import { Button, CardContent, CircularProgress, MenuItem, Paper, TextField, Toolbar, makeStyles } from '@material-ui/core';
import { showNotification, required } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Config from '../../../config';
import { getCountries } from '../../../utils/countries';
import { useFirebase } from '../../../firebase';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  sectionTitle: {
    fontSize: 16,
    marginTop: 30,
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  menu: {
    width: 200,
  },
  toolbar: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
  },
  desktopToolbar: {
    marginTop: theme.spacing(2),
  },
  mobileToolbar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '16px',
    width: '100%',
    boxSizing: 'border-box',
    flexShrink: 0,
    zIndex: 2,
  },
  defaultToolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacer: {
    [theme.breakpoints.down('xs')]: {
      height: '5em',
    },
  },
  button: {
    position: 'relative',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 18,
  },
}));

const locales = [
  { key: 'fr', label: 'French' },
  { key: 'es', label: 'Spanish' },
  { key: 'en', label: 'English' },
]

const UserCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const firebase = useFirebase();

  const [saving, setSaving] = React.useState(false);

  const [user, setUser] = React.useState({
    email: '',
    firstName: '',
    lastName: '',
    city: '',
    country: 'FR',
    phone: '',
    lang: 'fr',
  });

  const countries = getCountries('fr');

  const handleUserChange = (field) => (event) => {
    let value = event.target.value;

    if (field === 'email') {
      value = value.toLowerCase();
    }

    setUser({
      ...user,
      [field]: value,
    });
  };

  const submit = async (e) => {
    if (saving) {
      e.preventDefault();
      return;
    }

    setSaving(true);

    try {
      const res = await fetch(`${Config.frontUrl}/api/admin/users/createUser`, {
        method: 'POST',
        body: JSON.stringify({ user }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await res.json();

      if (result.success) {
        setUser({
          email: '',
          firstName: '',
          lastName: '',
          city: '',
          country: 'FR',
          phone: '',
        });

        dispatch(showNotification('Utilisateur créé'));

        history.push(`/users/${result.uid}`);
      } else {
        dispatch(showNotification(`Erreur lors de la création de l\'utilisateur : ${result.message}`, 'warning'));
      }
    } catch (error) {
      dispatch(showNotification("Erreur lors de la création de l'utilisateur", 'warning'));
    }

    setSaving(false);
  };

  return (
    <>
      <Paper>
        <CardContent>
          <form>
            <TextField id="email" label="Email" className={classes.textInput} value={user.email.toLowerCase()} onChange={handleUserChange('email')} margin="normal" variant="outlined" />

            <TextField id="firstName" label="Prénom" className={classes.textInput} value={user.firstName} onChange={handleUserChange('firstName')} margin="normal" variant="outlined" />

            <TextField id="lastName" label="Nom" className={classes.textInput} value={user.lastName} onChange={handleUserChange('lastName')} margin="normal" variant="outlined" />

            <TextField id="city" label="Ville" className={classes.textInput} value={user.city} onChange={handleUserChange('city')} margin="normal" variant="outlined" />

            <TextField
              id="country"
              select
              label="Pays"
              className={classes.textInput}
              value={user.country}
              onChange={handleUserChange('country')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              helperText="Please select your currency"
              margin="normal"
              variant="outlined"
            >
              {countries.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>

            <TextField id="phone" label="Téléphone" className={classes.textInput} value={user.phone} onChange={handleUserChange('phone')} margin="normal" variant="outlined" />

            <TextField
              id="lang"
              select
              label="Langue"
              className={classes.textInput}
              value={user.lang}
              onChange={handleUserChange('lang')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              helperText="Please select user's locale"
              margin="normal"
              variant="outlined"
            >
              {locales.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
        </CardContent>

        <Toolbar className={classnames(classes.toolbar, classes.desktopToolbar)} role="toolbar">
          <div className={classes.defaultToolbar}>
            <Button className={classes.button} variant="contained" type="button" onClick={submit} color={saving ? 'default' : 'primary'}>
              {saving ? (
                <CircularProgress size={18} thickness={2} className={classes.leftIcon} />
              ) : (
                React.cloneElement(<Save />, {
                  className: classnames(classes.leftIcon, classes.icon),
                })
              )}
              Enregistrer
            </Button>
          </div>
        </Toolbar>
      </Paper>
    </>
  );
};

export default UserCreate;
