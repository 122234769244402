import React from 'react';
import FirebaseContext from './FirebaseContext';

import Config from '../config';
import dayjs from 'dayjs';

/* eslint-disable */
const FirebaseProvider = ({ credentials = {}, children }) => {
  const [firebase, setFirebase] = React.useState(null);

  React.useEffect(() => {
    if (!firebase && typeof window !== 'undefined') {
      const app = import('firebase/app');
      const auth = import('firebase/auth');
      const firestore = import('firebase/firestore');
      const database = import('firebase/database');
      const storage = import('firebase/storage');
      const functions = import('firebase/functions');

      Promise.all([app, auth, firestore, database, storage, functions]).then((values) => {
        const firebaseInstance = values[0].default;

        if (!firebaseInstance.apps || !firebaseInstance.apps.length) {
          firebaseInstance.initializeApp(Config.firebaseConfig);
        }

        const myFirebase = new MyFirebase(firebaseInstance);

        setFirebase(myFirebase);
      });
    }
  }, [firebase]);

  if (!firebase) {
    return null;
  }

  return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
};

class MyFirebase {
  constructor(app) {
    this.instance = app;

    // Firebase Auth init
    this.auth = app.auth();
    this.auth.setPersistence(app.auth.Auth.Persistence.LOCAL);

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();

    // Firebase Firestore init
    this.db = app.firestore();
    const settings = {
      cacheSizeBytes: app.firestore.CACHE_SIZE_UNLIMITED,
    };
    this.db.settings(settings);
    this.db.enablePersistence({
      synchronizeTabs: true,
    });

    this.rtdb = app.database();

    // Firebase Storage init
    this.storage = app.storage().ref();

    // Firebase Functions
    this.functions = app.functions();
  }

  /* *********
   * Authentication
   ********** */
  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

  /* *********
   * Firestore (Database)
   ********** */
  user = (uid) => this.db.collection('users').doc(uid);

  adminStats = () => this.db.collection('stats').doc('admin');
  pageCheckoutStats = () => this.db.collection('stats').doc('pages').collection('checkout').where('date', '>=', dayjs().subtract(1, 'month').toDate());
  pageCheckoutConfirmStats = () => this.db.collection('stats').doc('pages').collection('checkoutConfirm').where('date', '>=', dayjs().subtract(1, 'month').toDate());
  pageSignupStats = () => this.db.collection('stats').doc('pages').collection('signup').where('date', '>=', dayjs().subtract(1, 'month').toDate());

  globalRatings = () => this.db.collection('ratings').doc('global');

  comments = () => this.db.collection('comments');

  /* *********
   * RTDB (Database)
   ********** */
  userCurrentWatchings = (uid) => this.rtdb.ref(`/online/${uid}`);

  /* *********
   * Functions
   ********** */
  createUser = (args) => this.functions.httpsCallable('createUser')(args);

  subscribeUserToPass = (args) => this.functions.httpsCallable('subscribeUserToPass')(args);

  sendPasswordResetEmail = (args) => this.functions.httpsCallable('sendPasswordResetEmail')(args);
}

export default FirebaseProvider;

/* eslint-enable */
