import PropTypes from 'prop-types';
import React from 'react';

import {
  Datagrid,
  DeleteButton,
  List,
  Filter,
  NumberField,
  SelectInput,
  TextField,
  TextInput,
  EditButton,
} from 'react-admin';
import {
  Typography,
} from '@material-ui/core';

import DateTimeField from '../../fields/DateTimeField';
import MyList from '../../layout/MyList';

const PageFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      source="type"
      choices={[{ id: 'redeemable', name: 'Code promo' }, { id: 'automatic', name: 'Automatique' }]}
      alwaysOn
    />

    <TextInput label="Search" source="id" alwaysOn />
  </Filter>
);

const CouponType = ({ record, source }) => {
  const value = record[source] || null;

  return (
    <Typography>
      {value === 'automatic' ? 'Auto' : 'Code'}
    </Typography>
  );
};

CouponType.propTypes = {
  record: PropTypes.any,
  source: PropTypes.string.isRequired,
};

CouponType.defaultProps = {
  record: null,
};

const CouponList = (props) => (
  <MyList {...props} filters={<PageFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <CouponType source="type" label="Type" />
      <TextField source="name" label="Description" />
      <NumberField source="percent" label="Pourcentage" />
      <DateTimeField source="createdAt" label="Date création" />
      <DateTimeField source="expirationDate" label="Expiration" />
      {/* <EditButton label="" /> */}
      <DeleteButton label="" redirect={false} undoable={false} />
    </Datagrid>
  </MyList>
);

export default CouponList;
