import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';

import { AutocompleteInput, CheckboxGroupInput, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required } from 'react-admin';

import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';
import MyEdit from '../../layout/MyEdit';
import Config from '../../../config';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    height: '2rem',
  },
}));

const OfferTitle = ({ record }) => {
  return <span>Offre {record ? `"${record.id}"` : ''}</span>;
};

OfferTitle.propTypes = {
  record: PropTypes.any,
};

OfferTitle.defaultProps = {
  record: null,
};

const MsplusOffersEdit = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  return (
    <MyEdit {...props} title={<OfferTitle />} actions={Actions} className="edit-wrapper">
      <SimpleForm key={lang} toolbar={<EditToolbar withDelete />} variant="outlined">
        <TextInput disabled source="id" label="ID" className={classes.textInput} />

        <TextInput source="couponId" label="ID Coupon" className={classes.textInput} helperText="ID du coupon de réduction Stripe à appliquer pour cette offre" />
        
        <TextInput source="description" label="Description (interne)" className={classes.textInput} validate={[required()]} />
      </SimpleForm>
    </MyEdit>
  );
};

MsplusOffersEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export default MsplusOffersEdit;
