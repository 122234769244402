import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';

import { AutocompleteInput, CheckboxGroupInput, Create, NumberInput, ReferenceInput, SimpleForm, TextInput, required } from 'react-admin';

import Config from '../../../config';
import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    height: '2rem',
  },
}));

const MsplusTestimonialsCreate = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  return (
    <Create {...props} title="Ajouter un témoignage" actions={Actions} className="edit-wrapper">
      <SimpleForm key={lang} toolbar={<EditToolbar withDelete />} variant="outlined">
        <TextInput source="name" label="Nom" className={classes.textInput} validate={[required()]} />
        <TextInput multiline source={`title.${lang}`} label={`Titre (${lang})`} className={classes.textInput} validate={[required()]} />
        <TextInput multiline source={`text.${lang}`} label={`Texte (${lang})`} className={classes.textInput} validate={[required()]} />
        <NumberInput source="rating" label="Note (/5)" className={classes.textInput} validate={[required()]} />

        <Typography variant="body1" className={classes.separator}>
          Sélectionnez les langues pour lesquelles vous voulez afficher ce cours.
        </Typography>
        <CheckboxGroupInput
          source="languages"
          label=""
          choices={Config.supportedLocales.map((locale) => ({ id: locale, name: locale.toUpperCase() }))}
          row={false}
          helperText="Si aucune case n'est cochée, alors l'élément ne sera jamais affiché sur le site."
        />
      </SimpleForm>
    </Create>
  );
};

export default MsplusTestimonialsCreate;
