import PropTypes from 'prop-types';
import {
  Children,
  cloneElement,
  isValidElement,
} from 'react';
import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

export const getTabFullPath = (
  tab,
  index,
  baseUrl,
) =>
  `${baseUrl}${
      tab.props.path ? `/${tab.props.path}` : index > 0 ? `/${index}` : ''
  }`;

const useStyles = makeStyles(() => ({
  wrapper: {
    alignItems: 'flex-start',
    textAlign: 'left',
    color: 'rgba(0,0,0,.8)',
  },
  selected: {
    background: 'rgb(246, 247, 251)',
    color: '#000',
  },
}));

const TabbedFormTabs = ({
  basePath,
  children,
  url,
  // syncWithLocation,
  value,
  ...rest
}) => {
  const location = useLocation();
  const classes = useStyles();

  const validTabPaths = Children.map(children, (tab, index) => {
    if (!isValidElement(tab)) return undefined;
    return getTabFullPath(tab, index, url);
  });

  // This ensures we don't get warnings from material-ui Tabs component when
  // the current location pathname targets a dynamically added Tab
  // In the case the targeted Tab is not present at first render (when
  // using permissions for example) we temporarily switch to the first
  // available tab. The current location will be applied again on the
  // first render containing the targeted tab. This is almost transparent
  // for the user who may just see a short tab selection animation
  const tabValue = validTabPaths.includes(location.pathname)
    ? location.pathname
    : validTabPaths[0];

  return (
    <Tabs
      orientation="vertical"
      value={tabValue}
      indicatorColor="primary"
      {...rest}
    >
      {Children.map(children, (tab, index) => {
        if (!isValidElement(tab)) return null;

        // Builds the full tab which is the concatenation of the last matched route in the
        // TabbedShowLayout hierarchy (ex: '/posts/create', '/posts/12', , '/posts/12/show')
        // and the tab path.
        // This will be used as the Tab's value
        const tabPath = getTabFullPath(tab, index, url);

        return cloneElement(tab, {
          intent: 'header',
          value: tabPath,
          classes: { wrapper: classes.wrapper, selected: classes.selected },
          wrapped: true,
          syncWithLocation: true
        });
      })}
    </Tabs>
  );
};

TabbedFormTabs.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  url: PropTypes.string,
  syncWithLocation: PropTypes.any,
  tabsWithErrors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.any,
};

TabbedFormTabs.defaultProps = {
  children: null,
  classes: null,
  syncWithLocation: false,
  tabsWithErrors: null,
  url: null,
  value: null,
};

export default TabbedFormTabs;
