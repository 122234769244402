import PropTypes from 'prop-types';
import React from 'react';

import { Typography, makeStyles } from '@material-ui/core';

import {
  ArrayInput,
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import EditToolbar from '../../../../common/EditToolbar';
import PageTitle from '../PageTitle';
import TabbedFormTabs from '../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../layout/VerticalTabbedForm';
import MyEdit from '../../../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
}));

const MsForGoodPageEditForm = ({ lang, ...props }) => {
  const classes = useStyles();

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm
        toolbar={<EditToolbar />}
        redirect={false}
        tabs={<TabbedFormTabs />}
        variant="outlined"
      >
        <FormTab label="Général">
          <TextInput disabled source="id" />

        </FormTab>

        <FormTab label="SEO">
          <div className={classes.separator} />
          <Typography variant="subtitle1" gutterBottom>SEO</Typography>

          <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} />

          <ImageInput source="seo.image" label="Photo d'aperçu SEO" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Hero">
          
          <ImageInput source="sections.hero.img" label="Photo Titre Hero" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>

          <TextInput source={`sections.hero.title.${lang}`} multiline label="Titre" className={classes.textInput} />

          <TextInput source={`sections.hero.description.${lang}`} multiline label="Description" className={classes.textInput} />

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>Image</Typography>

          <ImageInput source="sections.hero.image" label="Photo arrière plan" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source={`sections.hero.image.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} />

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>CTA</Typography>

          <TextInput multiline source={`sections.hero.cta.description.${lang}`} label="CTA Description" className={classes.textInput} />
          <TextInput source={`sections.hero.cta.label.${lang}`} label="Button label" className={classes.textInput} />
          <TextInput source="sections.hero.cta.url" label="Button path" className={classes.textInput} />
        </FormTab>

        <FormTab label="Description Bloc">
          <TextInput source={`sections.descriptionBloc.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput multiline source={`sections.descriptionBloc.description.${lang}`} label="Contenu" className={classes.textInput} />
          <ImageInput source="sections.descriptionBloc.image" label="Icône" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Partners Bloc">
        <TextInput source={`sections.partnersBloc.title.${lang}`} label="Titre" className={classes.textInput} />
        <ArrayInput source="sections.partnersBloc.logos" label="Logos des partenaires" style={{ width: '100%' }}>
            <SimpleFormIterator>
                <ImageInput source="logo" label="Icône" accept="image/*">
                     <ImageField source="src" title="title" />
                </ImageInput>
                <TextInput source="url" label="Url site partenaire" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>


        <FormTab label="Formulaire">
          {/*<TextInput source={`sections.form.title.${lang}`} label="Titre" className={classes.textInput} />*/}
          <TextInput source="sections.form.sheetId" label="Google Sheet ID" className={classes.textInput} helperText="Partager le sheet avec 'firebase-adminsdk-2xv2u@mentorshow-preview.iam.gserviceaccount.com' et 'firebase-adminsdk-gdinb@mentorshow-production.iam.gserviceaccount.com'" />
        
          <TextInput source={`sections.form.description.${lang}`} label="Description" className={classes.textInput} />
        </FormTab>

      </VerticalTabbedForm>
    </MyEdit>
  );
};

MsForGoodPageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default MsForGoodPageEditForm;
