import React from 'react';
import { CheckboxGroupInput, Create, ImageField, ImageInput, NumberInput, SimpleForm, TextInput, required } from 'react-admin';
import { Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Config from '../../../config';
import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
  },
  separator: {
    marginTop: '2rem',
  },
}));

const ClassComingSoonCreate = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  return (
    <Create {...props} title="Créer un Cours bientôt disponible" actions={Actions} className="edit-wrapper">
      <SimpleForm key={lang} toolbar={<EditToolbar />} variant="outlined">
        <TextInput source="id" validate={[required()]} />

        <NumberInput source="order" label="Rang" initialValue={1} />
        <TextInput source="name" label="Nom" className={classes.textInput} initialValue="" />
        <TextInput source={`domain.${lang}`} label="Domaine" className={classes.textInput} initialValue="" />
        <TextInput source={`teaches.${lang}`} label="Enseignement" className={classes.textInput} initialValue="" />
        <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} initialValue="" />

        <Typography variant="body1" className={classes.separator}>
          Sélectionnez les langues pour lesquelles vous voulez ajouter cet élément. Si aucune case n'est cochée, alors l'élément sera affiché sur toutes les langues du site.
        </Typography>
        <CheckboxGroupInput source="languages" choices={Config.supportedLocales.map((locale) => ({ id: locale, name: locale.toUpperCase() }))} row={false} initialValue={[]} validate={[required()]} />

        <Typography variant="subtitle1" gutterBottom className={classes.separator}>
          Image
        </Typography>
        <ImageInput source="image" label="Photo d'aperçu" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source={`image.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} initialValue="" />
      </SimpleForm>
    </Create>
  );
};

export default ClassComingSoonCreate;
