export const formatTime = (duration) => {
  let s = Math.floor((duration / 1000) % 60);
  let m = Math.floor((duration / (1000 * 60)) % 60);
  let h = Math.floor((duration / (1000 * 60 * 60)) % 24);

  h = h === 0 ? '' : `${h}h `;
  m = m === 0 ? '' : `${m}m `;
  s = `${s}s`;

  return `${h}${m}${s}`;
};
