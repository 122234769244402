import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  crudUpdate as crudUpdateAction,
  showNotification as showNotificationAction,
} from 'react-admin';

import {
  Button,
  CircularProgress,
} from '@material-ui/core';

const ApproveButton = ({
  basePath, crudUpdate, resource, record, showNotification, push,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleApprove = async (approved) => {
    setIsLoading(true);
    const updatedRecord = { ...record, approved };

    try {
      await crudUpdate(
        resource,
        record.id,
        updatedRecord,
        record,
        basePath,
      );

      showNotification('Comment approved');
      push('/comments');
    } catch (error) {
      showNotification('Error: comment not approved', 'warning');
    }

    setIsLoading(false);
  };

  if (record.approved) {
    return (
      <Button style={{ color: 'red' }} onClick={() => handleApprove(false)}>
        {isLoading ? <CircularProgress size={25} /> : 'Disapprove'}
      </Button>
    );
  }

  return (
    <Button color="primary" onClick={() => handleApprove(true)}>
      {isLoading ? <CircularProgress size={25} /> : 'Approve'}
    </Button>
  );
};

ApproveButton.propTypes = {
  basePath: PropTypes.any.isRequired,
  crudUpdate: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  push: PropTypes.func,
  showNotification: PropTypes.func.isRequired,
};

ApproveButton.defaultProps = {
  push: () => {},
};

export default connect(null, { crudUpdate: crudUpdateAction, showNotification: showNotificationAction })(
  ApproveButton,
);
