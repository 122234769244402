import PropTypes from 'prop-types';
import React from 'react';

const UserTitle = ({ record }) => {
  return (
    <span>
      {`Utilisateur: ${record.firstName || ''} ${record.lastName || ''} ${!record.firstName && !record.lastName ? record.email : ''}`}
    </span>
  );
};

UserTitle.propTypes = {
  record: PropTypes.object,
};

UserTitle.defaultProps = {
  record: null,
};

export default UserTitle;
