String.prototype.slugify = function (separator = "-") {
  return this
    .toString()
    .normalize('NFD')                   // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator);
};

export default {
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  },

  environment: process.env.REACT_APP_ENVIRONMENT,
  frontUrl: process.env.REACT_APP_FRONT_URL,

  supportedLocales: ['fr', 'en', 'es'],

  envTargets: {
    preview: 'preview',
    prod: 'production',
  },

  richTextToolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    [{ color: ['#ffffff', '#000000', '#1e1e1e', '#1e2022', '#3c3a3a', '#979797', '#999999', '#eb930d', '#e1a323', '#f3bf55', '#bf352e', '#ca2626', '#93bdb8', '#2C75E7'] }],
  ],

  userRoles: {
    role_moderator: 'Modérateur',
    role_admin: 'Administrateur',
  },

  vercel: {
    baseURl: 'https://api.vercel.com',
    token: 'JKGQXL4dALoIR5bT9Jy52knO',
    teamId: 'team_4NK5t7lKmORcAkK3IomLXpAT',
    projectName: 'mentorshow-v2',
    projectId: 'prj_TvIhpf5nyIfaBJCMGtMXnkssL0T6',
    hooks: {
      preview: 'https://api.vercel.com/v1/integrations/deploy/prj_TvIhpf5nyIfaBJCMGtMXnkssL0T6/LszhsLTiMW',
      prod: 'https://api.vercel.com/v1/integrations/deploy/prj_TvIhpf5nyIfaBJCMGtMXnkssL0T6/ZNoVjq5BvK',
    },
  },

  stripePrices: {
    prices: [
      { id: process.env.REACT_APP_STRIPE_PRICE_PASS_UPFRONT, name: 'Pass Upfront' },
      { id: process.env.REACT_APP_STRIPE_PRICE_PASS_WEBINAR, name: 'Pass Webinar' },
      { id: process.env.REACT_APP_STRIPE_PRICE_PASS_POST_WEBINAR, name: 'Pass Post Webinar' },
      { id: process.env.REACT_APP_STRIPE_PRICE_PASS_MONTHLY, name: 'Pass Monthly' },
      { id: process.env.REACT_APP_STRIPE_PRICE_PASS_GIFT, name: 'Pass Gift' },
      { id: process.env.REACT_APP_STRIPE_PRICE_MSPLUS_NOMINAL, name: 'MS Plus Nominal' },
      { id: process.env.REACT_APP_STRIPE_PRICE_MSPLUS_MONTHLY, name: 'MS Plus Monthly' },
      { id: process.env.REACT_APP_STRIPE_PRICE_MSPLUS_WEBINAR, name: 'MS Plus Webinar' },
      { id: process.env.REACT_APP_STRIPE_PRICE_BUNDLE_NOMINAL, name: 'Bundle Nominal' },
      { id: process.env.REACT_APP_STRIPE_PRICE_BUNDLE_MONTHLY, name: 'Bundle Monthly' },
      { id: process.env.REACT_APP_STRIPE_PRICE_BUNDLE_WEBINAR, name: 'Bundle Webinar' },
    ],
    prices3x: {
      [process.env.REACT_APP_STRIPE_PRICE_UPFRONT]: process.env.REACT_APP_STRIPE_PRICE_UPFRONT_3X,
      [process.env.REACT_APP_STRIPE_PRICE_WEBINAR]: process.env.REACT_APP_STRIPE_PRICE_WEBINAR_3X,
      [process.env.REACT_APP_STRIPE_PRICE_POST_WEBINAR]: process.env.REACT_APP_STRIPE_PRICE_POST_WEBINAR_3X,
      [process.env.REACT_APP_STRIPE_PRICE_GIFT]: process.env.REACT_APP_STRIPE_PRICE_GIFT_3X,
    }
  },
};
