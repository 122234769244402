import PropTypes from 'prop-types';
import React, { Children, cloneElement, isValidElement, useState } from 'react';
import { FormWithRedirect, escapePath } from 'react-admin';
import { Route, useRouteMatch, useLocation } from 'react-router-dom';

import { getTabFullPath } from './TabbedFormTabs';

const CustomForm = (props) => {
  const {
    basePath,
    children,
    className,
    classes: classesOverride,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    mutationMode,
    pristine,
    record,
    redirect: defaultRedirect,
    resource,
    saving,
    submitOnEnter,
    syncWithLocation = true,
    tabs,
    toolbar,
    undoable,
    variant,
    margin,
    validating,
    ...rest
  } = props;

  const match = useRouteMatch();
  const location = useLocation();

  const [tabValue, setTabValue] = useState(0);
  
  const url = match ? match.url : location.pathname;

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  return (
    <div style={{ paddingTop: 0 }}>
      <form
        // not sanitizing
        style={{ display: 'flex' }}
        {...sanitizeRestProps(rest)}
      >
        <div style={{ width: '220px' }}>
          {React.cloneElement(
            tabs,
            {
                // classes,
                url,
                onChange: handleTabChange,
                value: tabValue,
            },
            children
          )}
        </div>

        <div style={{ flexGrow: '1', padding: '16px', borderLeft: '1px solid #ccc', marginLeft: 0 }}>
          {Children.map(children, (tab, index) => {
            
            const tabPath = getTabFullPath(tab, index, url);

            return (
              tab && (
                <Route
                  exact
                  path={escapePath(tabPath)}
                >
                  {(routeProps) => isValidElement(tab)
                    ? React.cloneElement(tab, {
                        intent: 'content',
                        resource,
                        record,
                        basePath,
                        hidden: !routeProps.match,
                        variant:
                            tab.props.variant || variant,
                        margin:
                            tab.props.margin || margin,
                        value: tabPath,
                      })
                    : null
                  }
                </Route>
              )
            );
          })}
        </div>
      </form>

      {toolbar && cloneElement(toolbar, {
        basePath,
        className: 'toolbar',
        handleSubmitWithRedirect,
        handleSubmit,
        invalid,
        mutationMode,
        pristine,
        record,
        redirect: defaultRedirect,
        resource,
        saving,
        submitOnEnter,
        validating,
        undoable,
      })}

    </div>
  );
};

export const VerticalTabbedForm = (props) => (
  <FormWithRedirect {...props} render={(formProps) => <CustomForm {...formProps} />} sanitizeEmptyValues={false} />
);

CustomForm.defaultProps = {
  submitOnEnter: true,
};

CustomForm.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleSubmit: PropTypes.func, // passed by redux-form
  invalid: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  tabsWithErrors: PropTypes.arrayOf(PropTypes.string),
  toolbar: PropTypes.element,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  value: PropTypes.number,
  version: PropTypes.number,
};

const sanitizeRestProps = ({
  active,
  basePath,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  error,
  errors,
  form,
  hasSubmitErrors,
  hasValidationErrors,
  initialValues,
  modified = null,
  modifiedSinceLastSubmit,
  save = null,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touched = null,
  valid,
  values,
  visited = null,
  __versions = null,
  ...props
}) => props;
