import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';

import { ArrayInput, SimpleForm, SimpleFormIterator, TextInput } from 'react-admin';

import EditToolbar from '../../../common/EditToolbar';
import MyEdit from '../../../layout/MyEdit';
import PageEditActions from '../../pages/PageEditActions';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  column: {
    padding: '0 50px',
    border: '1px solid #dedede',
  },
  card: {
    width: '100%',
    margin: '20px auto',
  },
}));

const MenuTitle = ({ record }) => {
  return <span>Menu {record ? `"${record.id}"` : ''}</span>;
};

MenuTitle.propTypes = {
  record: PropTypes.any,
};

MenuTitle.defaultProps = {
  record: null,
};

const HeaderMenuEdit = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  return (
    <MyEdit {...props} title={<MenuTitle />} actions={Actions} className="edit-wrapper">
      <SimpleForm key={lang} toolbar={<EditToolbar withDelete />} redirect={false} variant="outlined">
        <TextInput disabled source="id" />

        <Typography>Le dernier élément du menu est toujours considéré comme élément "Pass illimité".</Typography>
        <Typography>Il est affiché ou masqué selon si l'utilisateur a un accès Pass ou non.</Typography>

        <ArrayInput source="items" label="Items du menu" style={{ width: '100%' }}>
          <SimpleFormIterator>
            <TextInput source={`label.${lang}`} label={`Libellé (${lang})`} className={classes.textInput} variant="outlined" />
            <TextInput source="path" label="Lien" className={classes.textInput} variant="outlined" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </MyEdit>
  );
};

HeaderMenuEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export default HeaderMenuEdit;
