import PropTypes from 'prop-types';
import React from 'react';
import RichTextInput from 'ra-input-rich-text';

import { Typography, makeStyles } from '@material-ui/core';

import {
  ArrayInput,
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import Config from '../../../../../config';
import EditToolbar from '../../../../common/EditToolbar';
import PageTitle from '../PageTitle';
import TabbedFormTabs from '../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../layout/VerticalTabbedForm';
import MyEdit from '../../../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
}));

const ReferralInvitePageEditForm = ({ lang, ...props }) => {
  const classes = useStyles();

  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    });
  }, [lang]);

  if (refresh) {
    return null;
  }

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm
        toolbar={<EditToolbar />}
        redirect={false}
        tabs={<TabbedFormTabs />}
        variant="outlined"
      >
        <FormTab label="Général">
          <TextInput disabled source="id" />
        </FormTab>

        <FormTab label="SEO">
          <div className={classes.separator} />
          <Typography variant="subtitle1" gutterBottom>SEO</Typography>

          <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} />

          <ImageInput source="seo.image" label="Photo d'aperçu SEO" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Hero">

          <TextInput source={`sections.hero.title.${lang}`} multiline label="Titre" className={classes.textInput} />

          <TextInput source={`sections.hero.description.${lang}`} multiline label="Description" className={classes.textInput} />

          <RichTextInput source={`sections.hero.hint.${lang}`} label="Indication" className={classes.textInput} toolbar={Config.richTextToolbar} />

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>Image</Typography>

          <ImageInput source="sections.hero.image" label="Photo arrière plan" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source={`sections.hero.image.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} />
        </FormTab>

        <FormTab label="Raisons">
          <TextInput source={`sections.reasons.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.reasons.sectionSubtitle.${lang}`} label="Sous-titre" className={classes.textInput} />

          <ArrayInput source="sections.reasons.items" label="Items" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="image" label="Icône" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
              <TextInput source={`text.${lang}`} label="Description" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="FAQ">
          <TextInput source={`sections.faq.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.faq.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />

          <ArrayInput source="sections.faq.items" label="Items" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`question.${lang}`} label="Question" className={classes.textInput} />
              <TextInput source={`answer.${lang}`} label="Réponse" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

      </VerticalTabbedForm>
    </MyEdit>
  );
};

ReferralInvitePageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default ReferralInvitePageEditForm;
