import PropTypes from 'prop-types';
import React from 'react';
import RichTextInput from 'ra-input-rich-text';
import { Typography, makeStyles } from '@material-ui/core';
import { ArrayInput, AutocompleteInput, BooleanInput, CheckboxGroupInput, DateInput, FileField, FileInput, FormTab, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleFormIterator, TextInput, required } from 'react-admin';
import { useSelector } from 'react-redux';

import Config from '../../../config';
import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';
import TabbedFormTabs from '../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../layout/VerticalTabbedForm';
import MyEdit from '../../layout/MyEdit';
import MyClassEdit from '../../layout/MyClassEdit';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  sectionTitle: {
    fontSize: 16,
    marginTop: 30,
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
    width: '100%',
  },
}));

const ClassTitle = ({ record }) => {
  return <span>Cours {record ? `"${record.mentor?.name} ${record.mentor?.teaches?.fr}"` : ''}</span>;
};

ClassTitle.propTypes = {
  record: PropTypes.any,
};

ClassTitle.defaultProps = {
  record: null,
};

const ClassEdit = (props) => {
  const { basePath, record } = props;

  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  /* React.useEffect(async () => {
    const lists = await fetch('https://mentorshow.api-us1.com/api/3/lists', {
      headers: {
        'Api-Token': '64371e1e644b5f359f72f69d4ee55a15896f14c7ce75c364b2190d2cf0fe75330dc55c98',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

    console.log('lists', lists);
  }, []); */

  const Actions = <PageEditActions basePath={basePath} record={record} />;

  return (
    <MyClassEdit {...props} title={<ClassTitle />} actions={Actions} className="edit-wrapper">
      <VerticalTabbedForm key={lang} toolbar={<EditToolbar />} redirect={false} tabs={<TabbedFormTabs />} variant="outlined">
        <FormTab label="Général">
          <BooleanInput source="published" label="Publié" />
          <BooleanInput source="mobileOnly" label="Accessible sur mobile uniquement" />

          <TextInput disabled source="id" />

          <DateInput source="launchDate" label="Date de lancement" validate={[required()]} />

          <NumberInput source="order" label="Rang" className={classes.separator} />
          <NumberInput source="rating.average" label="Note" helperText="Cette note sera traduite en étoiles" />
          <ReferenceInput label="Catégorie" source="category" reference="content/mentorshow/categories" filterToQuery={(searchText) => (searchText ? { [`name.${lang}`]: searchText } : null)}>
            <AutocompleteInput optionText={`name.${lang}`} />
          </ReferenceInput>
          <BooleanInput source="featured" label="Mis en avant" />

          <Typography variant="subtitle1" gutterBottom>
            Bande annonce
          </Typography>

          <TextInput source={`sections.thriller.sectionSubtitle.${lang}`} multiline label="Sous-titre Bande annonce" className={classes.textInput} initialValue="" />
          <TextInput source="sections.thriller.videoId" label="ID Vidéo Bande annonce" className={classes.textInput} />

          <Typography variant="subtitle1" gutterBottom>
            ActiveCompaign
          </Typography>

          <TextInput source="activeCompaign.leadsList" label="AC Liste Prospects" className={classes.textInput} initialValue="" />
          <TextInput source="activeCompaign.clientsList" label="AC Liste Clients" className={classes.textInput} initialValue="" />
          <TextInput source="activeCompaign.promoLeadsList" label="AC Liste Prospects landing page" className={classes.textInput} initialValue="" />
          <TextInput
            source="activeCompaign.startedClassTagId"
            label="AC ID Tag commencement cours"
            className={classes.textInput}
            initialValue=""
            helperText="Si ce champ est renseigné, le tag correspondant sera ajouté aux utilisateurs (ayant un pass) qui commencent ce cours pour la première fois"
          />

          <Typography variant="body1" className={classes.separator}>
            Sélectionnez les langues pour lesquelles vous voulez afficher ce cours.
          </Typography>
          <CheckboxGroupInput
            source="languages"
            label=""
            choices={Config.supportedLocales.map((locale) => ({ id: locale, name: locale.toUpperCase() }))}
            row={false}
            helperText="Si aucune case n'est cochée, alors l'élément ne sera jamais affiché sur le site."
          />
        </FormTab>

        <FormTab label="ID Produit">
          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Bon cadeau
          </Typography>
          <TextInput source={`pricing.thriveProductIdGift.${lang}`} label={`ID Produit ThriveCart (Cadeau) (${lang})`} className={classes.textInput} helperText={`ID produit ThriveCart pour cadeau (${lang})`} initialValue="" />
          <SelectInput source="pricing.gift.priceId" options={{ label: "Price option" }} choices={Config.stripePrices.prices}/>

          <Typography variant="subtitle1" gutterBottom>
            Offre Webinaire
          </Typography>
          <div>
            <Typography variant="caption">Mettez ici les id de produits ou A/B tests à afficher (les ids produits doivent être également mis dans les ids des offres spéciales)</Typography>
          </div>
          <TextInput source={`pricing.thriveProductIdWebinar.${lang}`} label={`ID Produit ThriveCart (webinar) (${lang})`} helperText={`ID produit ThriveCart webinar (${lang})`} initialValue="" />
          <TextInput source={`pricing.thriveProductIdWebinar2.${lang}`} label={`ID Produit ThriveCart (webinar 2) (${lang})`} className={classes.separator} helperText={`ID produit ThriveCart webinar 2 (${lang})`} initialValue="" />
          <SelectInput source="pricing.webinar.priceId" options={{ label: "Price option" }} choices={Config.stripePrices.prices}/>

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Offre Post-Webinaire
          </Typography>
          <div>
            <Typography variant="caption">Mettez ici l'id produit ou A/B tests à afficher (les ids produits doivent être également mis dans les ids des offres spéciales)</Typography>
          </div>
          <TextInput source={`pricing.thriveProductIdPostWebinar.${lang}`} label={`ID Produit ThriveCart Post-Webinar (${lang})`} helperText={`ID produit ThriveCart (Post-Webinar) (${lang})`} initialValue="" />
          <SelectInput source="pricing.postWebinar.priceId" options={{ label: "Price option" }} choices={Config.stripePrices.prices}/>

          <Typography variant="caption" gutterBottom className={classes.separator}>
            CTA
          </Typography>
          <TextInput source={`sections.hero.ctaSpecialOffer.description.${lang}`} label="CTA Description Post-Webinar" className={classes.textInput} helperText="Si ce champ est saisi, il remplace celui défini au niveau du Hero" initialValue="" />
          <TextInput source={`sections.hero.ctaSpecialOffer.descriptionBlock.${lang}`} label="Texte du block CTA Post-Webinar" helperText="Si ce champ est saisi, il remplace celui défini au niveau du Hero" initialValue="" />
        </FormTab>

        <FormTab label="Création checkout">
          <div className={classes.separator}>
            <Typography variant="body2">Page Sign up</Typography>
          </div>
          <TextInput source={`webinarSignup.title.${lang}`} label={`Titre (${lang})`} className={classes.separator} helperText={`Titre de la page Webinar Sign up (${lang})`} />
          <TextInput source={`webinarSignup.fieldLabels.email.${lang}`} label={`Libellé champ Email (${lang})`} className={classes.separator} helperText={`Libellé du champ Email (placeholder) (${lang})`} />
          <TextInput source={`webinarSignup.fieldLabels.password.${lang}`} label={`Libellé champ Mot de passe (${lang})`} className={classes.separator} helperText={`Libellé du champ Mot de passe (placeholder) (${lang})`} />

          <Typography variant="subtitle1" gutterBottom>
            Bénéfices
          </Typography>
          <TextInput source={`checkout.benefits.title.${lang}`} label={`Bénéfices Titre (${lang})`} className={classes.textInput} initialValue="" />

          <ArrayInput source="checkout.benefits.items" label="Descriptions" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`text.${lang}`} label="Titre" className={classes.textInput} initialValue="" />
            </SimpleFormIterator>
          </ArrayInput>

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Autres Offres Spéciales
          </Typography>
          <div>
            <Typography variant="caption">Ici, vous pouvez créer des offres spéciales avec un checkout dédié. Pour chaque offre, définissez:</Typography>
          </div>
          <div>
            <Typography variant="caption">{`- ID de l'offre: permet de définir le slug de l'offre et donc son URL => /checkout/{offerId}/{classId}`}</Typography>
          </div>
          <div>
            <Typography variant="caption">{`- ID produit thrivecart: Le produit à afficher sur le checkout (ou l'id de l'A/B test)`}</Typography>
          </div>

          <ArrayInput source="pricing.specialOffers" label="">
            <SimpleFormIterator>
              <TextInput source="offerId" label="ID de l'offre" className={classes.textInput} helperText="En minuscules, ne doit pas contenir des caractères spéciaux autres que '-' et '_'. Ex: we, fl, we-pass" />
              <TextInput source="description" label="Description" className={classes.textInput} helperText="La description est seulement pour un usage interne pour repérer les offres. Elle n'est affichée qu'ici" />
              <TextInput source="productId" label="ID Produit Thrivecart" className={classes.textInput} helperText="ID du produit ou de l'A/B test à afficher dans le checkout" />
              <TextInput source="couponId" label="ID Coupon" className={classes.textInput} helperText="ID du coupon de réduction Stripe à appliquer pour cette offre" />
              <TextInput source="signupTitle" label="Titre Signup" className={classes.textInput} helperText="Titre à afficher lors du signup" />
              <TextInput source="signupDescription" label="Description Signup" className={classes.textInput} helperText="Texte à afficher lors du signup (en dessous du titre)" />
              <ImageInput source="image" label="Photo de la page" accept="image/*" helperText="Si cette image n'est pas renseignée, la photo du mentor (hero, par défaut) sera affichée">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Prévente">
          <BooleanInput source="isPreSale" label="Prévente" />

          <TextInput source={`preSaleDispoLabel.${lang}`} multiline label="Label de disponibilité" className={classes.textInput} initialValue="" />
        </FormTab>

        <FormTab label="SEO">
          <Typography variant="subtitle1" gutterBottom>
            SEO
          </Typography>

          <TextInput source={`seo.title.${lang}`} label="Titre" className={classes.textInput} initialValue="" />
          <TextInput source={`seo.description.${lang}`} label="Description" className={classes.textInput} initialValue="" />

          <ImageInput source="seo.image" label="Photo d'aperçu SEO" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Vignette (card)">
          <BooleanInput source="teaser.isNew" label={'Afficher le badge "New"'} />
          <TextInput source="teaser.name" label="Nom" className={classes.textInput} initialValue="" />
          <TextInput source={`teaser.domain.${lang}`} label="Domaine" className={classes.textInput} initialValue="" />
          <TextInput source={`teaser.teaches.${lang}`} label="Enseignement" className={classes.textInput} initialValue="" />
          <TextInput source={`teaser.description.${lang}`} label="Description" className={classes.textInput} initialValue="" />

          <Typography variant="subtitle1" gutterBottom>
            Image
          </Typography>

          <ImageInput source="teaser.image" label="Photo d'aperçu" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source={`teaser.image.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} initialValue="" />

          {/*<Typography variant="subtitle1" gutterBottom>
            Vertical card image
          </Typography>

          <ImageInput source="teaser.verticalImage" label="Photo d'aperçu" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source={`teaser.verticalImage.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} initialValue="" />
  */}
          <Typography variant="subtitle1" gutterBottom>
            Mise en avant
          </Typography>
          <Typography variant="body2" gutterBottom className={classes.textInput}>
            En activant cette option, la card sera affichée en grand au dessus des autres
          </Typography>

          <BooleanInput source="teaser.featured" label="Mis en avant" />
          <TextInput multiline source={`teaser.title.${lang}`} label="Titre" className={classes.textInput} helperText="Le titre ne sera affiché que si la card est mise en avant" initialValue="" />
        </FormTab>

        <FormTab label="Hero">
          <Typography variant="subtitle1" gutterBottom>
            Site vittrine
          </Typography>
          <TextInput source={`sections.hero.introTitle.${lang}`} label="Titre Introduction (Site vitrine)" className={classes.textInput} initialValue="" />
          <TextInput multiline source={`sections.hero.introCommercial.${lang}`} label="Texte Introduction (Site vitrine)" className={classes.textInput} initialValue="" />


          <Typography variant="subtitle1" gutterBottom>
            Texte Hero
          </Typography>
          <TextInput multiline source={`sections.hero.heroText.${lang}`} label="`Texte de la hero section" className={classes.textInput} initialValue="" />


          <Typography variant="subtitle1" gutterBottom>
            CTA
          </Typography>
          <TextInput source={`sections.hero.cta.description.${lang}`} label="CTA Description" className={classes.textInput} helperText="Si ce champ est saisi, il remplace celui défini au niveau de la page" initialValue="" />
          <TextInput source={`sections.hero.cta.descriptionBlock.${lang}`} label="Texte du block CTA" className={classes.textInput} helperText="Si ce champ est saisi, il remplace celui défini au niveau de la page" initialValue="" />

          <Typography variant="subtitle1" gutterBottom>
            Espace membres
          </Typography>
          <TextInput multiline source={`sections.hero.intro.${lang}`} label="Texte Introduction (Espace membres)" className={classes.textInput} initialValue="" />

          <Typography variant="subtitle1" gutterBottom>
            Image
          </Typography>

          <ImageInput source="sections.hero.image" label="Photo" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source={`sections.hero.image.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} initialValue="" />
        </FormTab>

        <FormTab label="Trailer Bloc">
          <Typography variant="subtitle1" gutterBottom>
            Texte du bloc bande d'annonce
          </Typography>
          <TextInput multiline source={`sections.trailerBloc.text.${lang}`} label="Texte du bloc Trailer" className={classes.textInput} initialValue="" />
        </FormTab>

        <FormTab label="Mentor">
          <TextInput source={`mentor.sectionTitle.${lang}`} label="Titre de la section" className={classes.textInput} initialValue="Apprenez à connaître votre instructeur" />
          <TextInput source={`mentor.sectionSubtitle.${lang}`} label="Sous-titre de la section" className={classes.textInput} initialValue="" />
          <TextInput source="mentor.name" label="Nom" className={classes.textInput} initialValue="" />
          <TextInput source={`mentor.domain.${lang}`} label="Domaine" className={classes.textInput} initialValue="" />
          <TextInput source={`mentor.teaches.${lang}`} label="Enseignement" className={classes.textInput} initialValue="" />
          <TextInput source={`mentor.intro.title.${lang}`} label="Accroche Introduction" className={classes.textInput} initialValue="" />
          <RichTextInput source={`mentor.intro.text.${lang}`} label="Texte Introduction" className={classes.textInput} toolbar={Config.richTextToolbar} />

          <Typography variant="subtitle1" gutterBottom>
            Image
          </Typography>

          <ImageInput source="mentor.imagePresentation" label="Photo de présentation" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source={`mentor.imagePresentation.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} initialValue="" />
          <TextInput source={`mentor.cta.title.${lang}`} label="Titre du bloc CTA" className={classes.textInput} helperText="Si ce champ est saisi, il remplace celui défini au niveau de la page" />

          <ImageInput source="mentor.imageOverview" label="Image de couverture" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput source="mentor.imageMentorName" label="Image de nom du mentor" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Leçons">
          <TextInput source={`sections.lessons.sectionSubtitle.${lang}`} label="Sous-titre de la section" className={classes.textInput} initialValue="" />
          <TextInput source="sections.lessons.sampleVideoId" label="ID Wistia de la vidéo d'extrait" className={classes.textInput} initialValue="" />

          <ArrayInput source="sections.lessons.chapters" label="Liste des chapitres" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`title.${lang}`} label="Titre du chapitre" className={classes.textInput} initialValue="" />
              <TextInput source={`description.${lang}`} label="Description du chapitre" className={classes.textInput} initialValue="" />
              <ArrayInput source="lessonsIdx" label="Numéro des leçons du chapitre" style={{ width: '100%' }}>
                <SimpleFormIterator>
                  <NumberInput label="Numéro de la leçon" className={classes.textInput} initialValue="" />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>

          <ArrayInput source="sections.lessons.items" label="Liste des cours" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} initialValue="" />
              <NumberInput source="number" label="Rang" className={classes.textInput} />
              <NumberInput source="duration" label="Durée (en secondes)" className={classes.textInput} initialValue="" />
              <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} initialValue="" />
              <TextInput source="videoId" label="ID Video" className={classes.textInput} />

              <Typography variant="subtitle1" gutterBottom>
                Image
              </Typography>

              <ImageInput source="thumbnailImage" label="Image" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput source={`thumbnailImage.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} initialValue="" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Citation">
          <Typography variant="subtitle1" gutterBottom>
            Citation du mentor
          </Typography>

          <TextInput source={`sections.mentorQuote.text.${lang}`} label="Citation" className={classes.textInput} initialValue="" />
        </FormTab>

        <FormTab label="Témoignages">
          <TextInput source={`sections.testimonials.sectionTitle.${lang}`} label="Titre de la section" className={classes.textInput} initialValue="Ils ont suivi le programme et témoignent..." />

          <ArrayInput source={`sections.testimonials.items.${lang}`} label="Vidéos" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="description" label="Description" className={classes.textInput} initialValue="" />
              <TextInput source="videoId" label="ID vidéo" className={classes.textInput} initialValue="" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Communauté">
          <TextInput source={`sections.community.sectionTitle.${lang}`} label="Titre de la section" className={classes.textInput} initialValue="Une communauté à votre écoute" />

          <ArrayInput source={`sections.community.items.${lang}`} label="Arguments" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="icon" label="Icône" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput source="text" label="Texte" className={classes.textInput} initialValue="" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Récapitulatif">
          <TextInput source={`sections.why.sectionSubtitle.${lang}`} label="Sous-titre de la section" className={classes.textInput} initialValue="" />

          <ArrayInput source="sections.why.items" label="Pourquoi" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`text.${lang}`} label="Description" className={classes.textInput} initialValue="" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Promesses">
          <TextInput source={`sections.promises.sectionSubtitle.${lang}`} label="Sous-titre de la section" className={classes.textInput} initialValue="" />

          <ArrayInput source="sections.promises.items" label="Promesses" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} initialValue="" />
              <TextInput source={`text.${lang}`} label="Description" className={classes.textInput} initialValue="" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Ressources">
          <TextInput source={`sections.resources2.sectionSubtitle.${lang}`} label={`Sous-titre de la section (${lang})`} className={classes.textInput} initialValue="" />

          <ArrayInput source={`sections.resources2.items.${lang}`} label={`Ressources du cours (${lang})`} style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="title" label="Titre" className={classes.textInput} initialValue="" />
              <TextInput source="description" label="Description" className={classes.textInput} initialValue="" />
              <FileInput source="file" label="Fichier">
                <FileField source="src" title="Fichier PDF" />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Aperçu">
          <BooleanInput source="sections.preview.enabled" label="Activé" />
          <Typography variant="subtitle1" gutterBottom>
            Card
          </Typography>
          <TextInput source="sections.preview.videoId" label="ID Vidéo Wistia" className={classes.textInput} initialValue="" />
          <TextInput multiline source={`sections.preview.cardTitle.${lang}`} label="Titre card" className={classes.textInput} initialValue="" />

          <Typography variant="subtitle1" gutterBottom>
            Popup
          </Typography>
          <TextInput source={`sections.preview.title.${lang}`} label="Titre popup" className={classes.textInput} initialValue="" />
          <TextInput source={`sections.preview.pricingText.${lang}`} label="Texte offre" className={classes.textInput} initialValue="" />
          <TextInput source={`sections.preview.ctaLabel.${lang}`} label="Label CTA" className={classes.textInput} initialValue="" />

          <Typography variant="subtitle1" gutterBottom>
            Témoignage
          </Typography>
          <TextInput source={`sections.preview.testimonial.name.${lang}`} label="Nom" className={classes.textInput} initialValue="" />
          <TextInput source={`sections.preview.testimonial.text.${lang}`} label="Texte" className={classes.textInput} initialValue="" />
          <NumberInput source={`sections.preview.testimonial.rating.${lang}`} label="Note (/5)" className={classes.textInput} />

          <Typography variant="subtitle1" gutterBottom>
            Description
          </Typography>
          <TextInput source={`sections.preview.description.title.${lang}`} label="Titre" className={classes.textInput} initialValue="" />
          <TextInput source={`sections.preview.description.text.${lang}`} label="Texte" className={classes.textInput} initialValue="" />
        </FormTab>

        <FormTab label="Descriptions">
          <ArrayInput source="sections.descriptions.items" label="Descriptions" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
              <RichTextInput source={`text.${lang}`} label="Texte" toolbar={Config.richTextToolbar} />
              <ImageInput source="image" label="Photo d'aperçu" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Admin Config">
          <AutocompleteInput
            source="adminWarningColor"
            label="Couleur"
            choices={[
              { id: '#ff4545', name: 'Rouge' },
              { id: '#61d361', name: 'Vert' },
              { id: '#ff922e', name: 'Orange' },
            ]}
          />
          <RichTextInput source="adminWarningText" label="Instructions" toolbar={Config.richTextToolbar} />
        </FormTab>
      </VerticalTabbedForm>
    </MyClassEdit>
  );
};

ClassEdit.propTypes = {
  basePath: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  record: PropTypes.any,
};

ClassEdit.defaultProps = {
  record: null,
};

export default ClassEdit;
