import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { Edit, SimpleForm, TextInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import Config from '../../../config';
import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';
import PageTitle from './PageTitle';
import MyEdit from '../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  sectionTitle: {
    fontSize: 16,
    marginTop: 30,
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
}));

const defaultValues = {
  createdAt: new Date(),
  lastupdate: new Date(),
};

const StaticPageEdit = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  return (
    <MyEdit {...props} title={<PageTitle />} actions={Actions} className="edit-wrapper">
      <SimpleForm key={lang} toolbar={<EditToolbar withDelete />} initialValues={defaultValues} redirect={false} variant="outlined">
        <TextInput disabled source="id" />
        <TextInput source={`title.${lang}`} label={`Titre (${lang})`} className={classes.textInput} />
        <RichTextInput source={`body.${lang}`} label={`Contenu (${lang})`} className={classes.textInput} toolbar={Config.richTextToolbar} />
      </SimpleForm>
    </MyEdit>
  );
};

StaticPageEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export default StaticPageEdit;
