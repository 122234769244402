import Countries from './countries.json';

const getCountries = (lang) => {
  const excludedCountries = ['AQ', 'BQ', 'CW', 'GG', 'IM', 'JE', 'XK', 'BL', 'MF', 'SX', 'SS'];

  return Countries.filter((country) => !excludedCountries.includes(country.alpha2Code)).map((country) => ({
    key: country.alpha2Code,
    value: country.alpha2Code,
    text: country.translations[lang] || country.name,
    flag: country.alpha2Code.toLowerCase(),
  }));
};

export {
  getCountries,
};
