import PropTypes from 'prop-types';
import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { Button, Checkbox, CircularProgress, Dialog, DialogTitle, DialogContent, FormControlLabel, List, ListItem, ListItemText, Paper, Radio, RadioGroup, TextField, Typography, makeStyles } from '@material-ui/core';
import { crudUpdate as crudUpdateAction, crudGetOne as crudGetOneAction, showNotification as showNotificationAction, CheckboxGroupInput, ReferenceField } from 'react-admin';

import Config from '../../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '1em',
    marginBottom: '1em',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    root: {
      backgroundColor: '#ff4567',
    },
  },
}));

const UserPassBundle = ({ basePath, crudGetOne, crudUpdate, record, resource, showNotification, ...props }) => {
  const classes = useStyles();

  console.log('props', props);

  const now = dayjs();
  const nextYear = now.add(1, 'year').endOf('day');
  const nextYearString = nextYear.format('YYYY-MM-DD');

  const [isLoading, setIsLoading] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [passType, setPassType] = React.useState('payable');
  const [nextDate, setNextDate] = React.useState(nextYearString);
  const [doNotBillTillDate, setDoNotBillTillDate] = React.useState(false);

  console.log('nextDate', nextDate);

  if (!record) {
    return null;
  }

  const cancelPass = async () => {
    setIsLoading(true);

    try {
      await fetch(`${Config.frontUrl}/api/admin/pass/cancel`, {
        method: 'POST',
        body: JSON.stringify({
          uid: record.id,
          type: 'bundle',
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      await crudGetOne(resource, record.id, basePath);

      //TODO:Replace with kinesis
      // fetch(`${Config.parseServer.apiUrl}/class-pass-events`, {
      //   method: 'POST',
      //   body: JSON.stringify({
      //     event: 'pass',
      //     uid: record.id,
      //     active: false,
      //     date: new Date(),
      //   }),
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // });

      showNotification('Pass illimité annulé');
    } catch (error) {
      console.log('error', error);
      showNotification("Impossible d'annuler le pass illimité", 'error');
    }

    setIsLoading(false);
  };

  const activatePass = async () => {
    if (dayjs(nextDate).isBefore(dayjs())) {
      return;
    }

    setIsLoading(true);

    if (passType === 'free') {
      crudUpdate(
        resource,
        record.id,
        {
          ...record,
          purchases: {
            ...record.purchases,
            bundle: {
              ...record.purchases?.bundle,
              isRenewable: false,
              active: false,
              date: now.toDate(),
              currentPeriodEnd: nextYear.toISOString(),
              subscriptionId: null,
              processor: null,
            },
          },
        },
        record,
        basePath,
      );

      //TODO:Replace with kinesis
      // fetch(`${Config.parseServer.apiUrl}/class-pass-events`, {
      //   method: 'POST',
      //   body: JSON.stringify({
      //     event: 'pass',
      //     uid: record.id,
      //     active: true,
      //     date: now,
      //     nextDate: nextDateObj,
      //   }),
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // });

      setShowForm(false);
      showNotification('Pass illimité activé');
    } else {
      try {
        await fetch(`${Config.frontUrl}/api/admin/pass/activate`, {
          method: 'POST',
          body: JSON.stringify({
            uid: record.id,
            type: 'bundle',
            isFree: passType === 'free',
            nextDatestr: nextDate,
            doNotBillTillDate,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        crudGetOne(resource, record.id, basePath);

        //TODO:Replace with kinesis
        // fetch(`${Config.parseServer.apiUrl}/class-pass-events`, {
        //   method: 'POST',
        //   body: JSON.stringify({
        //     event: 'pass',
        //     uid: record.id,
        //     active: true,
        //     date: new Date(),
        //   }),
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        // });

        setShowForm(false);
        showNotification('Pass illimité activé');
      } catch (error) {
        console.log('error', error);
        showNotification("Impossible d'activer le pass illimité", 'error');
      }
    }

    setIsLoading(false);
  };

  if (!record) {
    return null;
  }

  const isBundleActive = dayjs(record.purchases?.bundle?.currentPeriodEnd).isAfter(dayjs());

  return (
    <Paper className={classes.root} style={{ borderRadius: 0 }}>
      <Typography variant="subtitle1" gutterBottom>
        Pass Bundle (Pack MS/MS+)
      </Typography>

      <List component="nav">
        <ListItem>
          <ListItemText primary={`Pack bundle actif: ${isBundleActive ? 'Oui' : 'Non'}`} />
        </ListItem>

        {isBundleActive && (
          <>
            <ListItem>
              <ListItemText primary={`Pass MS+ renouvelable: ${record.purchases?.bundle?.isRenewable ? 'Oui' : 'Non'}`} />
            </ListItem>

            <ListItem>
              <ListItemText primary={`Cadeau: ${record.purchases?.bundle?.isGift ? 'Oui' : 'Non'}`} />
            </ListItem>

            <ListItem>
              <ListItemText primary={`Stripe Subscription: ${record.purchases?.bundle?.subscriptionId}`} />
            </ListItem>

            {record.purchases?.bundle?.isGift && (
              <ListItem>
                <ListItemText primary={`Offert par: <${<ReferenceField record={record} source="purchases.bundle.giftFrom" />}`} />
              </ListItem>
            )}

            {!!record.purchases?.bundle?.currentPeriodEnd && (
              <>
                <ListItem>
                <ListItemText primary={`Depuis le: ${record.purchases?.bundle?.date?.seconds ? dayjs(record.purchases?.bundle?.date?.seconds * 1000).toISOString() : dayjs(record.purchases?.bundle?.date).toISOString()}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`Prochaine échéance: ${dayjs(record.purchases?.bundle?.currentPeriodEnd).toISOString()}`} />
                </ListItem>
              </>
            )}

            <ListItem>
              <ListItemText primary={`Plateforme d'achat: ${(record.purchases?.bundle?.platform || 'web').toUpperCase() }`} />
            </ListItem>
          </>
        )}
      </List>

      {!showForm &&
        (isBundleActive ? (
          <Button variant="outlined" disabled={isLoading} style={{ border: ' 1px solid #ff4567', fontWeight: '400', color: '#ff4567' }} onClick={cancelPass}>
            {isLoading ? <CircularProgress /> : 'Annuler le Pass'}
          </Button>
        ) : (
          <Button variant="outlined" color="primary" disabled={isLoading} style={{ border: ' 1px solid #5383ff', fontWeight: '400', color: '#5383ff' }} onClick={() => setShowForm(true)}>
            {isLoading ? <CircularProgress /> : 'Activer le Pass'}
          </Button>
        ))}

      <Dialog open={showForm} onClose={() => setShowForm(false)} aria-labelledby="simple-dialog-title">
        <DialogTitle id="simple-dialog-title">Activer le Pass</DialogTitle>

        <DialogContent>
          <div style={{ paddingBottom: 40 }}>
            <RadioGroup aria-label="passType" name="radio-buttons-group" row color="primary" value={passType} onChange={(e) => setPassType(e.target.value)}>
              <FormControlLabel value="payable" color="primary" control={<Radio />} label="Pass client" />
              <FormControlLabel value="free" color="primary" control={<Radio />} label="Pass privilège (gratuit)" />
            </RadioGroup>

            <div style={{ marginTop: 20, marginBottom: 20 }}>
              {passType === 'payable' && (
                <>
                  <Typography>Sélectionner la date à laquelle les prochains paiements doivent commencer</Typography>
                  <div>
                    <Typography variant="caption">- Si le client a déjà un pass qui a été annulé, sélectionner la date de fin de validité de son pass actuel</Typography>
                  </div>
                  <div>
                    <Typography variant="caption">- Si le premier paiement doit s'effectuer maintenant (ex: client cours qui veut acheter un pass), mettre la date d'aujourd'hui</Typography>
                  </div>
                </>
              )}

              {passType === 'free' && (
                <>
                  <Typography>Sélectionner la date limite de validité du pass</Typography>
                  <Typography variant="caption">- Ce type de pass n'engendrera jamais de paiement. Il est à réserver pour des demandes spécifiques, comme pour un mentor ou un collaborateur</Typography>
                </>
              )}
            </div>

            <TextField
              id="date"
              label="Date d'échéance"
              type="date"
              value={nextDate}
              onChange={(e) => setNextDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <FormControlLabel
              label="Le client a déjà été débité (en cochant cette case, le prochain débit se fera à la date d'échéance mentionnée, sans aucune promo)"
              control={<Checkbox color="primary" checked={doNotBillTillDate} onChange={(event) => setDoNotBillTillDate(event.target.checked)} />}
              style={{ marginTop: '2rem', marginBottom: '1rem' }}
            />

            <div style={{ marginTop: 20 }}>
              <Button variant="contained" color="primary" disabled={isLoading || !nextDate} className={classes.button} onClick={activatePass}>
                {isLoading ? <CircularProgress /> : 'Valider'}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

UserPassBundle.propTypes = {
  basePath: PropTypes.any.isRequired,
  crudUpdate: PropTypes.any.isRequired,
  record: PropTypes.object,
  resource: PropTypes.any.isRequired,
  showNotification: PropTypes.func.isRequired,
};

UserPassBundle.defaultProps = {
  record: null,
};

export default connect(null, { showNotification: showNotificationAction, crudUpdate: crudUpdateAction, crudGetOne: crudGetOneAction })(UserPassBundle);
