import React from 'react';
import { Create, NumberInput, SimpleForm, TextInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  sectionTitle: {
    fontSize: 16,
    marginTop: 30,
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
}));

const CategoryCreate = (props) => {
  const classes = useStyles();
  const { lang } = useSelector((state) => state.app);

  return (
    <Create title="Ajouter une catégorie" {...props}>
      <SimpleForm key={lang} variant="outlined" sanitizeEmptyValues={false}>
        <TextInput source="id" label="Id" helperText="l'id = le nom tout en minuscule, sans accent et sans espaces" className={classes.textInput} validate={[required()]} />
        <TextInput source={`name.${lang}`} label={`Nom (${lang})`} className={classes.textInput} initialValue="" validate={[required()]} />
        <NumberInput source="order" label="Rang" initialValue={1} />
      </SimpleForm>
    </Create>
  );
};

export default CategoryCreate;
