import React from 'react';

import { CardContentInner, Title } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import StopIcon from '@material-ui/icons/Stop';
import { IconButton } from '@material-ui/core';

import Config from '../config';
import { formatTime } from '../utils/datetime';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  flex: {
    flexGrow: 1,
  },
  table: {
    minWidth: 700,
  },
  button: {
    fontSize: '14px',
    lineHeight: '24px',
    padding: '4px 32px',
    border: '1px solid rgb(66, 104, 246)',
    color: 'rgb(66, 104, 246)',
    fontWeight: '400',
    marginRight: '16px',

    '&:hover,': {
      backgroundColor: '#535b8e',
      color: 'white',
    },
  },
}));

const target = Config.envTargets[Config.environment];

const DeploymentsPage = () => {

  const [pipelines, setPipelines] = React.useState([]);

  const classes = useStyles();

  const getData = async () => {
    const res = await fetch(`${Config.vercel.baseURl}/v6/now/deployments?teamId=${Config.vercel.teamId}&projectId=${Config.vercel.projectId}&target=${target}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Config.vercel.token}`,
      },
    });

    const deploymentsRes = await res.json();

    console.log('deployments', deploymentsRes);

    setPipelines(deploymentsRes?.deployments || []);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const createPipeline = async () => {
    fetch(Config.vercel.hooks[Config.environment]);

    setPipelines([
      {
        createdAt: new Date().getTime(),
        ready: new Date().getTime() + 1000,
        state: 'QUEUED',
        uid: 'dpl_new',
      },
      ...pipelines,
    ]);

    // getData();
  };

  const cancelPipeline = async (deploymentId) => {
    await fetch(`${Config.vercel.baseURl}/v12/now/deployments/${deploymentId}/cancel?teamId=${Config.vercel.teamId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${Config.vercel.token}`,
      },
      mode: 'cors',
    });

    getData();
  };

  const isRunning = pipelines.findIndex((item) => item.state === 'BUILDING' || item.state === 'QUEUED') > -1;

  return (
    <CardContentInner>
      <Title title="Déploiements" />

      <Typography variant="h4" gutterBottom style={{ marginTop: '40px', fontWeight: '100' }}>Déploiements</Typography>

        <Toolbar>
          <Typography variant="subtitle1" color="inherit" className={classes.flex} />

          <Button
            onClick={createPipeline}
            disabled={isRunning}
            className={classes.button}
          >
            Lancer un déploiement
          </Button>
        </Toolbar>

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>Environnement</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>Lancement</TableCell>
              <TableCell>Durée</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pipelines.map((pipeline) => (
              <TableRow key={pipeline.uid}>
                <TableCell><a href={`https://${pipeline.url}`} target="_blank" rel="noopener noreferrer">{pipeline.uid}</a></TableCell>
                <TableCell>{pipeline.target === 'production' ? 'Production' : 'Preview'}</TableCell>
                <TableCell>
                  {pipeline.state === 'BUILDING' || pipeline.state === 'QUEUED'
                    ? <CircularProgress className={classes.progress} size={20} />
                    : pipeline.state}
                </TableCell>
                <TableCell>{new Date(pipeline.createdAt).toLocaleString()}</TableCell>
                <TableCell>{!pipeline.ready ? '0s' : formatTime(new Date(pipeline.ready) - new Date(pipeline.createdAt))}</TableCell>
                <TableCell>
                  <IconButton color="primary" aria-label="Delete" onClick={() => cancelPipeline(pipeline.uid)} disabled={pipeline.state !== 'BUILDING' && pipeline.state !== 'QUEUED'}>
                    <StopIcon />
                  </IconButton>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </CardContentInner>
  );
};

export default DeploymentsPage;
