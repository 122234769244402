import PropTypes from 'prop-types';
import React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
  useListContext,
} from 'react-admin';
import MyList from '../../layout/MyList';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EmailFilterInput = (props) => {
  const { source } = props;
  const { filterValues, setFilters } = useListContext();

  const onChange = (event) => {
    const values = { ...filterValues };
    values[source] = event.target.value.trim();
    setFilters(values);
  };

  return (
    <TextInput {...props} onChange={onChange} style={{ marginTop: '16px', marginBottom: '8px' }} />
  );
};

const PageFilter = (props) => (
  <Filter {...props}>
    <EmailFilterInput source="senderEmail" label="Email expéditeur" alwaysOn />
    <EmailFilterInput source="destinationEmail" label="Email destinataire" alwaysOn />
  </Filter>
);

const GiftAwaitingList = (props) => (
  <MyList
    {...props}
    title="Bons cadeau programmés"
    filters={<PageFilter />}
    sort={{ field: 'sendDate', order: 'DESC' }}
    perPage={25}
    bulkActionButtons={false}
  >
    <Datagrid>
      <DateField source="sendDate" label="Date d'envoi" />
      <ReferenceField label="Expéditeur" source="user" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="destinationEmail" label="Email dest." />
      <TextField source="destinationFirstName" label="Nom dest." />
      <TextField source="message" label="Message" />
      <EditButton label="" />
    </Datagrid>
  </MyList>
);

EmailFilterInput.propTypes = {
  source: PropTypes.string.isRequired,
};

export default GiftAwaitingList;
