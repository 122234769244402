import PropTypes from 'prop-types';
import React from 'react';
import { ArrayInput, SimpleFormIterator, Datagrid, Edit, EditButton, NumberInput, ReferenceManyField, SimpleForm, TextField, TextInput, required , ImageInput, ImageField} from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import MyEdit from '../../layout/MyEdit';
import PageEditActions from '../pages/PageEditActions';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  sectionTitle: {
    fontSize: 16,
    marginTop: 30,
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
}));

const CompanyTitle = ({ record }) => {
    const { lang } = useSelector((state) => state.app);
    return <span>Entreprise {record ? `"${record.name?.[lang]}"` : ''}</span>;
  };

  CompanyTitle.propTypes = {
    record: PropTypes.any,
  };
  
  CompanyTitle.defaultProps = {
    record: null,
  };
  

const ClassRefField = ({ record }) => (record ? <Link to={`products/${record.id}`}>{record.reference}</Link> : null);

ClassRefField.propTypes = {
    record: PropTypes.any,
  };
  
  ClassRefField.defaultProps = {
    record: null,
  };

const CompanyEdit = (props) => {
  const { basePath, record } = props;

  const classes = useStyles();
  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions basePath={basePath} record={record} />;

  return (
    <MyEdit title={<CompanyTitle />} actions={Actions} {...props}>
      <SimpleForm key={lang} variant="outlined">
        <TextInput source="id" label="Id" helperText="l'id = le nom tout en minuscule, sans accent et sans espaces" className={classes.textInput} validate={[required()]} />
        <TextInput source="name" label="Nom" className={classes.textInput} initialValue="" validate={[required()]} />
        <NumberInput source="order" label="Rang" initialValue={1} />
        <ImageInput source="heroImg" label="Image Hero" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="description" label="Hero Description" className={classes.textInput} initialValue="" validate={[required()]} />


        <ArrayInput source="classes" label="Classes" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="slug" label="Class Slug" className={classes.textInput} initialValue="" />
            </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </MyEdit>
  );
};

export default CompanyEdit;
