import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { Drawer, makeStyles } from '@material-ui/core';
import { setSidebarVisibility, useLocale } from 'ra-core';
import { useDispatch, useSelector } from 'react-redux';

export const DRAWER_WIDTH = 240;
export const CLOSED_DRAWER_WIDTH = 44;

const useStyles = makeStyles((theme) => ({
  root: {},
  docked: {},
  paper: {},
  paperAnchorLeft: {},
  paperAnchorRight: {},
  paperAnchorTop: {},
  paperAnchorBottom: {},
  paperAnchorDockedLeft: {},
  paperAnchorDockedTop: {},
  paperAnchorDockedRight: {},
  paperAnchorDockedBottom: {},
  modal: {},
  drawerPaper: {
    position: 'relative',
    height: 'auto',
    overflowX: 'hidden',
    width: (props) => {
      return props.open
        ? (lodashGet(theme, 'sidebar.width', DRAWER_WIDTH))
        : (lodashGet(
          theme,
          'sidebar.closedWidth',
          CLOSED_DRAWER_WIDTH,
        ));
    },
    backgroundColor: 'transparent',
    marginTop: '0.5em',
    borderRight: 'none',
    zIndex: 1,
  },
}));

const defaultOnMenuClick = () => null;

const Sidebar = (props) => {

  const {
    children,
    closedSize,
    size,
    ...rest
  } = props;

  const dispatch = useDispatch();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useLocale(); // force redraw on locale change

  const { drawerPaper, ...classes } = useStyles({ ...props, open });

  // const handleClose = () => dispatch(setSidebarVisibility(false));

  const toggleSidebar = () => dispatch(setSidebarVisibility(!open));

  return (
    <Drawer
      // variant="permanent"
      open={open}
      variant="persistent"
      anchor="left"
      PaperProps={{
        className: drawerPaper,
        style: {
          width: open ? DRAWER_WIDTH : closedSize,
          height: '100%',
          background: '#051e34',
          color: 'white',
          marginTop: 0,
        },
      }}
      classes={classes}
      onClose={toggleSidebar}
      {...rest}
    >
      {cloneElement(Children.only(children), { dense: true, onMenuClick: defaultOnMenuClick })}
    </Drawer>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  closedSize: PropTypes.number,
  size: PropTypes.number,
};

Sidebar.defaultProps = {
  size: DRAWER_WIDTH,
  closedSize: CLOSED_DRAWER_WIDTH,
};

export default Sidebar;
