import React from 'react';

import {
  Datagrid,
  List,
  Filter,
  TextField,
  TextInput,
  EditButton,
} from 'react-admin';

import DateTimeField from '../../fields/DateTimeField';
import MyList from '../../layout/MyList';

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Rechercher" source="id" alwaysOn />
  </Filter>
);

const MenuList = (props) => (
  <MyList {...props} title="Menus" filters={<PageFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Slug" />
      <DateTimeField source="lastupdate" label="Mise à jour" />
      {/* <EditButton label="" /> */}
    </Datagrid>
  </MyList>
);

export default MenuList;
