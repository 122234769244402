import PropTypes from 'prop-types';
import React from 'react';

const PageTitle = ({ record }) => {
  return <span>Page {record ? `"${record.id}"` : ''}</span>;
};

PageTitle.propTypes = {
  record: PropTypes.object,
};

PageTitle.defaultProps = {
  record: null,
};

export default PageTitle;
