import PropTypes from 'prop-types';
import React from 'react';

const CombinedNameField = ({ record, ...props }) => {
  if (!record) {
    return '-';
  }

  /* eslint-disable */

  const {
    allowEmpty, basePath, translateChoice, ...rest
  } = props;

  /* eslint-enable */

  return (
    <div {...rest}>{`${record.firstName || ''} ${record.lastName || ''}`}</div>
  );
};

CombinedNameField.propTypes = {
  record: PropTypes.object,
};

CombinedNameField.defaultProps = {
  record: null,
};

export default CombinedNameField;
