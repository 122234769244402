import PropTypes from 'prop-types';
import React from 'react';
import {
  Datagrid,
  Filter,
  List,
  ReferenceField,
  ShowButton,
  TextField,
  TextInput,
  useListContext,
} from 'react-admin';

import DateTimeField from '../../fields/DateTimeField';
import MyList from '../../layout/MyList';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EmailFilterInput = (props) => {
  const { source } = props;
  const { filterValues, setFilters } = useListContext();

  const onChange = (event) => {
    const values = { ...filterValues };
    values[source] = event.target.value.trim();
    setFilters(values);
  };

  return (
    <TextInput {...props} onChange={onChange} style={{ marginTop: '16px', marginBottom: '8px' }} />
  );
};

const PageFilter = (props) => (
  <Filter {...props}>
    <EmailFilterInput source="senderEmail" label="Email expéditeur" alwaysOn />
    <EmailFilterInput source="destinationEmail" label="Email destinataire" alwaysOn />
  </Filter>
);

const GiftList = (props) => (
  <MyList
    {...props}
    title="Bons cadeau envoyés"
    filters={<PageFilter />}
    sort={{ field: 'sendDate', order: 'DESC' }}
    perPage={25}
    bulkActionButtons={false}
  >
    <Datagrid>
      <DateTimeField source="sendDate" />
      <ReferenceField label="Expéditeur" source="user" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="destinationEmail" label="Email dest." />
      <TextField source="destinationFirstName" label="Nom dest." />
      <TextField source="message" label="Message" />
      <TextField source="sendNow" label="Immédiat" />
      <ShowButton label="" />
    </Datagrid>
  </MyList>
);

EmailFilterInput.propTypes = {
  source: PropTypes.string.isRequired,
};

export default GiftList;
