import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';

import {
  AutocompleteInput,
  Create,
  ImageField,
  ImageInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    height: '2rem',
  },
}));

const TestimonialsVideoCreate = (props) => {
  const classes = useStyles();

  const Actions = <PageEditActions />;

  return (
    <Create {...props} title="Ajouter un témoignage" actions={Actions} className="edit-wrapper">
      <SimpleForm
        toolbar={<EditToolbar withDelete />}
        variant="outlined"
      >
        <ReferenceInput label="Cours" source="classId" reference="content/mentorshow/classes" filterToQuery={(searchText) => (searchText ? { mentor: { name: searchText } } : null)} validate={[required()]}>
          <AutocompleteInput optionText="mentor.name" />
        </ReferenceInput>
        <TextInput source="videoId" label="ID Vidéo" className={classes.textInput} validate={[required()]} />
        <TextInput source="name" label="Nom" className={classes.textInput} validate={[required()]} />
        <ImageInput source="avatar" label="Photo d'avatar" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default TestimonialsVideoCreate;
