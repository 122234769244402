import PropTypes from 'prop-types';
import React from 'react';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const EditToolbar = ({ withDelete, ...props }) => {
  const classes = useStyles();

  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />

      {withDelete && (
        <DeleteButton undoable={false} />
      )}
    </Toolbar>
  );
};

EditToolbar.propTypes = {
  withDelete: PropTypes.bool,
};

EditToolbar.defaultProps = {
  withDelete: false,
};

export default EditToolbar;
