import React from 'react';

import {
  Datagrid,
  EditButton,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import MyList from '../../layout/MyList';

const TestimonialsVideoList = (props) => (
  <MyList {...props} title="Témoignages" bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Nom" />
      <TextField source="videoId" label="Vidéo" />
      <ReferenceField source="classId" reference="content/mentorshow/classes" label="Cours">
        <TextField source="mentor.name" />
      </ReferenceField>
      {/* <EditButton label="" /> */}
    </Datagrid>
  </MyList>
);

export default TestimonialsVideoList;
