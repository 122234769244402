import PropTypes from 'prop-types';
import React from 'react';

import { Typography, makeStyles } from '@material-ui/core';

import {
  AutocompleteInput,
  ArrayInput,
  BooleanInput,
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  ReferenceInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import EditToolbar from '../../../../common/EditToolbar';
import PageTitle from '../PageTitle';
import TabbedFormTabs from '../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../layout/VerticalTabbedForm';
import MyEdit from '../../../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
}));

const HomePageEditForm = ({ lang, ...props }) => {
  const classes = useStyles();

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm
        toolbar={<EditToolbar />}
        redirect={false}
        tabs={<TabbedFormTabs />}
        variant="outlined"
      >
        <FormTab label="Général">
          <TextInput disabled source="id" />

        </FormTab>

        <FormTab label="SEO">
          <div className={classes.separator} />
          <Typography variant="subtitle1" gutterBottom>SEO</Typography>

          <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} />

          <ImageInput source="seo.image" label="Photo d'aperçu SEO" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Hero">

          <TextInput source={`sections.hero.title.${lang}`} multiline label="Titre" className={classes.textInput} />

          <TextInput source={`sections.hero.description.${lang}`} multiline label="Description" className={classes.textInput} />

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>Vidéo</Typography>
          <TextInput source={`sections.hero.videoId.${lang}`} label={`ID Vidéo Bande annonce ${lang}`} className={classes.textInput} />

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>CTA</Typography>

          <TextInput source={`sections.hero.cta.description.${lang}`} label="CTA Description" className={classes.textInput} />
          <TextInput source={`sections.hero.cta.label.${lang}`} label="Button label" className={classes.textInput} />
          <TextInput source="sections.hero.cta.url" label="Button path" className={classes.textInput} />

          <ArrayInput source="sections.hero.images.col1" label="Images Hero Colonne 1" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="image" label="Photo d'aperçu" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="sections.hero.images.col2" label="Images Hero Colonne 2" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="image" label="Photo d'aperçu" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="sections.hero.images.col3" label="Images Hero Colonne 3" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="image" label="Photo d'aperçu" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="sections.hero.images.col4" label="Images Hero Colonne 4" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="image" label="Photo d'aperçu" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Onboarding">
          <Typography variant="subtitle1" gutterBottom>Home page Survey</Typography>
          <BooleanInput source="sections.onboardingSurvey.displayed" label="Publié" />
          <TextInput source={`sections.onboardingSurvey.question.${lang}`} label="Question" className={classes.textInput} />
          <ArrayInput source="sections.onboardingSurvey.answers" label="Réponses" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`answer.${lang}`} label="Réponse" className={classes.textInput} initialValue="" />
              <ArrayInput source="categories" label="Catégories" style={{ width: '100%' }}>
                <SimpleFormIterator>
                  <ReferenceInput label="Catégorie" source="category" reference="content/mentorshow/categories" filterToQuery={(searchText) => (searchText ? { [`name.${lang}`]: searchText } : null)}>
                    <AutocompleteInput optionText={`name.${lang}`} />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput>
              <ArrayInput source="relatedSurveys" label="Réponses" style={{ width: '100%' }}>
                <SimpleFormIterator>
                  <TextInput source={`question.${lang}`} label="Question" className={classes.textInput} />
                  <ArrayInput source="answers" label="Réponses" style={{ width: '100%' }}>
                    <SimpleFormIterator>
                      <TextInput source={`answer.${lang}`} label="Réponse" className={classes.textInput} initialValue="" />
                    </SimpleFormIterator>
                  </ArrayInput>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>

          <Typography variant="subtitle1" gutterBottom>Frequency Survey</Typography>
          <TextInput source={`sections.frequencySurvey.question.${lang}`} label="Question" className={classes.textInput} />
          <ArrayInput source="sections.frequencySurvey.answers" label="Réponses" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`answer.${lang}`} label="Réponse" className={classes.textInput} initialValue="" />
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} initialValue="" />
              <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} initialValue="" />
              <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>

          <Typography variant="subtitle1" gutterBottom>Recommandations Page</Typography>
          <TextInput source={`sections.recommandations.description.${lang}`} label="Description" className={classes.textInput} />
          <TextInput source={`sections.recommandations.cta.title.${lang}`} label="Titre CTA" className={classes.textInput} />
          <TextInput source={`sections.recommandations.cta.description.${lang}`} label="Sous-description CTA" className={classes.textInput} />


        </FormTab>

        <FormTab label="Gift Banner">
          <Typography variant="subtitle1" gutterBottom>Bannière Gift</Typography>

          <BooleanInput source="sections.giftBanner.displayed" label="Display" />

          <ImageInput source="sections.giftBanner.image" label="Background Image Desktop/Tablet" accept="image/*">
                <ImageField source="src" title="title" />
          </ImageInput>
          <ImageInput source="sections.giftBanner.mobileImage" label="Background Image Mobile" accept="image/*">
                <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source={`sections.giftBanner.description.${lang}`} label="Description" className={classes.textInput} />

        </FormTab>


        <FormTab label="PRICING TABLE">
          <div className={classes.separator} />
          <Typography variant="subtitle1" gutterBottom>PRICING TABLE</Typography>

          <TextInput source={`section.pricing.title.${lang}`} label="Titre" className={classes.textInput} />


          <TextInput source={`section.pricing.firstArgumentTitle.${lang}`} label="Titre du Premier argument" className={classes.textInput} />
          <TextInput source={`section.pricing.firstArgument.${lang}`} label="Premier argument" className={classes.textInput} />

          <TextInput source={`section.pricing.secondArgumentTitle.${lang}`} label="Titre du Deuxième argument" className={classes.textInput} />
          <TextInput source={`section.pricing.secondArgument.${lang}`} label="Deuxième argument" className={classes.textInput} />

          <TextInput source={`section.pricing.thirdArgumentTitle.${lang}`} label="Titre du Troisième argument" className={classes.textInput} />
          <TextInput source={`section.pricing.thirdArgument.${lang}`} label="Troisième argument" className={classes.textInput} />

          <TextInput source={`section.pricing.paymentDetail.${lang}`} label="Text détail paiement" className={classes.textInput} />

          <TextInput source={`section.pricing.proArgumentTitle.${lang}`} label="Titre de l'argument pro" className={classes.textInput} />
          <TextInput source={`section.pricing.proArgument.${lang}`} label="Argument Pro" className={classes.textInput} />
        </FormTab>

        <FormTab label="Pré-ventes">
          <TextInput source={`sections.preSales.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.preSales.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Cours">
          <TextInput source={`sections.classes.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.classes.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Bientôt dispo">
          <TextInput source={`sections.comingSoon.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.comingSoon.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Arguments">
          <TextInput source={`sections.arguments.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.arguments.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />

          <ArrayInput source="sections.arguments.items" label="Descriptions" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
              <TextInput source={`text.${lang}`} label="Texte" className={classes.textInput} />
              <ImageInput source="image" label="Photo d'aperçu" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Témoignages">
          <TextInput source={`sections.testimonials.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.testimonials.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="CTA Block">
          <TextInput source={`sections.ctaBlock.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.ctaBlock.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Valeurs">
          <ArrayInput source="sections.values.items" label="Items" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
              <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

      </VerticalTabbedForm>
    </MyEdit>
  );
};

HomePageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default HomePageEditForm;
