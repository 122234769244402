import PropTypes from 'prop-types';
import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  cardHeader: {
    padding: '.75rem 1.25rem',
  },
  title: {
    fontSize: '1.1rem',
  },
  table: {
  },
  thead: {
    height: '40px',
    background: 'rgba(239,239,247,.7)',
  },
  th: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: '#3b3e66',
    borderBottom: '2px solid #dcdef1',
    borderTop: '2px solid #dcdef1',
  },
}));

const PageViewsStatsTable = (props) => {
  const {
    rows, subtitle, title,
  } = props;

  const classes = useStyles();

  console.log('rows', rows);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={title}
        subheader={subtitle}
        className={classes.cardHeader}
        classes={{ title: classes.title }}
      />

      <CardContent style={{ padding: 0 }}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.thead}>
              <TableCell className={classes.th}>Jour</TableCell>
              <TableCell className={classes.th} align="right">Signup</TableCell>
              <TableCell className={classes.th} align="right">Checkout PI</TableCell>
              <TableCell className={classes.th} align="right">Checkout MS Plus</TableCell>
              <TableCell className={classes.th} align="right">Checkout Bundle</TableCell>
              <TableCell className={classes.th} align="right">Checkout Confirmation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.dateStr}</TableCell>
                <TableCell align="right">{row.views_signup || 0}</TableCell>
                <TableCell align="right">{row.views || 0}</TableCell>
                <TableCell align="right">{row.views_msplus || 0}</TableCell>
                <TableCell align="right">{row.views_bundle || 0}</TableCell>
                <TableCell align="right">{row.views_checkoutConfirm || 0}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

PageViewsStatsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

PageViewsStatsTable.defaultProps = {
  subtitle: null,
  title: null,
};

export default PageViewsStatsTable;
