import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';

import { AutocompleteInput, CheckboxGroupInput, Create, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required } from 'react-admin';

import Config from '../../../config';
import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    height: '2rem',
  },
}));

const MsplusOffersCreate = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  return (
    <Create {...props} title="Ajouter un témoignage" actions={Actions} className="edit-wrapper">
      <SimpleForm key={lang} toolbar={<EditToolbar withDelete />} variant="outlined">
        <TextInput source="id" label="ID" className={classes.textInput} validate={[required()]} />

        <TextInput source="couponId" label="ID Coupon" className={classes.textInput} helperText="ID du coupon de réduction Stripe à appliquer pour cette offre" />

        <TextInput source="description" label="Description (interne)" className={classes.textInput} validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};

export default MsplusOffersCreate;
