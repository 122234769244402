import React from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  List,
  NumberField,
  TextField,
  TextInput,
} from 'react-admin';
import { useSelector } from 'react-redux';
import MyList from '../../layout/MyList';

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Rechercher une catégorie" source="name" alwaysOn />
  </Filter>
);

const MSPlusCategoryList = (props) => {
  const { lang } = useSelector((state) => state.app);

  return (
    <MyList {...props} title="Catégories" filters={<PageFilter />} bulkActionButtons={false} >
      <Datagrid rowClick="edit">
        <TextField source="id" label="ID" />
        {/* <EditButton label="" /> */}
        <DeleteButton label="" redirect={false} undoable={false} />
      </Datagrid>
    </MyList>
  );
};

export default MSPlusCategoryList;
