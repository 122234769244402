import PropTypes from 'prop-types';
import React, { Children, cloneElement } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {
  LoadingIndicator, /* UserMenu, */ HideOnScroll, toggleSidebar, useTranslate,
} from 'react-admin';

//import LoadingIndicator from './LoadingIndicator';
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: 24,
    paddingRight: 24,
    height: '66px',
  },
  menuButton: {
    marginLeft: '0.5em',
    marginRight: '0.5em',
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '1rem',
  },
}), { name: 'RaAppBar' });

const AppBar = (props) => {

  const {
    children,
    className,
    color,
    logo,
    logout,
    open,
    title,
    userMenu,
    width,
    ...rest
  } = props;

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const translate = useTranslate();

  return (
      <MuiAppBar
        className={className}
        color={color}
        position="relative"
        elevation={0}
        style={{ borderBottom: '1px solid rgb(221, 225, 229)' }}
        {...rest}
      >
        <Toolbar
          disableGutters
          variant={isXSmall ? 'regular' : 'dense'}
          className={classes.toolbar}
        >
          {Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
              style={{ fontWeight: '300' }}
            />
          ) : (
            children
          )}
          <LoadingIndicator />
          {cloneElement(userMenu, { logout })}
        </Toolbar>
      </MuiAppBar>
  );
};

AppBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  logo: PropTypes.any,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
};

AppBar.defaultProps = {
  children: [],
  className: null,
  color: 'secondary',
  logo: null,
  logout: null,
  open: false,
  userMenu: <UserMenu />,
  width: null,
};

export default AppBar;
