import { Typography } from '@material-ui/core';
import { Edit } from 'ra-ui-materialui';
import React from 'react';

const TextComp = ({ children, ...props }) => {
    return (
        <>
            {props.record?.mentor ? (
                <div>
                    <Typography variant="h4" gutterBottom style={{ marginTop: '40px', marginBottom: '40px', fontWeight: '100' }}>
                        Modifier {props.record ? `"${props.record?.mentor?.name} ${props.record?.mentor?.teaches?.fr}"` : ''}
                    </Typography>
                </div>
            ) : (
                <Typography variant="h4" gutterBottom style={{ marginTop: '40px', marginBottom: '40px', fontWeight: '100' }}>
                    {/* props.title */}
                </Typography>
            )}

            <div style={{ display: 'flex', borderWidth: '5px', borderColor: 'black', border: 'groove', padding: '20px', marginBottom: '20px' }}>
                <div style={{ width: 20, height: 20, borderRadius: 20, backgroundColor: props.record.adminWarningColor, marginRight: '20px' }} />
                <div style={{ marginTop: '-17px' }}>
                    <div dangerouslySetInnerHTML={{ __html: props.record ? props.record.adminWarningText : 'NA' }} />
                </div>
            </div>

            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { ...props });
                }
                return child;
            })}
        </>
    );
};


const MyClassEdit = (props) => {

    return (
        <>
            <Edit {...props}>
                <TextComp>
                    {props.children}
                </TextComp>
            </Edit>
        </>
    );
};

export default MyClassEdit;
