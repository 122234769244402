import PropTypes from 'prop-types';
import React from 'react';
import { Card, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  card: {
    float: 'left',
    margin: '-20px 20px 0 15px',
    zIndex: 100,
    borderRadius: 3,
  },
  icon: {
    float: 'right',
    width: 34,
    height: 34,
    padding: 10,
    color: '#fff',
  },
}));

const StatOverviewCardIcon = ({ Icon, bgColor }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} style={{ backgroundColor: bgColor }}>
      <Icon className={classes.icon} />
    </Card>
  );
};

StatOverviewCardIcon.propTypes = {
  Icon: PropTypes.any.isRequired,
  bgColor: PropTypes.string,
};

StatOverviewCardIcon.defaultProps = {
  bgColor: '#31708f',
};

export default StatOverviewCardIcon;
