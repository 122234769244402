import { parse as convertFromCSV } from 'papaparse';
import lensPath from 'ramda/src/lensPath';
import over from 'ramda/src/over';

const setObjectValue = (object, path, value) => {
  const lensPathFunction = lensPath(path.split('.'));
  return over(lensPathFunction, () => value, object || {});
};

export async function getCsvData(file, inputConfig) {
  let config = {};
  const isObject = !!inputConfig && typeof inputConfig === 'object';
  if (isObject) {
    config = inputConfig;
  }
  return new Promise((resolve, reject) => convertFromCSV(file, {
    // Defaults
    delimiter: '',
    newline: '',
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true,
    // Configs (overwrites)
    ...config,
    // Callbacks
    complete: (result) => resolve(result.data),
    error: (error) => reject(error),
  }));
}

export function processCsvData(data) {
  const topRowKeys = data[0];

  const dataRows = data.slice(1).map((row) => {
    let value = {};

    topRowKeys.forEach((key, index) => {
      value = setObjectValue(value, key, row[index]);
    });

    return value;
  });

  return dataRows;
}

export async function processCsvFile(file, parseConfig) {
  if (!file) {
    return null;
  }

  const csvData = await getCsvData(file, parseConfig);

  console.log('scvData', csvData);

  return processCsvData(csvData);
}
