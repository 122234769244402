import PropTypes from 'prop-types';
import React from 'react';
import { Datagrid, Edit, EditButton, NumberInput, ReferenceManyField, SimpleForm, TextField, TextInput, required , ImageInput, ImageField} from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import MyEdit from '../../layout/MyEdit';
import PageEditActions from '../pages/PageEditActions';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  sectionTitle: {
    fontSize: 16,
    marginTop: 30,
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
}));

const CategoryTitle = ({ record }) => {
  const { lang } = useSelector((state) => state.app);
  return <span>Catégorie {record ? `"${record.name?.[lang]}"` : ''}</span>;
};

CategoryTitle.propTypes = {
  record: PropTypes.any,
};

CategoryTitle.defaultProps = {
  record: null,
};

const ClassRefField = ({ record }) => (record ? <Link to={`products/${record.id}`}>{record.reference}</Link> : null);

ClassRefField.propTypes = {
  record: PropTypes.any,
};

ClassRefField.defaultProps = {
  record: null,
};

const MSPlusCategoryEdit = (props) => {
  const { basePath, record } = props;

  const classes = useStyles();
  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions basePath={basePath} record={record} />;

  return (
    <MyEdit title={<CategoryTitle />} actions={Actions} {...props}>
      <SimpleForm key={lang} variant="outlined">
        <TextInput source="id" label="Id" helperText="l'id = le nom tout en minuscule, sans accent et sans espaces" className={classes.textInput} validate={[required()]} />
        <TextInput source={`name.${lang}`} label={`Nom (${lang})`} className={classes.textInput} initialValue="" validate={[required()]} />
      </SimpleForm>
    </MyEdit>
  );
};

export default MSPlusCategoryEdit;
