import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '@material-ui/core';
import _ from 'lodash';

const ColorStickField = ({ record, source }) => {

    if (!record) {
        return null;
    }

    const data = _.get(record, source);

    if (!data) {
        return (
            <div style={{ width: 20, height: 20, borderRadius: 20, backgroundColor: 'grey', marginRight: '20px' }} />
        );
    }


    return (
        <div style={{ width: 20, height: 20, borderRadius: 20, backgroundColor: data, marginRight: '20px' }} />
    );
};

export default ColorStickField;

