import PropTypes from 'prop-types';
import React from 'react';
import { AppBar, Box, CircularProgress, Divider, IconButton, List, ListItem, ListItemText, Paper, Toolbar, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { Refresh as RefreshIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { useFirebase } from '../../../../firebase';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  container: {
    padding: '1em',
    marginBottom: '1em',
  },
  rightAlignedCell: { textAlign: 'right' },
  boldCell: { fontWeight: 'bold' },
}));

const UserCurrentWatchingList = ({ record }) => {
  const classes = useStyles();
  const firebase = useFirebase();

  const [currentWatchings, setCurrentWatchings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    getCurrentWatchings();
  }, [record, firebase]);

  async function getCurrentWatchings() {
    if (!record || !firebase) {
      return;
    }

    setIsLoading(true);

    const watchingsSnap = await firebase.userCurrentWatchings(record.id).once('value');
    const watchings = watchingsSnap.val();

    if (watchings) {
      setCurrentWatchings(Object.values(watchings));
    } else {
      setCurrentWatchings([]);
    }

    setIsLoading(false);
  }

  const deleteAllWatchings = async () => {
    await firebase.userCurrentWatchings(record.id).remove();
    getCurrentWatchings();
  };

  return (
    <Paper className={classes.container} style={{ borderRadius: 0 }}>
      <AppBar position="relative" variant="outlined" style={{ backgroundColor: 'transparent', color: 'initial', border: 0 }}>
        <Toolbar style={{ padding: 0, minHeight: 40 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1" gutterBottom>
              Visionnages actuels
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Tooltip title="Recharger la liste">
              <IconButton size="small" aria-label="refresh" color="inherit" disabled={isLoading} onClick={getCurrentWatchings}>
                {isLoading ? <CircularProgress size={20} /> : <RefreshIcon />}
              </IconButton>
            </Tooltip>

            <Tooltip title="Réinitialiser toutes les connexions">
              <IconButton size="small" aria-label="refresh" color="primary" disabled={isLoading} onClick={deleteAllWatchings}>
                <DeleteIcon style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>

      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {currentWatchings.length === 0 && (
          <ListItem alignItems="flex-start">
            <ListItemText primary="Aucune connexion actuellement" />
          </ListItem>
        )}

        {currentWatchings.map((watching, index) => (
          <>
            <ListItem key={index} alignItems="flex-start">
              <ListItemText
                primary={`${watching.isTablet || watching.isMobile ? `${watching.mobileVendor} ${watching.mobileModel}` : 'Ordinateur'} ${watching.osName || watching.os || ''} ${watching.osVersion || ''}`}
                secondary={
                  <>
                    <Typography sx={{ display: 'block' }}>Navigateur : {watching.browserName}</Typography>
                    <Typography sx={{ display: 'block' }}>IP : {watching.ip}</Typography>
                    <Typography sx={{ display: 'block' }}>
                      Depuis le : {moment(watching.date).format(`DD/MM/YYYY à H:m`)} ({moment(watching.date).fromNow()})
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
          </>
        ))}
      </List>
    </Paper>
  );
};

UserCurrentWatchingList.propTypes = {
  record: PropTypes.object,
};

UserCurrentWatchingList.defaultProps = {
  record: null,
};

export default UserCurrentWatchingList;
