import PropTypes from 'prop-types';
import React from 'react';
import {
  Card, Icon, Typography, makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import CardIcon from './StatOverviewCardIcon';

const useStyles = makeStyles(() => ({
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
    '&:last-child': {
      marginRight: 0,
    },
  },
  card: {
    overflow: 'inherit',
    textAlign: 'center',
    padding: '32px 8px',
    minHeight: 52,
    boxShadow: 'rgb(137 138 154 / 40%) 0px 1px 6px 0px',
    borderRadius: 0,
  },
  title: {
    fontWeight: '400',
    marginTop: 10,
    marginBottom: 16,
    fontSize: 16,
  },
  icon: {
    color: '#879FFA',
    fontSize: 40
  },
  value: {
    fontWeight: 400,
    fontSize: 22,
  }
}));

const ConditionalLink = ({ children, link }) => (
  link ? (
    <Link to={link} style={{ textDecoration: 'none' }}>{children}</Link>
  ) : (
    children
  )
);

ConditionalLink.propTypes = {
  children: PropTypes.any.isRequired,
  link: PropTypes.string,
};

ConditionalLink.defaultProps = {
  link: null,
};

const StatOverviewCard = ({
  label, icon, value, link,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <ConditionalLink link={link}>
        
        <Card className={classes.card}>
          {React.createElement(icon, { className: classes.icon })}
          <Typography className={classes.title}>
            {label}
          </Typography>
          <Typography variant="h5" component="h2" className={classes.value}>
            {value}
          </Typography>
        </Card>
      </ConditionalLink>
    </div>
  );
};

StatOverviewCard.propTypes = {
  icon: PropTypes.any.isRequired,
  label: PropTypes.string,
  link: PropTypes.string,
  value: PropTypes.any.isRequired,
};

StatOverviewCard.defaultProps = {
  label: null,
  link: null,
};

export default StatOverviewCard;
