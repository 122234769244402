const fr = {
  menu: {
    sections: {
      content: 'Contenu',
      mentorshow: 'MentorShow',
      msplus: 'MS Plus',
      users: 'Utilisateurs',
      pricing: 'Tarification',
      config: 'Configuration',
    },
  },
};

export default fr;
