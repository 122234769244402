import React from 'react';

import {
  Datagrid,
  List,
  Filter,
  NumberField,
  TextField,
  TextInput,
  EditButton,
  DeleteButton,
} from 'react-admin';

import DateTimeField from '../../fields/DateTimeField';
import MyList from '../../layout/MyList';

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Rechercher un mentor" source="name" alwaysOn />
  </Filter>
);

const ClassComingSoonList = (props) => (
  <MyList {...props} title="Bientôt disponibles" filters={<PageFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <NumberField source="order" label="Rang" />
      <TextField source="name" label="Mentor" />
      <TextField source="domain.fr" label="Domaine" />
      <TextField source="teaches.fr" label="Enseignement" />
      <DateTimeField source="lastupdate" label="Mise à jour" />
      {/* <EditButton label="" /> */}
      <DeleteButton label="" redirect={false} undoable={false} />
    </Datagrid>
  </MyList>
);

export default ClassComingSoonList;
