import React from 'react';

import {
  Datagrid,
  List,
  Filter,
  TextField,
  TextInput,
  EditButton,
} from 'react-admin';

import DateTimeField from '../../fields/DateTimeField';
import MyList from '../../layout/MyList';

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Rechercher" source="title" alwaysOn />
  </Filter>
);

const PageSlug = ({ record }) => {
  return <span>/{record.id}</span>
}

const StaticPageList = (props) => (
  <MyList {...props} title="Pages statiques" filters={<PageFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <PageSlug source="id" label="Url" />
      <TextField source="title.fr" label="Titre FR" />
      <TextField source="title.en" label="Titre EN" />
      <DateTimeField source="lastupdate" label="Mise à jour" />
      {/* <EditButton label="" /> */}
    </Datagrid>
  </MyList>
);

export default StaticPageList;
