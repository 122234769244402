import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { Create, SimpleForm, TextInput, required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import Config from '../../../config';
import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  sectionTitle: {
    fontSize: 16,
    marginTop: 30,
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
}));

const defaultValues = {
  createdAt: new Date(),
  lastupdate: new Date(),
};

const StaticPageCreate = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  return (
    <Create {...props} title="Créer une page statique" actions={Actions} className="edit-wrapper">
      <SimpleForm key={lang} toolbar={<EditToolbar withDelete />} initialValues={defaultValues} variant="outlined">
        <TextInput source="id" validate={[required()]} />
        <TextInput source={`title.${lang}`} label={`Titre (${lang})`} className={classes.textInput} initialValue="" />
        <RichTextInput source={`body.${lang}`} label={`Contenu (${lang})`} className={classes.textInput} toolbar={Config.richTextToolbar} validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};

export default StaticPageCreate;
