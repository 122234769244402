import PropTypes from 'prop-types';
import React from 'react';
import RichTextInput from 'ra-input-rich-text';

import { Typography, makeStyles } from '@material-ui/core';

import { ArrayInput, BooleanInput, Edit, FormTab, ImageField, ImageInput, SelectInput, SimpleFormIterator, TextInput } from 'react-admin';

import Config from '../../../../../../config';
import EditToolbar from '../../../../../common/EditToolbar';
import MyEdit from '../../../../../layout/MyEdit';
import PageTitle from '../../PageTitle';
import TabbedFormTabs from '../../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../../layout/VerticalTabbedForm';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
  root: {
    flexGrow: 1,
    width: '100%',
  },
  column: {
    padding: '0 50px',
    border: '1px solid #dedede',
  },
  card: {
    width: '100%',
    margin: '20px auto',
  },
}));

const MsplusPageEditForm = ({ lang, ...props }) => {
  const classes = useStyles();

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm toolbar={<EditToolbar />} redirect={false} tabs={<TabbedFormTabs />} variant="outlined">
        <FormTab label="General">
          <TextInput disabled source="id" />
        </FormTab>

        <FormTab label="SEO">
          <div className={classes.separator} />
          <Typography variant="subtitle1" gutterBottom>
            SEO
          </Typography>

          <TextInput source={`seo.title.${lang}`} label="Title" className={classes.textInput} />
          <TextInput source={`seo.description.${lang}`} label="Description" className={classes.textInput} />

          <ImageInput source="seo.image" label="SEO Preview Image" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Hero">
          <ImageInput source="sections.hero.image" label="Hero image" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput source="sections.hero.titleImage" label="Title image" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>

          <TextInput source={`sections.hero.title.${lang}`} multiline label="Title" className={classes.textInput} />
          <TextInput source={`sections.hero.description.${lang}`} multiline label="Description" className={classes.textInput} />
        </FormTab>

        <FormTab label="Trailer">
          <TextInput source={`sections.trailer.title.${lang}`} multiline label="Title" className={classes.textInput} />
          <TextInput source={`sections.trailer.description.${lang}`} multiline label="Description" className={classes.textInput} />
          <TextInput source={`sections.trailer.videoId.${lang}`} label="Video ID" className={classes.textInput} />
        </FormTab>

        <FormTab label="Descriptions">
          <ArrayInput source={`sections.descriptions.${lang}.items`} label="Descriptions" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="overline" label="Overline" className={classes.textInput} />
              <TextInput source="title" label="Title" className={classes.textInput} />
              <RichTextInput source="text" label="Description" toolbar={Config.richTextToolbar} />
              <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Benefits">
          <TextInput source={`sections.benefits.${lang}.title`} multiline label="Title" className={classes.textInput} />
          <ArrayInput source={`sections.benefits.${lang}.items`} label="Items" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="image" label="Icon" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput source="title" label="Title" className={classes.textInput} />
              <TextInput source="description" label="Description" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Testimonials">
          <TextInput source={`sections.testimonials.${lang}.overtitle`} multiline label="Overtitle" className={classes.textInput} />
          <TextInput source={`sections.testimonials.${lang}.title`} multiline label="Title" className={classes.textInput} />
        </FormTab>

        <FormTab label="FAQ">
          <TextInput source={`sections.faq.${lang}.title`} label="Titre" className={classes.textInput} />
          <ArrayInput source={`sections.faq.${lang}.items`} label="Liste des questions" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="question" label="Question" className={classes.textInput} />
              <TextInput source="answer" label="Réponse" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Recall CTA">
          <TextInput source={`sections.recallCta.${lang}.title`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.recallCta.${lang}.description`} label="Titre" className={classes.textInput} />
        </FormTab>
      </VerticalTabbedForm>
    </MyEdit>
  );
};

MsplusPageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default MsplusPageEditForm;
