import PropTypes from 'prop-types';
import React from 'react';

import {
  withStyles,
} from '@material-ui/core';

import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  DateInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';

const styles = {
  textInput: {
    width: '100%',
  },
  hiddenInput: {
    display: 'none',
  },
};

const defaultValues = {
  handeled: false,
  thrivecart: {
    customer: {
      address: { country: 'FR', zip: '' },
    },
    order: [{
      id: '',
      t: 'product',
    }],
    order_id: '',
  },
  type: 'gift-pass',
};

const TestimonialTitle = ({ record }) => {
  return <span>Cadeau {record ? `"${record.destinationEmail}"` : ''}</span>;
};

const GiftAwaitingEdit = (props) => {
  const { classes } = props;

  const Actions = <PageEditActions />;

  return (
    <Edit {...props} title="Programmer un cadeau" actions={Actions} className="edit-wrapper">
      <SimpleForm
        redirect={false}
        toolbar={<EditToolbar withDelete />}
        defaultValue={defaultValues}
      >

        <RadioButtonGroupInput
          source="type"
          choices={[
            { id: 'gift-pass', name: 'Pass' },
            { id: 'gift-class', name: 'Cours' },
          ]}
          label="Type de cadeau"
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => formData.type === 'gift-class' && (
            <ReferenceInput {...rest} label="Cours offert" source="classId" reference="content/mentorshow/classes" filterToQuery={(searchText) => (searchText ? { id: searchText } : {})}>
              <AutocompleteInput optionText="id" />
            </ReferenceInput>
          )}
        </FormDataConsumer>

        <ReferenceField label="Expéditeur" source="user" reference="users" filterToQuery={(searchText) => ({ email: searchText })}>
          <TextField source="email" />
        </ReferenceField>

        <TextInput source="senderFirstName" label="Prénom expéditeur" className={classes.textInput} />

        <TextInput source="destinationEmail" label="Email destinataire" className={classes.textInput} />
        <TextInput source="destinationFirstName" label="Prénom destinataire" className={classes.textInput} />
        <TextInput multiline source="message" label="Message" className={classes.textInput} />

        <DateInput source="sendDate" label="Date d'envoi envisagée" />

        <BooleanInput source="handeled" defaultValue={false} className={classes.hiddenInput} />

        <TextInput source="thrivecart.order[0].id" label="ID produit ThriveCart" />
      </SimpleForm>
    </Edit>
  );
};

GiftAwaitingEdit.propTypes = {
  classes: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
};

TestimonialTitle.propTypes = {
  record: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  GiftAwaitingEdit,
);
