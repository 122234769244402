import { ListBase } from "ra-core";
import { BulkActionsToolbar, ListToolbar, Pagination, Title } from "ra-ui-materialui";
import { Card, Typography } from '@material-ui/core';
import { cloneElement } from "react";
import ListActions from '../common/ListActions';

const MyList = ({children, actions, bulkActionButtons, filters, title, ...props}) => {

  console.log('props.hasCreate', props.hasCreate);
  
  return (
    <ListBase {...props}>
      <Title title={title}/>

      <Typography variant="h4" gutterBottom style={{ marginTop: '40px', fontWeight: '100' }}>{title}</Typography>

      <ListToolbar
        filters={filters}
        actions={actions ? cloneElement(actions, { ...props }) : <ListActions {...props} />}
        style={{ marginBottom: '10px' }}
      />

      <Card style={{ padding: '30px' }}>
        <BulkActionsToolbar>
          {bulkActionButtons}
        </BulkActionsToolbar>

        {cloneElement(children, {
          hasBulkActions: bulkActionButtons !== false,
        })}
        
        <Pagination />
      </Card>
    </ListBase>
  );
};

export default MyList;
