import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '@material-ui/core';

const DateTimeField = ({ record, source }) => {
  let date = null;

  if (!record) {
    return null;
  }

  if (!record[source]) {
    return (
      <div>-</div>
    );
  }

  if (record[source] && record[source]._seconds) {
    date = new Date(record[source]._seconds * 1000);
  } else {
    date = new Date(record[source]);
  }

  return (
    <Typography>{date.toLocaleString()}</Typography>
  );
};

DateTimeField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

DateTimeField.defaultProps = {
  record: {},
};

export default DateTimeField;
