import { Typography } from '@material-ui/core';
import { Edit } from 'ra-ui-materialui';
import React from 'react';

const MyEdit = (props) => {
  return (
    <>
      {props.record ? (
        <Typography variant="h4" gutterBottom style={{ marginTop: '40px', marginBottom: '40px', fontWeight: '100' }}>
          Modifier {record ? `"${record.mentor?.name} ${record.mentor?.teaches?.fr}"` : ''}
        </Typography>
      ) : (
        <Typography variant="h4" gutterBottom style={{ marginTop: '40px', marginBottom: '40px', fontWeight: '100' }}>
          {/* props.title */}
        </Typography>
      )}

      <Edit {...props}>{props.children}</Edit>
    </>
  );
};

export default MyEdit;
