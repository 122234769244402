import PropTypes from 'prop-types';
import React from 'react';
import RichTextInput from 'ra-input-rich-text';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Typography, makeStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { ArrayInput, BooleanInput, Edit, FormGroupContextProvider, FormTab, ImageField, ImageInput, SelectInput, NumberInput, SimpleFormIterator, TextInput, required, useFormGroup, FormDataConsumer, CheckboxGroupInput, DateInput, RadioButtonGroupInput } from 'react-admin';

import Config from '../../../config';
import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';
import TabbedFormTabs from '../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../layout/VerticalTabbedForm';
import MyEdit from '../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  textInput: {
    width: '100%',
    marginBottom: 20,
  },
  separator: {
    marginTop: '2rem',
  },
}));

const SettingsEdit = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  return (
    <MyEdit {...props} title="Paramètres MentorShow" actions={Actions} className="edit-wrapper">
      <VerticalTabbedForm key={lang} toolbar={<EditToolbar withDelete />} redirect={false} tabs={<TabbedFormTabs />} variant="outlined">
        <FormTab label="Général">
          <TextInput disabled source="siteName" label="Site" />

          <BooleanInput source="landing" label="Mode Landing page" className={classes.textInput} />
        </FormTab>

        <FormTab label="Pricing table">
          <Typography variant="subtitle1" gutterBottom>
            Tableau de pricing
          </Typography>

          <TextInput source={`pricingTable.title.${lang}`} label="Titre" className={classes.textInput} initialValue="" />

          <ArrayInput source={`pricingTable.advantages.${lang}`} label="Avantages" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="description" label="avantage" className={classes.textInput} initialValue="" />
            </SimpleFormIterator>
          </ArrayInput>

          <ArrayInput source={`pricingTable.items.${lang}`} label="Pricing options" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <SelectInput source="priceId" options={{ label: "Price option" }} choices={Config.stripePrices.prices} />
              <TextInput source="name" label="Product name" className={classes.textInput} initialValue="" />
              <TextInput source="description" label="Product description" className={classes.textInput} initialValue="" />

              <BooleanInput source="featured" label="Mis en avant" initialValue={false} />

              <TextInput source="generalCouponCode" label="Coupon général à appliquer" className={classes.textInput} initialValue="" />
              <DateInput source="generalCouponEndDate" label="Date de fin du coupon général" className={classes.textInput} initialValue="" />

              <ArrayInput source="arguments" label="Arguments" style={{ width: '100%' }}>
                <SimpleFormIterator>
                  <TextInput source="description" label="Description" className={classes.textInput} initialValue="" />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>

          <FormDataConsumer>
              {(props) => {
                return (
                  <ArrayInput source={`pricingTable.detailItems.${lang}`} label="Pricing details" style={{ width: '100%' }}>
                    <SimpleFormIterator>
                      <TextInput source="label" label="Detail item label" className={classes.textInput} initialValue="" />
                      <TextInput source="description" label="Detail item description" className={classes.textInput} initialValue="" />

                      <CheckboxGroupInput
                        source="priceOptions"
                        label="This item is included in"
                        choices={props.formData.pricingTable?.items?.[lang]?.filter((i) => !!i?.priceId && !!i?.name)?.map((item) => ({ id: item.priceId, name: item.name }))}
                        row={false}
                        helperText="Si aucune case n'est cochée, alors l'élément ne sera actif pour aucune option tarifaire."
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
              )}}
          </FormDataConsumer>
        </FormTab>

        <FormTab label="[PW]* Pricing table">
          <Typography variant="subtitle1" gutterBottom>
            Tableau de pricing
          </Typography>

          <TextInput source={`pricingTablePw.title.${lang}`} label="Titre" className={classes.textInput} initialValue="" />

          <ArrayInput source={`pricingTablePw.advantages.${lang}`} label="Avantages" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="description" label="avantage" className={classes.textInput} initialValue="" />
            </SimpleFormIterator>
          </ArrayInput>

          <ArrayInput source={`pricingTablePw.items.${lang}`} label="Pricing options" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <SelectInput source="priceId" options={{ label: "Price option" }} choices={Config.stripePrices.prices} />
              <TextInput source="name" label="Product name" className={classes.textInput} initialValue="" />
              <TextInput source="description" label="Product description" className={classes.textInput} initialValue="" />

              <BooleanInput source="featured" label="Mis en avant" initialValue={false} />

              <ArrayInput source="arguments" label="Arguments" style={{ width: '100%' }}>
                <SimpleFormIterator>
                  <TextInput source="description" label="Description" className={classes.textInput} initialValue="" />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>

          <FormDataConsumer>
              {(props) => {
                return (
                  <ArrayInput source={`pricingTablePw.detailItems.${lang}`} label="Pricing details" style={{ width: '100%' }}>
                    <SimpleFormIterator>
                      <TextInput source="label" label="Detail item label" className={classes.textInput} initialValue="" />
                      <TextInput source="description" label="Detail item description" className={classes.textInput} initialValue="" />

                      <CheckboxGroupInput
                        source="priceOptions"
                        label="This item is included in"
                        choices={props.formData.pricingTablePw?.items?.[lang]?.filter((i) => !!i?.priceId && !!i?.name)?.map((item) => ({ id: item.priceId, name: item.name }))}
                        row={false}
                        helperText="Si aucune case n'est cochée, alors l'élément ne sera actif pour aucune option tarifaire."
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
              )}}
          </FormDataConsumer>
        </FormTab>

        <FormTab label="Pricing Gifts">
          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Offre Spéciale cadeaux
          </Typography>
          <div>
            <Typography variant="caption">Cette offre, quand elle est valide, s'applique sur les cadeaux en priorité par rapport aux autres offres en cours</Typography>
          </div>
          <div style={{ height: '2rem '}} />

          <TextInput source="pricing.gifts.generalCouponCode" label="Coupon général à appliquer aux gifts" className={classes.textInput} initialValue="" />
          <DateInput source="pricing.gifts.generalCouponEndDate" label="Date de fin du coupon" className={classes.textInput} initialValue="" />
        </FormTab>

        <FormTab label="Produits Stripe">
          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            Upgrade Auto (upsell)
          </Typography>
          <NumberInput source="pricing.autoUpgrade.trialDays" validate={[required()]} label="Nombre de jours d'essai gratuit" helperText="Si mis à 0, le premier paiement sera effectué immédiatement" style={{ marginBottom: 10 }} />
          <TextInput source="pricing.autoUpgrade.stripePriceId" validate={[required()]} label="ID Stripe du price pour les bump/upgrade" className={classes.textInput} />

          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            Bump
          </Typography>
          <NumberInput source="pricing.bump.trialDays" validate={[required()]} label="Nombre de jours d'essai gratuit" helperText="Si mis à 0, le premier paiement sera effectué immédiatement" style={{ marginBottom: 10 }} />
          <TextInput source="pricing.bump.stripePriceId" validate={[required()]} label="ID Stripe du price pour les bump/upgrade" className={classes.textInput} />
        </FormTab>

        <FormTab label="ActiveCampaign">
          <div className={classes.separator} />
          <Typography variant="subtitle1" gutterBottom>
            ActiveCampaign
          </Typography>

          <TextInput source="activeCompaign.newsletterList" label="Liste AC newsletter" className={classes.textInput} />
          <TextInput source="activeCompaign.passLeadsList" label="Liste AC prospects Pass" className={classes.textInput} />
          <TextInput source="activeCompaign.passClientsList" label="Liste AC clients Pass" className={classes.textInput} />
          <TextInput source="activeCompaign.bumpPassTagId" label="Tag AC clients bump" className={classes.textInput} helperText="ID du tag pour les acheteurs du bump" />
          <TextInput source="activeCompaign.cancelPassTagId" label="Tag AC clients cancel" className={classes.textInput} helperText="ID du tag pour les acheteurs qui annulent leur abonnement" />
          <TextInput source="activeCompaign.churnPassTagId" label="Tag AC clients churn" className={classes.textInput} helperText="ID du tag pour les acheteurs dont le paiement suivant ne passe pas" />
        </FormTab>

        <FormTab label="Incentive">
          <TextInput source={`incentive.title.${lang}`} label="Titre" className={classes.textInput} />
          <RichTextInput source={`incentive.pricing.${lang}`} label="Texte tarif" toolbar={Config.richTextToolbar} />
          <RichTextInput source={`incentive.body.${lang}`} label="Contenu" className={classes.textInput} toolbar={Config.richTextToolbar} />

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Image
          </Typography>

          <ImageInput source="incentive.image" label="Image" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source={`incentive.image.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} />

          <TextInput source={`incentive.buttonLabel.${lang}`} label="Texte bouton Pass" className={classes.textInput} />
          <TextInput source={`incentive.linkLabel.${lang}`} label="Texte lien cours seul" className={classes.textInput} />
        </FormTab>

        <FormTab label="CTA Sticky">
          <TextInput source={`ctaSticky.title.${lang}`} label="Titre (texte à gauche)" className={classes.textInput} />
          <TextInput source={`ctaSticky.text.${lang}`} label="Texte (milieu)" className={classes.textInput} />
          <TextInput source={`ctaSticky.buttonLabel.${lang}`} label="Libellé du bouton (à droite)" className={classes.textInput} />
        </FormTab>

        <FormTab label="Bandeaux">
          <FormGroupContextProvider name="options">
            <div>
              <Typography variant="caption">Cancel : Affiche le bandeau à tous les utilisateurs qui ont un pass actif mais dont le renouvellement a été désactivé</Typography>
            </div>
            <div>
              <Typography variant="caption">Upsell : Affiche le bandeau à tous les utilisateurs qui ont un acheté un cours et qui n'ont pas de pass actif</Typography>
            </div>
            <div>
              <Typography variant="caption">Promo : Affiche le bandeau à tous les utilisateurs (qu'ils aient un pass, un cours ou aucun des deux)</Typography>
            </div>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="options-content1" id="options-header1">
                <AccordionSectionTitle name="options">Cancel</AccordionSectionTitle>
              </AccordionSummary>

              <AccordionDetails id="options-content1" aria-labelledby="options-header1">
                <Typography variant="caption">Affiche le bandeau à tous les utilisateurs qui ont un pass actif mais dont le renouvellement a été désactivé</Typography>
                <BooleanInput source={`userBanners.cancel.enabled.${lang}`} label="Activer le bandeau" />
                <TextInput source={`userBanners.cancel.title.${lang}`} label={`Texte (${lang})`} variant="outlined" />
                <TextInput source={`userBanners.cancel.buttonText.${lang}`} label={`Texte Bouton (${lang})`} variant="outlined" />
                <div>
                  <Typography variant="caption">Si aucun des 2 champs n'est saisi, l'utilisateur sera redirigé vers la page d'upgrade auto</Typography>
                </div>
                <TextInput source={`userBanners.cancel.thriveProductId.${lang}`} label={`ID profuit ThriveCart (${lang})`} variant="outlined" helperText="Si saisi, une popup avec le checkout du produit sera affichée" />
                <TextInput source={`userBanners.cancel.url.${lang}`} label={`URL de destination (${lang})`} variant="outlined" helperText="Si saisi, le productId sera ignoré. L'utilisateur sera redirigé sur cette URL" />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="options-content2" id="options-header2">
                <AccordionSectionTitle name="options">Upsell</AccordionSectionTitle>
              </AccordionSummary>

              <AccordionDetails id="options-content2" aria-labelledby="options-header2">
                <Typography variant="caption">Affiche le bandeau à tous les utilisateurs qui ont un acheté un cours et qui n'ont pas de pass actif</Typography>
                <BooleanInput source={`userBanners.upsell.enabled.${lang}`} label="Activer le bandeau" />
                <TextInput source={`userBanners.upsell.title.${lang}`} label={`Texte (${lang})`} variant="outlined" />
                <TextInput source={`userBanners.upsell.buttonText.${lang}`} label={`Texte Bouton (${lang})`} variant="outlined" />
                <div>
                  <Typography variant="caption">Si aucun des 2 champs n'est saisi, l'utilisateur sera redirigé vers la page d'upgrade auto</Typography>
                </div>
                <TextInput source={`userBanners.upsell.thriveProductId.${lang}`} label={`ID profuit ThriveCart (${lang})`} variant="outlined" helperText="Si saisi, une popup avec le checkout du produit sera affichée" />
                <TextInput source={`userBanners.upsell.url.${lang}`} label={`URL de destination (${lang})`} variant="outlined" helperText="Si saisi, le productId sera ignoré. L'utilisateur sera redirigé sur cette URL" />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="options-content3" id="options-header3">
                <AccordionSectionTitle name="options">Pass</AccordionSectionTitle>
              </AccordionSummary>

              <AccordionDetails id="options-content3" aria-labelledby="options-header3">
                <Typography variant="caption">Affiche le bandeau à tous les détenteur d'un pass qui n'ont pas cancel</Typography>
                <BooleanInput source={`userBanners.pass.enabled.${lang}`} label="Activer le bandeau" />
                <TextInput source={`userBanners.pass.title.${lang}`} label={`Texte (${lang})`} variant="outlined" />
                <TextInput source={`userBanners.pass.buttonText.${lang}`} label={`Texte Bouton (${lang})`} variant="outlined" />
                <div>
                  <Typography variant="caption">Si aucun des 2 champs n'est saisi, l'utilisateur sera redirigé vers la page d'upgrade auto</Typography>
                </div>
                <TextInput source={`userBanners.pass.thriveProductId.${lang}`} label={`ID profuit ThriveCart (${lang})`} variant="outlined" helperText="Si saisi, une popup avec le checkout du produit sera affichée" />
                <TextInput source={`userBanners.pass.url.${lang}`} label={`URL de destination (${lang})`} variant="outlined" helperText="Si saisi, le productId sera ignoré. L'utilisateur sera redirigé sur cette URL" />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="options-content3" id="options-header3">
                <AccordionSectionTitle name="options">Promo</AccordionSectionTitle>
              </AccordionSummary>

              <AccordionDetails id="options-content3" aria-labelledby="options-header3">
                <Typography variant="caption">Affiche le bandeau à tous les utilisateurs</Typography>
                <BooleanInput source={`userBanners.promo.enabled.${lang}`} label="Activer le bandeau" />
                <TextInput source={`userBanners.promo.title.${lang}`} label={`Texte (${lang})`} variant="outlined" />
                <TextInput source={`userBanners.promo.buttonText.${lang}`} label={`Texte Bouton (${lang})`} variant="outlined" />
                <div>
                  <Typography variant="caption">Si aucun des 2 champs n'est saisi, l'utilisateur sera redirigé vers la page d'upgrade auto</Typography>
                </div>
                <TextInput source={`userBanners.promo.thriveProductId.${lang}`} label={`ID profuit ThriveCart (${lang})`} variant="outlined" helperText="Si saisi, une popup avec le checkout du produit sera affichée" />
                <TextInput source={`userBanners.promo.url.${lang}`} label={`URL de destination (${lang})`} variant="outlined" helperText="Si saisi, le productId sera ignoré. L'utilisateur sera redirigé sur cette URL" />
              </AccordionDetails>
            </Accordion>

            <div style={{ marginTop: 50 }}>
              <Typography variant="body2">Les bandeaux s'affichent par ordre de priorité, avec un seul bandeau bandeau affiché à la fois selon le cas de l'utilisateur (1: Cancel, 2: Upsell, 3: Pass, 4: Promo)</Typography>
            </div>
          </FormGroupContextProvider>
        </FormTab>

        <FormTab label="NPS">
          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            Typeform
          </Typography>
          <TextInput source={`nps.typeformId.${lang}`} label={`ID du formulaire Typeform (${lang})`} className={classes.textInput} />
        </FormTab>

        <FormTab label="Video Player">
          <TextInput source={`videoPlayer.blockedText.${lang}`} label={`Video player blocked watch text (${lang})`} className={classes.textInput} />
        </FormTab>

        <FormTab label="Cancel">
          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            Page
          </Typography>
          <RadioButtonGroupInput
            source="cancel.redirectType"
            choices={[
              { id: 'popup', name: 'Popup' },
              { id: 'redirect', name: 'Redirection' },
            ]}
            defaultValue="popup"
            label="Type de process de désabonnement"
          />
          <TextInput source={`cancel.destinationUrl.${lang}`} label={`Url de désabonnement (${lang})`} helperText="Renseignez ici une URL vers laquelle l'utilisateur sera redirigé lorsqu'il souhaite annuler son abonnement. Laissez ce champ vide pour garder le comportement classique à base de popups" className={classes.textInput} />
        </FormTab>

        <FormTab label="Réabonnement">
          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            Page
          </Typography>
          <TextInput source={`resubscribe.passBlock.text.${lang}`} label={`Texte de réabonnement (${lang})`} className={classes.textInput} />
          <TextInput source={`resubscribe.button.label.${lang}`} label={`Libellé du bouton de réabonnement (${lang})`} className={classes.textInput} />

          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            Mode de traiement
          </Typography>
          <RadioButtonGroupInput
            source="resubscribe.redirectType"
            choices={[
              { id: 'popup', name: 'Popup' },
              { id: 'redirect', name: 'Redirection' },
            ]}
            defaultValue="popup"
            label="Type de process de désabonnement"
          />

          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            Redirection
          </Typography>
          <TextInput source={`resubscribe.redirect.destinationUrl.${lang}`} label={`Url de désabonnement (${lang})`} helperText="Renseignez ici une URL vers laquelle l'utilisateur sera redirigé lorsqu'il souhaite annuler son abonnement. Laissez ce champ vide pour garder le comportement classique à base de popups" className={classes.textInput} />

          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            Popup
          </Typography>
          <TextInput source={`resubscribe.popup.title.${lang}`} label={`Titre de la pupup de réabonnement (${lang})`} className={classes.textInput} />
          <RichTextInput source={`resubscribe.popup.text.${lang}`} label="Texte de la pupup de réabonnement" className={classes.textInput} toolbar={Config.richTextToolbar} />

          <TextInput multiline source={`resubscribe.popup.success.${lang}`} label={`Message de succès (${lang})`} variant="outlined" helperText="Message de confirmation du réabonnement" className={classes.textInput} style={{ marginBottom: 20 }} />
          <TextInput multiline source={`resubscribe.popup.error.${lang}`} label={`Message d'erreur (${lang})`} variant="outlined" helperText="Ce message s'affiche si la requête de réabonnement n'aboutit pas" className={classes.textInput} />
        </FormTab>
      </VerticalTabbedForm>
    </MyEdit>
  );
};

SettingsEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SettingsEdit;

const AccordionSectionTitle = ({ children, name }) => {
  const formGroupState = useFormGroup(name);

  return <Typography color={formGroupState.invalid && formGroupState.dirty ? 'error' : 'inherit'}>{children}</Typography>;
};
