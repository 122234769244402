import React from 'react';
import {
  BooleanField,
  Datagrid,
  List,
  // Filter,
  TextField,
  // TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
} from 'react-admin';
import MyList from '../../layout/MyList';

import UserListActions from './utils/UserListActions';
import UserListFilter from './utils/UserListFilter';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const UserList = (props) => (
  <MyList
    {...props}
    title="Utilisateurs"
    filters={<UserListFilter />}
    bulkActionButtons={false}
    actions={<UserListActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="email" label="Email" />
      <TextField source="firstName" label="Prénom" />
      <TextField source="lastName" label="Nom" />
      <TextField source="id" label="ID" />
      <BooleanField source="fullPass.active" label="Pass renouvelable" />

      {/* <ShowButton label="" /> */}
      {/* <EditButton label="" /> */}
      <DeleteButton label="" redirect={false} undoable={false} />
    </Datagrid>
  </MyList>
);

export default UserList;
