import PropTypes from 'prop-types';
import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';

import {
  AutocompleteInput,
  Edit,
  ImageField,
  ImageInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';
import MyEdit from '../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  title: {
    fontSize: 14,
  },
  textInput: {
    width: '100%',
  },
  separator: {
    height: '2rem',
  },
}));

const TestimonialVideoTitle = ({ record }) => {
  return <span>Témoignage de {record ? `"${record.name}"` : ''}</span>;
};

TestimonialVideoTitle.propTypes = {
  record: PropTypes.any,
};

TestimonialVideoTitle.defaultProps = {
  record: null,
};

const TestimonialsVideoEdit = (props) => {
  const classes = useStyles();

  const Actions = <PageEditActions />;

  return (
    <MyEdit {...props} title={<TestimonialVideoTitle />} actions={Actions} className="edit-wrapper">
      <SimpleForm
        toolbar={<EditToolbar withDelete />}
        redirect={false}
        variant="outlined"
      >
        <ReferenceInput label="Cours" source="classId" reference="content/mentorshow/classes" filterToQuery={(searchText) => (searchText ? { mentor: { name: searchText } } : null)}>
          <AutocompleteInput optionText="mentor.name" />
        </ReferenceInput>
        <TextInput source="videoId" label="ID Vidéo" className={classes.textInput} />
        <TextInput source="name" label="Nom" className={classes.textInput} />
        <ImageInput source="avatar" label="Photo d'avatar" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </MyEdit>
  );
};

TestimonialsVideoEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TestimonialsVideoEdit;
