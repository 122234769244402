import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';

import { ArrayInput, SimpleForm, SimpleFormIterator, TextInput } from 'react-admin';

import EditToolbar from '../../../common/EditToolbar';
import MyEdit from '../../../layout/MyEdit';
import PageEditActions from '../../pages/PageEditActions';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  column: {
    padding: '0 50px',
    border: '1px solid #dedede',
  },
  card: {
    width: '100%',
    margin: '20px auto',
  },
}));

const MenuTitle = ({ record }) => {
  return <span>Menu {record ? `"${record.id}"` : ''}</span>;
};

MenuTitle.propTypes = {
  record: PropTypes.any,
};

MenuTitle.defaultProps = {
  record: null,
};

const FooterMenuEdit = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  return (
    <MyEdit {...props} title={<MenuTitle />} actions={Actions} className="edit-wrapper">
      <SimpleForm key={lang} toolbar={<EditToolbar withDelete />} redirect={false} variant="outlined">
        <TextInput disabled source="id" />

        <Grid container className={classes.root} spacing={5}>
          <Grid item xs={6} className={classes.column}>
            <TextInput source={`col1.title.${lang}`} label="Titre" className={classes.textInput} variant="outlined" />
            <ArrayInput source="col1.items" label="Colonne 1" style={{ width: '100%' }}>
              <SimpleFormIterator>
                <TextInput source={`label.${lang}`} label="Libellé" className={classes.textInput} variant="outlined" />
                <TextInput source="path" label="Lien" className={classes.textInput} variant="outlined" />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>

          <Grid item xs={6} className={classes.column}>
            <TextInput source={`col2.title.${lang}`} label="Titre" className={classes.textInput} variant="outlined" />
          </Grid>
        </Grid>
        <ArrayInput source="col3.items" label="Bottom Links" style={{ width: '100%' }}>
              <SimpleFormIterator>
                <TextInput source={`label.${lang}`} label="Libellé" className={classes.textInput} variant="outlined" />
                <TextInput source="path" label="Lien" className={classes.textInput} variant="outlined" />
              </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </MyEdit>
  );
};

FooterMenuEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FooterMenuEdit;
