import PropTypes from 'prop-types';
import React from 'react';

const PageTitle = ({ record }) => {
  return <span>Page {record ? `"${record.title.fr}"` : ''}</span>;
};

PageTitle.propTypes = {
  record: PropTypes.object.isRequired,
};

export default PageTitle;
