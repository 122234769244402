import PropTypes from 'prop-types';
import React from 'react';
import { CheckboxGroupInput, Edit, ImageField, ImageInput, NumberInput, SimpleForm, TextInput } from 'react-admin';
import { Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Config from '../../../config';
import EditToolbar from '../../common/EditToolbar';
import PageEditActions from '../pages/PageEditActions';
import MyEdit from '../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '20px auto',
  },
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
}));

const ClassTitle = ({ record }) => {
  return <span>Cours {record ? `"${record.name} ${record.teaches.fr}"` : ''}</span>;
};

ClassTitle.propTypes = {
  record: PropTypes.any,
};

ClassTitle.defaultProps = {
  record: null,
};

const ClassComingSoonEdit = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  return (
    <MyEdit {...props} title={<ClassTitle />} actions={Actions} className="edit-wrapper">
      <SimpleForm key={lang} toolbar={<EditToolbar />} redirect={false} variant="outlined">
        <TextInput disabled source="id" />

        <NumberInput source="order" label="Rang" />
        <TextInput source="name" label="Nom" className={classes.textInput} />
        <TextInput source={`domain.${lang}`} label="Domaine" className={classes.textInput} />
        <TextInput source={`teaches.${lang}`} label="Enseignement" className={classes.textInput} />
        <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} />

        <Typography variant="body1" className={classes.separator}>
          Sélectionnez les langues pour lesquelles vous voulez ajouter cet élément. Si aucune case n'est cochée, alors l'élément sera affiché sur toutes les langues du site.
        </Typography>
        <CheckboxGroupInput source="languages" choices={Config.supportedLocales.map((locale) => ({ id: locale, name: locale.toUpperCase() }))} row={false} />

        <Typography variant="subtitle1" gutterBottom className={classes.separator}>
          Image
        </Typography>
        <ImageInput source="image" label="Photo d'aperçu" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source={`image.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} />
      </SimpleForm>
    </MyEdit>
  );
};

ClassComingSoonEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ClassComingSoonEdit;
