import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { showNotification as showNotificationAction } from 'react-admin';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  makeStyles,
} from '@material-ui/core';

import SendIcon from '@material-ui/icons/Send';
import KeyIcon from '@material-ui/icons/SupervisorAccount';

import Config from '../../../../config';
import { useFirebase } from '../../../../firebase';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginBottom: '1em',
  },
}));

const getUserRole = async ({ firebase, record }) => {
  const userRoleSnap = await firebase.db.collection('config_roles').doc(record.id).get();
  const userRole = userRoleSnap.data();

  if (userRole && JSON.stringify(userRole) !== '{}') {
    return {
      id: Object.keys(userRole)[0],
      label: Config.userRoles[Object.keys(userRole)[0]],
    };
  }

  return {
    id: null,
    label: 'Utilisateur',
  };
};

const UserEditAsideActions = ({ record, showNotification }) => {

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingRole, setIsLoadingRole] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [currentUserRole, setCurrentUserRole] = React.useState({});

  const classes = useStyles();
  const firebase = useFirebase();

  React.useEffect(() => {
    const getRole = async () => {
      const role = await getUserRole({ record, firebase });
      setCurrentUserRole(role);
    };

    getRole();
  }, [firebase, record]);

  const sendPasswordResetEmail = async () => {
    setIsLoading(true);
    try {
      await firebase.sendPasswordResetEmail({ email: record.email });
      showNotification('Email envoyé');

    } catch (error) {
      showNotification('Impossible d\'envoyer l\'email', 'warning');
    }
    setIsLoading(false);
  };

  const handleChangeRole = async (roleId) => {
    setIsLoadingRole(true);
    setIsModalOpen(false);

    await firebase.db.collection('config_roles').doc(record.id).set(!roleId ? {} : { [roleId]: true });

    const role = await getUserRole({ record, firebase });

    setCurrentUserRole(role);

    setIsLoadingRole(false);
  };

  return (
    <Paper className={classes.root} style={{ borderRadius: 0 }}>
      <List
        component="nav"
        subheader={<ListSubheader component="div">Actions</ListSubheader>}
      >
        <ListItem button onClick={sendPasswordResetEmail} disabled={isLoading}>
          <ListItemIcon>
            {isLoading
              ? (
                <CircularProgress size={25} />
              ) : (
                <SendIcon />
              )}
          </ListItemIcon>
          <ListItemText inset primary="Envoyer Réinitialisation MDP" />
        </ListItem>

        <ListItem button onClick={() => setIsModalOpen(true)} disabled={isLoadingRole}>
          <ListItemIcon>
            {isLoadingRole
              ? (
                <CircularProgress size={25} />
              ) : (
                <KeyIcon />
              )}
          </ListItemIcon>
          <ListItemText inset primary={`Changer le rôle (${currentUserRole.label})`} />
        </ListItem>
      </List>

      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} aria-labelledby="simple-dialog-title">
        <DialogTitle id="simple-dialog-title">{'Gérer les rôles de l\'utilisateur'}</DialogTitle>
        <DialogContent>
          <div
            id="alert-dialog-description"
            style={{ fontFamily: 'sans-serif' }}
          >
            <p style={{ margin: '0px' }}>{'Sélectionnez le rôle que vous souhaitez attribuer à l\'utilisateur'}</p>
            <List>
              <ListItem button onClick={() => handleChangeRole(null)} disabled={!currentUserRole.id}>
                <ListItemText primary="Utilisateur" />
              </ListItem>
              {Object.keys(Config.userRoles).map((roleKey) => (
                <ListItem button onClick={() => handleChangeRole(roleKey)} key={roleKey} disabled={roleKey === currentUserRole.id}>
                  <ListItemText primary={Config.userRoles[roleKey]} />
                </ListItem>
              ))}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>
            <span>Annuler</span>
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

UserEditAsideActions.propTypes = {
  record: PropTypes.object.isRequired,
  showNotification: PropTypes.func.isRequired,
};

export default connect(null, { showNotification: showNotificationAction })(
  UserEditAsideActions,
);
