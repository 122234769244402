import PropTypes from 'prop-types';
import React from 'react';

const AmountField = ({ record, options }) => {
  const amount = record && record.plan && record.plan.amount ? (record.plan.amount / 100).toLocaleString('fr-FR', options) : '-';
  return (
    <div>{amount}</div>
  );
};

AmountField.propTypes = {
  record: PropTypes.object,
  options: PropTypes.object,
};

AmountField.defaultProps = {
  options: {},
  record: null,
};

export default AmountField;
