import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { Form } from 'react-final-form';
import { TextInput, useListContext } from 'react-admin';

import SearchIcon from '@material-ui/icons/Search';

const UserListFilterForm = () => {
  const {
    // displayedFilters,
    filterValues,
    setFilters,
    hideFilter,
  } = useListContext();

  React.useEffect(() => {
    return () => {
      setFilters({}, []);
    };
  }, []);

  const onSubmit = (values) => {
    const newValues = { ...values };

    if (Object.keys(values).length > 0) {
      Object.keys(values).forEach((key) => {
        newValues[key] = newValues[key].trim().toLowerCase();
      });

      setFilters(newValues);
    } else {
      hideFilter('main');
    }
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
            <TextInput
              resettable
              helperText={false}
              source="email"
              label="Email"
              onChange={handleSubmit}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

UserListFilterForm.propTypes = {
};

export default UserListFilterForm;
