import React from 'react';

const FirebaseContext = React.createContext(null);

export function useFirebase(fn) {
  const firebase = React.useContext(FirebaseContext);

  if (!firebase) {
    return;
  }

  if (fn) {
    /* eslint consistent-return:0 */
    return fn(firebase);
  }

  return firebase;

}

export const withFirebase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => !!firebase && <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;
