import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import FooterMenuEdit from './edit-forms/FooterMenuEdit';
import HeaderMenuEdit from './edit-forms/HeaderMenuEdit';
import PageEditActions from '../pages/PageEditActions';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  column: {
    padding: '0 50px',
    border: '1px solid #dedede',
  },
  card: {
    width: '100%',
    margin: '20px auto',
  },
}));

const MenuTitle = ({ record }) => {
  return <span>Menu {record ? `"${record.id}"` : ''}</span>;
};

MenuTitle.propTypes = {
  record: PropTypes.any,
};

MenuTitle.defaultProps = {
  record: null,
};

const MenuEdit = (props) => {
  const classes = useStyles();

  const { lang } = useSelector((state) => state.app);

  const Actions = <PageEditActions />;

  const { id: menuId } = props;

  switch (menuId) {
    case 'header':
      return <HeaderMenuEdit key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    case 'footer':
      return <FooterMenuEdit key={lang} classes={classes} lang={lang} actions={Actions} {...props} />;

    default:
      return null;
  }
};

MenuEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export default MenuEdit;
