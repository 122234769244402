import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button as RAButton,
  // CREATE,
  showNotification,
  // UPDATE,
  refreshView,
} from 'react-admin';
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import countries from '../../../utils/countries.json';
import { processCsvFile } from './csv-extractor';
import { useFirebase } from '../../../firebase';

const importTypes = {
  CREATTION: 0,
  WITH_PASS: 1,
  WITH_SUBSCRIPTION: 2,
};

const ImportButton = (props) => {
  const { resource, parseConfig, logging, mappings } = props;

  if (logging) {
    console.log('ImportButton', { props });
  }

  if (!resource) {
    throw new Error("the 'resource' prop was empty, did you pass in the {...props} to the ImportButton?");
  }

  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);
  const [values, setValues] = React.useState(null);
  const [errorTxt, setErrorTxt] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedLang, setSelectedLang] = React.useState('fr');
  const [importType, setImportType] = React.useState(importTypes.WITH_SUBSCRIPTION);
  const [csvMapping, setCsvMapping] = React.useState({
    lastName: 'last_name',
    firstName: 'first_name',
    email: 'email',
    city: 'address_city',
    zip: 'address_zip',
    state: 'address_state',
    country: 'address_country',
    phone: 'telephone',
    itemType: 'relevant_item',
  });

  const dispatch = useDispatch();

  const firebase = useFirebase();

  const openImportDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFileName(null);
    setValues(null);
    setOpen(false);
  };

  const handleSubmitCreate = async () => {
    console.log('values', values);
    // return;

    // setOpen(false);
    setIsLoading(true);
    try {
      let delay = 0;
      await Promise.all(
        // values.map((value) => dataProvider(CREATE, resource, { data: value })),
        values.map((value) => {
          delay += 100;

          switch (importType) {
            case importTypes.WITH_PASS: {
              delay += 100;
              return new Promise((resolve) => setTimeout(resolve, delay)).then(() => firebase.createUser({ user: value, withPass: true, product: value.classId, lang: selectedLang }));
            }
            case importTypes.WITH_SUBSCRIPTION: {
              delay += 1000;
              return new Promise((resolve) => setTimeout(resolve, delay)).then(() => firebase.subscribeUserToPass({ ...value, lang: selectedLang }));
            }
            default: {
              return new Promise((resolve) => setTimeout(resolve, delay)).then(() => firebase.createUser({ user: value, withPass: false, product: value.classId, lang: selectedLang }));
            }
          }
          // return new Promise((resolve) => setTimeout(resolve, delay)).then(() => firebase.createUser({ user: value, withPass: importType === importTypes.WITH_PASS, withSubscription: importType === importTypes.WITH_SUBSCRIPTION, product: value.classId }));
          // return new Promise((resolve) => setTimeout(resolve, delay)).then(() => firebase.subscribeUserToPass(value));
        }),
      );
      setIsLoading(false);
      setFileName(null);
      setValues(null);
      dispatch(showNotification(`Ficher "${fileName}" importé`, 'info', { autoHideDuration: 60000, undoable: true }));
      dispatch(refreshView());
      // setOpen(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(showNotification(`Error importing ${fileName}, ${error}`, 'error', { autoHideDuration: 30000, undoable: true }));
    }
  };

  /* const handleSubmitOverwrite = async () => {
    if (values.some((v) => !v.id)) {
      throw new Error('Some rows have no value for the "id" column');
    }

    setOpen(false);
    try {
      await Promise.all(
        values.map((value) => dataProvider(UPDATE, resource, { id: value.id, data: value })),
      );
      dispatch(showNotification(`Imported ${fileName}`));
    } catch (error) {
      dispatch(showNotification(`Error importing ${fileName}, ${error}`));
    }
  }; */

  const processFile = async (fileToProcess = file) => {
    let newValues;

    try {
      const vals = await processCsvFile(fileToProcess, parseConfig);
      /* if (vals.some((v) => !v.id)) {
        throw new Error('Some rows have no value for the "id" column');
      } */
      if (logging) {
        console.log({ vals });
      }

      if (!mappings) {
        newValues = vals;
      } else {
        newValues = vals
          .map((item) => {
            const newItem = {};

            Object.keys(csvMapping).forEach((key) => {
              newItem[key] = item[csvMapping[key]] || '';
            });

            // Added
            newItem.orderId = item.order_id;
            newItem.orderDate = new Date(`${item.order_date} ${item.order_time}`);
            newItem.processor = item.processor;
            newItem.productId = item.relevant_item_id;
            newItem.productName = item.relevant_item_name;
            newItem.transactionAmountTotal = item.total;
            newItem.transactionId = item.transaction_id;

            return newItem;
          })
          .filter((val) => !!val.email)
          .filter((val) => val.itemType === 'product' && vals.filter((item2) => item2.email === val.email).length > 1)
          .map((val) => {
            return {
              ...val,
              itemType: 'pass',
              country: countries.find((item) => item.name === val.country)?.alpha2Code || 'SP',
            };
          });
      }

      console.log('newValues', newValues);

      setErrorTxt(null);
      return newValues;
    } catch (error) {
      console.error(error);
      setErrorTxt(error.toString());
      return newValues;
    }
  };

  React.useEffect(() => {
    const process = async () => {
      const newValues = await processFile(file);
      setValues(newValues);
    };

    if (file) {
      process();
    }
  }, [file, csvMapping]);

  const onFileAdded = async (e) => {
    const fileToProcess = e.target.files && e.target.files[0];
    await setFile(e.target.files[0]);
    await setFileName(fileToProcess.name);
  };

  const handleMappingChange = async (attr, value) => {
    setCsvMapping({
      ...csvMapping,
      [attr]: value.trim(),
    });
  };

  return (
    <>
      <RAButton color="primary" component="span" label="Import CSV" onClick={openImportDialog} className={props.className}>
        <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: '20' }} />
      </RAButton>

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Créer des utilisateurs à partir d'un CSV</DialogTitle>

        <DialogContent>
          <div id="alert-dialog-description" style={{ fontFamily: 'sans-serif' }}>
            <p style={{ margin: '0px' }}>Règles pour le fichier CSV</p>
            <ol>
              <li>{"Doit être de type '.csv' or '.tsv'"}</li>
              <li>Doit être délimité par des points-virgules (;)</li>
            </ol>

            <div style={{ marginTop: 50 }}>
              <h5>1- Sélectionnez un fichier</h5>
              <Button variant="contained" component="label">
                <span>Choose File</span>
                <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: '20' }} />
                <input type="file" style={{ display: 'none' }} onChange={onFileAdded} accept=".csv,.tsv,.txt" />
              </Button>

              {!!fileName && (
                <p style={{ marginBottom: '0px' }}>
                  {'Processed: '}
                  <strong>{fileName}</strong>
                </p>
              )}
              {!!values && (
                <p style={{ margin: '0px' }}>
                  {'Nombre de lignes valides: '}
                  <strong>{values.length}</strong>
                </p>
              )}
              {!!errorTxt && <p style={{ margin: '0px', color: 'red' }}>{errorTxt}</p>}
            </div>
          </div>

          {!!values && (
            <>
              <div style={{ marginTop: 50 }}>
                <h5>2- Choisissez vos options</h5>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Langue des utilisateurs importés</FormLabel>
                  <RadioGroup row aria-label="language" name="lang" value={selectedLang} onChange={(event) => setSelectedLang(event.target.value)}>
                    <FormControlLabel value="fr" control={<Radio />} label="Français" />
                    <FormControlLabel value="es" control={<Radio />} label="Espagnol" />
                    <FormControlLabel value="en" control={<Radio />} label="Anglais" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset">
                  <FormLabel component="legend">Type d'import</FormLabel>
                  <RadioGroup aria-label="Import type" name="importType" value={importType} onChange={(event) => setImportType(event.target.value)}>
                    <FormControlLabel value={importTypes.CREATTION} control={<Radio />} label="Création simple" />
                    <FormControlLabel value={importTypes.WITH_PASS} control={<Radio />} label="Création + Pass (1 an)" />
                    <FormControlLabel value={importTypes.WITH_SUBSCRIPTION} control={<Radio />} label="Création + Pass + Renouvellement (abonnement)" />
                  </RadioGroup>
                </FormControl>
              </div>

              <div style={{ marginTop: 50 }}>
                <h5>2- Rectifiez le mapping des colonnes</h5>
                {Object.keys(csvMapping).map((key) => (
                  <TextField key={key} label={key} id="outlined-size-small" value={csvMapping[key]} variant="outlined" size="small" onChange={(e) => handleMappingChange(key, e.target.value)} style={{ margin: 10 }} />
                ))}
              </div>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>
            <span>Annuler</span>
          </Button>
          <Button disabled={!values || isLoading} onClick={handleSubmitCreate} color="secondary" variant="contained">
            {isLoading && <CircularProgress size={14} />}
            {!isLoading && <span>Importer les utilisateurs</span>}
          </Button>
          {/* <Button
            disabled={!values}
            onClick={handleSubmitOverwrite}
            color="primary"
            variant="contained"
          >
            <span>Import as Overwrite</span>
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

ImportButton.propTypes = {
  resource: PropTypes.any.isRequired,
  parseConfig: PropTypes.any,
  logging: PropTypes.any.isRequired,
  mappings: PropTypes.bool.isRequired,
};

ImportButton.defaultProps = {
  parseConfig: null,
};

export default ImportButton;
