import React from 'react';

import {
  Datagrid,
  Filter,
  TextField,
  TextInput,
  DeleteButton,
} from 'react-admin';
import MyList from '../../layout/MyList';

const MsplusOffersList = (props) => (
  <MyList {...props} title="Offres MS Plus" bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Id" />
      <TextField source="description" label="Description" />
      <DeleteButton label="" redirect={false} undoable={false} />
    </Datagrid>
  </MyList>
);

export default MsplusOffersList;
