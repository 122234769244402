import PropTypes from 'prop-types';
import React from 'react';

// import UserListFilterButton from './UserListFilterButton';
import UserListFilterForm from './UserListFilterForm';

const UserListFilter = (props) => {

  const { context } = props;

  return context === 'button'
    ? null
    : (
      <UserListFilterForm {...props} />
    );
};

UserListFilter.propTypes = {
  context: PropTypes.any,
};

UserListFilter.defaultProps = {
  context: null,
};

export default UserListFilter;
