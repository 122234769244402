import React from 'react';

import {
  BooleanField,
  Datagrid,
  List,
  Filter,
  NumberField,
  TextField,
  TextInput,
  EditButton,
  DeleteButton,
} from 'react-admin';
import ColorStickField from '../../fields/ColorStickField';

import DateTimeField from '../../fields/DateTimeField';
import MyList from '../../layout/MyList';

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Rechercher un mentor" source="mentor.name" alwaysOn />
  </Filter>
);

const ClassesList = (props) => (
  <MyList {...props} title="Cours" filters={<PageFilter />} bulkActionButtons={false} sort={{ field: 'order', order: 'ASC' }}>
    <Datagrid rowClick="edit">
      <BooleanField source="published" label="Publié" />
      <NumberField source="order" label="Rang" />
      <TextField source="mentor.name" label="Mentor" />
      <ColorStickField source="adminWarningColor" label="Warning" />
      <TextField source="mentor.domain.fr" label="Domaine" />
      <TextField source="mentor.teaches.fr" label="Enseignement" />
      <DateTimeField source="lastupdate" label="Mise à jour" />
      {/* <EditButton label="" /> */}
      <DeleteButton label="" redirect={false} undoable={false} />
    </Datagrid>
  </MyList>
);

export default ClassesList;
