import React from 'react';

import {
  TextField,
  Datagrid,
  List,
  EditButton,
} from 'react-admin';

const SettingsList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="siteName" label="Site" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export default SettingsList;
