import PropTypes from 'prop-types';
import React from 'react';

import { makeStyles } from '@material-ui/core';

import {
  Edit,
  FormTab,
  TextInput,
} from 'react-admin';

import EditToolbar from '../../../../common/EditToolbar';
import PageTitle from '../PageTitle';
import TabbedFormTabs from '../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../layout/VerticalTabbedForm';
import MyEdit from '../../../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
}));

const DashboardPageEditForm = ({ lang, ...props }) => {
  const classes = useStyles();

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm
        toolbar={<EditToolbar />}
        redirect={false}
        tabs={<TabbedFormTabs />}
        variant="outlined"
      >
        <FormTab label="Général">
          <TextInput disabled source="id" />

          <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Mes Cours">
          <TextInput source={`sections.myClasses.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.myClasses.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Pré-ventes">
          <TextInput source={`sections.preSales.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.preSales.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Cours dispo">
          <TextInput source={`sections.classes.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.classes.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Bientôt dispo">
          <TextInput source={`sections.comingSoon.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.comingSoon.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Popup Parrainage">
          <TextInput source={`sections.referralPopup.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput multiline source={`sections.referralPopup.callout.${lang}`} label="Appel à action" className={classes.textInput} />
          <TextInput multiline source={`sections.referralPopup.description.${lang}`} label="Description" className={classes.textInput} />
        </FormTab>

      </VerticalTabbedForm>
    </MyEdit>
  );
};

DashboardPageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default DashboardPageEditForm;
