import PropTypes from 'prop-types';
import React from 'react';

import { Typography, makeStyles } from '@material-ui/core';

import {
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';

import EditToolbar from '../../../../common/EditToolbar';
import PageTitle from '../PageTitle';
import TabbedFormTabs from '../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../layout/VerticalTabbedForm';
import MyEdit from '../../../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
}));

const AllClassesPageEditForm = ({ lang, ...props }) => {

  const classes = useStyles();

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm
        toolbar={<EditToolbar />}
        redirect={false}
        tabs={<TabbedFormTabs />}
        variant="outlined"
      >
        <FormTab label="Général">
          <TextInput disabled source="id" />
        </FormTab>

        <FormTab label="SEO">
          <div className={classes.separator} />
          <Typography variant="subtitle1" gutterBottom>SEO</Typography>

          <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} />

          <ImageInput source="seo.image" label="Photo d'aperçu SEO" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Hero">
          <TextInput source={`sections.hero.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.hero.description.${lang}`} label="Description" className={classes.textInput} />
        </FormTab>

        <FormTab label="Bientôt Dispo">
          <TextInput source={`sections.comingSoon.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.comingSoon.subTitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Small CTA">
          <TextInput source={`sections.smallCta.text.${lang}`} label="Texte" className={classes.textInput} />
          <TextInput source={`sections.smallCta.buttonText.${lang}`} label="Texte du bouton" className={classes.textInput} />
          <TextInput source={`sections.smallCta.buttonUrl`} label="URL du bouton" className={classes.textInput} />
        </FormTab>

        <FormTab label="FAQ">
          <TextInput source={`sections.faq.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.faq.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
          <ArrayInput source="sections.faq.items" label="Liste des questions" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`question.${lang}`} label="Question" className={classes.textInput} />
              <TextInput source={`answer.${lang}`} label="Réponse" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

      </VerticalTabbedForm>
    </MyEdit>
  );
};

AllClassesPageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default AllClassesPageEditForm;
