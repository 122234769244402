import PropTypes from 'prop-types';
import React from 'react';
import RichTextInput from 'ra-input-rich-text';

import { Grid, Typography, makeStyles } from '@material-ui/core';

import { ArrayInput, BooleanInput, Edit, FormTab, ImageField, ImageInput, SelectInput, SimpleFormIterator, TextInput } from 'react-admin';

import Config from '../../../../../config';
import EditToolbar from '../../../../common/EditToolbar';
import PageTitle from '../PageTitle';
import TabbedFormTabs from '../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../layout/VerticalTabbedForm';
import MyEdit from '../../../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
  separator: {
    marginTop: '2rem',
  },
  root: {
    flexGrow: 1,
    width: '100%',
  },
  column: {
    padding: '0 50px',
    border: '1px solid #dedede',
  },
  card: {
    width: '100%',
    margin: '20px auto',
  },
}));

const PassFullAccessPageEditForm = ({ lang, ...props }) => {
  const classes = useStyles();

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm toolbar={<EditToolbar />} redirect={false} tabs={<TabbedFormTabs />} variant="outlined">
        <FormTab label="Général">
          <TextInput disabled source="id" />
        </FormTab>

        <FormTab label="SEO">
          <div className={classes.separator} />
          <Typography variant="subtitle1" gutterBottom>
            SEO
          </Typography>

          <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} />

          <ImageInput source="seo.image" label="Photo d'aperçu SEO" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Offre Webinaire">
          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Checkout
          </Typography>
          <TextInput source={`offers.webinar.thriveProductId.${lang}`} label={`ID Produit ThriveCart (${lang})`} className={classes.textInput} helperText={`ID produit ThriveCart (webinaire) (${lang})`} />
          <SelectInput source="offers.webinar.priceId" options={{ label: "Price option" }} choices={Config.stripePrices.prices}/>

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Connexion
          </Typography>
          <TextInput source={`offers.webinar.signin.title.${lang}`} label={`Titre (${lang})`} className={classes.textInput} helperText={`Titre de la page de connexion (${lang})`} />
          <ImageInput source="offers.webinar.signin.image" label="Image" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Inscription
          </Typography>
          <TextInput source={`offers.webinar.signup.title.${lang}`} label={`Titre (${lang})`} className={classes.textInput} helperText={`Titre de la page d'inscription (${lang})`} />
          <TextInput source={`offers.webinar.signup.description.${lang}`} label={`Description (${lang})`} className={classes.textInput} helperText={`Description de la page d'inscription (${lang})`} />
          <ImageInput source="offers.webinar.signup.image" label="Image" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Offres spéciales">
          <Typography variant="subtitle1" gutterBottom>
            Offres spéciales
          </Typography>

          <div>
            <Typography variant="caption">Ici, vous pouvez créer des offres spéciales avec un checkout dédié. Pour chaque offre, définissez:</Typography>
          </div>
          <div>
            <Typography variant="caption">{`- ID de l'offre: permet de définir le slug de l'offre et donc son URL => /checkout/pass/{offerId}`}</Typography>
          </div>
          <div>
            <Typography variant="caption">{`- ID produit thrivecart: Le produit à afficher sur le checkout (ou l'id de l'A/B test)`}</Typography>
          </div>

          <ArrayInput source="offers.specialOffers" label="">
            <SimpleFormIterator>
              <TextInput source="offerId" label="ID de l'offre" className={classes.textInput} helperText="En minuscules, ne doit pas contenir des caractères spéciaux autres que '-' et '_'. Ex: we, fl, we-pass" />
              <TextInput source="thriveProductId" label="ID Produit Thrivecart" className={classes.textInput} helperText="ID du produit ou de l'A/B test à afficher dans le checkout" />
              <TextInput source="couponId" label="ID Coupon" className={classes.textInput} helperText="ID du coupon de réduction Stripe à appliquer pour cette offre" />
            </SimpleFormIterator>
          </ArrayInput>

          <Typography variant="subtitle1" gutterBottom>Offres spéciales Cadeau</Typography>

          <ArrayInput source="offers.giftSpecialOffers" label="">
            <SimpleFormIterator>
              <TextInput source="offerId" label="ID de l'offre" className={classes.textInput} helperText="En minuscules, ne doit pas contenir des accents ou des caractères spéciaux autres que '-' et '_'. Ex: offre-speciale, fete-des-meres" />
              <TextInput source="thriveProductId" label="ID Produit Thrivecart" className={classes.textInput} helperText="ID du produit ou de l'A/B test à afficher dans le checkout" />
              <SelectInput source="priceId" options={{ label: "Price option" }} choices={Config.stripePrices.prices}/>
              <TextInput source="couponId" label="ID Coupon" className={classes.textInput} helperText="ID du coupon de réduction Stripe à appliquer pour cette offre" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Checkout">
          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            ThriveCart Produit
          </Typography>
          <TextInput source={`offers.default.thriveProductId.${lang}`} label={`ID Produit Pass (${lang})`} className={classes.textInput} helperText={`ID produit ThriveCart (${lang})`} />
          <SelectInput source="offers.default.priceId" options={{ label: "Price option" }} choices={Config.stripePrices.prices}/>

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            ThriveCart Cadeau
          </Typography>
          <TextInput source={`offers.gift.thriveProductId.${lang}`} label={`ID Produit Pass Cadeau (${lang})`} className={classes.textInput} helperText={`ID produit ThriveCart (${lang})`} />
          <SelectInput source="offers.gift.priceId" options={{ label: "Price option" }} choices={Config.stripePrices.prices}/>

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Bénéfices
          </Typography>
          <TextInput source={`offers.default.checkout.benefits.title.${lang}`} label={`Bénéfices Titre (${lang})`} className={classes.textInput} />

          <ArrayInput source="offers.default.checkout.benefits.items" label="Descriptions" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`text.${lang}`} label="Titre" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Hero">
          <TextInput source={`sections.hero.title.${lang}`} multiline label="Titre" className={classes.textInput} />

          <TextInput source={`sections.hero.description.${lang}`} multiline label="Description" className={classes.textInput} />

          <ImageInput source={`sections.hero.image.${lang}`} label="Image de hero" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            Vidéo
          </Typography>
          <TextInput source={`sections.hero.videoId.${lang}`} label="ID Vidéo Bande annonce" className={classes.textInput} />

          <Typography variant="subtitle1" gutterBottom className={classes.separator}>
            CTA
          </Typography>

          <TextInput source={`sections.hero.cta.description.${lang}`} label="CTA Description" className={classes.textInput} />
          <TextInput source={`sections.hero.cta.label.${lang}`} label="Button label" className={classes.textInput} />

          <ArrayInput source="sections.hero.medias" label="Logos Médias" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="image" label="Photo" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>

          <ArrayInput source={`sections.hero.testimonials.${lang}`} label="Témoignages" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="text" label="Texte" className={classes.textInput} />
              <TextInput source="author" label="Auteur" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Pré-ventes">
          <TextInput source={`sections.preSales.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.preSales.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Cours">
          <TextInput source={`sections.classes.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.classes.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Bientôt dispo">
          <TextInput source={`sections.comingSoon.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.comingSoon.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
          <ArrayInput source="sections.comingSoon.items" label="Items" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="name" label="Nom du mentor" className={classes.textInput} />
              <TextInput source={`domain.${lang}`} label="Domaine" className={classes.textInput} />
              <TextInput source={`teaches.${lang}`} label="Enseignement" className={classes.textInput} />
              <TextInput source={`description.${lang}`} label="Description" className={classes.textInput} />

              <Typography variant="subtitle1" gutterBottom className={classes.separator}>
                Image
              </Typography>

              <ImageInput source="image" label="Photo" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput source={`image.alt.${lang}`} multiline label="Texte alternatif (alt)" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Avantages">
          <ArrayInput source="sections.advantages.items" label="Items" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
              <TextInput source={`text.${lang}`} label="Description" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Aperçu de cours">
          <TextInput source={`sections.classPreviews.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.classPreviews.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Pricing">
          <TextInput source={`sections.pricing.badgeText.${lang}`} label="Texte du badge" className={classes.textInput} />
          <TextInput source={`sections.pricing.title.${lang}`} label="Titre" className={classes.textInput} />

          <Grid container className={classes.root} spacing={5}>
            <Grid item xs={6} className={classes.column}>
              <BooleanInput source="sections.pricing.monthly.active" label="Actif" />
              <BooleanInput source="sections.pricing.monthly.isFeatured" label="Mis en avant (populaire)" />
              <TextInput source={`sections.pricing.monthly.price.${lang}`} label="Tarif (€)" className={classes.textInput} />
              <TextInput source={`sections.pricing.monthly.periodicity.${lang}`} label="Label périodicité (par mois)" className={classes.textInput} />
              <TextInput source={`sections.pricing.monthly.label.${lang}`} label="Label tarification" className={classes.textInput} />

              <ArrayInput source="sections.pricing.monthly.includes" label="Mensuel" style={{ width: '100%' }}>
                <SimpleFormIterator>
                  <TextInput source={`text.${lang}`} label="Description" className={classes.textInput} />
                </SimpleFormIterator>
              </ArrayInput>

              <TextInput source={`sections.pricing.monthly.buttonLabel.${lang}`} label="Libellé bouton" className={classes.textInput} />
            </Grid>

            <Grid item xs={6} className={classes.column}>
              <BooleanInput source="sections.pricing.yearly.active" label="Actif" />
              <BooleanInput source="sections.pricing.yearly.isFeatured" label="Mis en avant (populaire)" />
              <TextInput source={`sections.pricing.yearly.price.${lang}`} label="Tarif (€)" className={classes.textInput} />
              <TextInput source={`sections.pricing.yearly.periodicity.${lang}`} label="Label périodicité (par mois)" className={classes.textInput} />
              <TextInput source={`sections.pricing.yearly.label.${lang}`} label="Label tarification" className={classes.textInput} />

              <ArrayInput source="sections.pricing.yearly.includes" label="Mensuel" style={{ width: '100%' }}>
                <SimpleFormIterator>
                  <TextInput source={`text.${lang}`} label="Description" className={classes.textInput} />
                </SimpleFormIterator>
              </ArrayInput>
              <TextInput source={`sections.pricing.yearly.buttonLabel.${lang}`} label="Libellé bouton" className={classes.textInput} />
            </Grid>
          </Grid>
        </FormTab>

        <FormTab label="Catégories">
          <TextInput source={`sections.categories.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.categories.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />

          <ArrayInput source="sections.categories.items" label="Items" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ImageInput source="image" label="Icône" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Arguments">
          <TextInput source={`sections.arguments.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.arguments.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />

          <ArrayInput source="sections.arguments.items" label="Descriptions" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
              <TextInput source={`text.${lang}`} label="Texte" className={classes.textInput} />
              <ImageInput source="image" label="Photo d'aperçu" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Témoignages">
          <TextInput source={`sections.testimonials.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.testimonials.subtitle.${lang}`} label="Sous-titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="FAQ">
          <TextInput source={`sections.faq.${lang}.title`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.faq.${lang}.subtitle`} label="Sous-titre" className={classes.textInput} />
          <ArrayInput source={`sections.faq.${lang}.items`} label="Liste des questions" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="question" label="Question" className={classes.textInput} />
              <TextInput source="answer" label="Réponse" className={classes.textInput} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Bloc Pass">
          <TextInput source={`sections.passBlock.title.${lang}`} label="Titre" className={classes.textInput} />
          <TextInput source={`sections.passBlock.buttonLabel.${lang}`} label="Libellé bouton d'action" className={classes.textInput} />

          <ImageInput source="sections.passBlock.imageLeft" label="Photo à gauche" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>

          <ImageInput source="sections.passBlock.imageRight" label="Photo à droite" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
      </VerticalTabbedForm>
    </MyEdit>
  );
};

PassFullAccessPageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default PassFullAccessPageEditForm;
