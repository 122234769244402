import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Badge, Typography, makeStyles, useMediaQuery,
} from '@material-ui/core';
import {
  MenuItemLink, getResources, useTranslate,
} from 'react-admin';

import DashboardIcon from '@material-ui/icons/Dashboard';
import DefaultIcon from '@material-ui/icons/ViewList';
import BackupIcon from '@material-ui/icons/Backup';
import { useFirebase } from '../../firebase';

const useBadgeStyles = makeStyles(() => ({
  badgeRoot: {
    width: '100%',
  },
  badge: {
    top: 0,
    right: -5,
  },
}));

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '0.5em',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '1.5em',
    },
    padding: 10,
  },
  menuItem: {
    fontSize: '0.9rem',
    padding: 10,
    fontWeight: 400,
    color: 'white',
  },
  menuItemActive: {
    fontWeight: '600',
    color: '#5383ff',
    background: 'rgba(71, 98, 130, 0.5)',
    // borderRadius: '0 22px 22px 0',
  },
}), { name: 'RaMenu' });

const ConditionalBadge = ({
  active, children, value,
}) => {
  const classes = useBadgeStyles();

  if (active && value && value > 0) {
    return (
      <Badge color="primary" badgeContent={value} classes={{ root: classes.badgeRoot, badge: classes.badge }}>
        {children}
      </Badge>
    );
  }

  return children;
};

const MyMenu = (props) => {
  const {
    className,
    classes: classesOverride,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    ...rest
  } = props;

  const [stats, setStats] = React.useState(0);

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual);
  const t = useTranslate();
  const classes = useStyles(props);

  const firebase = useFirebase();

  // Used to force redraw on navigation
  useSelector((state) => state.router.location.pathname);

  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  React.useEffect(() => {
    const unsubscribeAdminStats = firebase.adminStats().onSnapshot((adminStatsSnapshot) => {
      setStats(adminStatsSnapshot.data());
    });

    return () => {
      if (unsubscribeAdminStats) {
        unsubscribeAdminStats();
      }
    };
  }, [firebase]);

  const menuItems = {};

  resources.forEach((resource) => {
    menuItems[resource.options.section] = menuItems[resource.options.section] || [];
    menuItems[resource.options.section].push(resource);
  });

  return (
    <div className={classnames(classes.main, className)} {...rest}>
      <MenuItemLink
        dense
        onClick={onMenuClick}
        to="/"
        primaryText={t('ra.page.dashboard')}
        leftIcon={<DashboardIcon style={{ fontSize: '1rem', color: 'white' }} />}
        exact
        className={classes.menuItem}
        classes={{ active: classes.menuItemActive }}
        sidebarIsOpen={open}
      />

      {Object.keys(menuItems).map((section, index) => (
        <div key={index} style={{ marginTop: 20 }}>
          <Typography style={{ marginBottom: 10 }}>{t(`menu.sections.${section}`)}</Typography>

          {menuItems[section].map((resource) => (
            <MenuItemLink
              dense
              key={resource.name}
              to={(resource.options && resource.options.link) || `/${resource.name}`}
              primaryText={(
                <ConditionalBadge active={resource.options.badgeFieldName} value={stats.unreviewedComments}>
                  {(resource.options && resource.options.label) || resource.name}
                </ConditionalBadge>
              )}
              leftIcon={
                resource.icon ? <resource.icon style={{ fontSize: '1rem', color: 'white' }} /> : <DefaultIcon style={{ fontSize: '1rem' }} />
              }
              onClick={onMenuClick}
              className={classes.menuItem}
              classes={{ active: classes.menuItemActive }}
              sidebarIsOpen={open}
            />
          ))}
        </div>
      ))}

      {/* resources.map((resource) => (
        <MenuItemLink
          dense
          key={resource.name}
          to={(resource.options && resource.options.link) || `/${resource.name}`}
          primaryText={(
            <ConditionalBadge active={resource.options.badgeFieldName} value={stats.unreviewedComments}>
              {(resource.options && resource.options.label) || resource.name}
            </ConditionalBadge>
          )}
          leftIcon={
            resource.icon ? <resource.icon style={{ fontSize: '1rem' }} /> : <DefaultIcon style={{ fontSize: '1rem' }} />
          }
          onClick={onMenuClick}
          className={classes.menuItem}
          classes={{ active: classes.menuItemActive }}
        />
      )) */}

      <MenuItemLink
        to="/deployments"
        primaryText="Déploiements"
        onClick={onMenuClick}
        leftIcon={<BackupIcon style={{ fontSize: '1rem', color: 'white' }} />}
        className={classes.menuItem}
        classes={{ active: classes.menuItemActive }}
      />

      {isXSmall && logout}
    </div>
  );
};

MyMenu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  hasDashboard: PropTypes.bool,
  dense: PropTypes.any,
  logout: PropTypes.any.isRequired,
  onMenuClick: PropTypes.func,
};

MyMenu.defaultProps = {
  classes: null,
  className: null,
  dense: null,
  hasDashboard: false,
  onMenuClick: () => {},
};

export default MyMenu;
