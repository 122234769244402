import PropTypes from 'prop-types';
import React from 'react';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core';

import {
  Edit,
  FormTab,
  TextInput,
} from 'react-admin';

import Config from '../../../../../config';
import EditToolbar from '../../../../common/EditToolbar';
import PageTitle from '../PageTitle';
import TabbedFormTabs from '../../../../layout/TabbedFormTabs';
import { VerticalTabbedForm } from '../../../../layout/VerticalTabbedForm';
import MyEdit from '../../../../layout/MyEdit';

const useStyles = makeStyles(() => ({
  textInput: {
    width: '100%',
  },
}));

const DashboardPageEditForm = ({ lang, ...props }) => {
  const classes = useStyles();

  return (
    <MyEdit {...props} title={<PageTitle />}>
      <VerticalTabbedForm
        toolbar={<EditToolbar />}
        redirect={false}
        tabs={<TabbedFormTabs />}
        variant="outlined"
      >
        <FormTab label="Général">
          <TextInput disabled source="id" />

          <TextInput source={`title.${lang}`} label="Titre" className={classes.textInput} />
        </FormTab>

        <FormTab label="Incentive">
          <TextInput source={`incentive.title.${lang}`} label="Titre" className={classes.textInput} />
          <RichTextInput source={`incentive.text.${lang}`} label="Texte" className={classes.textInput} toolbar={Config.richTextToolbar} />
          <TextInput source={`incentive.buttonLabel.${lang}`} label="Texte du bouton" className={classes.textInput} />
        </FormTab>

      </VerticalTabbedForm>
    </MyEdit>
  );
};

DashboardPageEditForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default DashboardPageEditForm;
