import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Confirm, crudGetAll as crudGetAllAction, crudUpdate as crudUpdateAction } from 'react-admin';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import Config from '../../../../config';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1em',
    marginBottom: '1em',
  },
  rightAlignedCell: { textAlign: 'right' },
  boldCell: { fontWeight: 'bold' },
  button: {
    margin: theme.spacing(1),
  },
}));

const UserClassesList = ({ basePath, allClasses, crudGetAll, crudUpdate, record, resource, userClasses }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [classToDelete, setClassToDelete] = React.useState(null);

  const classes = useStyles();

  React.useEffect(() => {
    crudGetAll('content/mentorshow/classes', { page: 1, perPage: 100 }, {}, 100);
  }, [crudGetAll]);

  if (!record) {
    return null;
  }

  const handleAddClass = async (classId) => {
    crudUpdate(
      resource,
      record.id,
      {
        ...record,
        classes: {
          ...record.classes,
          available: [...record.classes?.available, classId],
        },
      },
      record,
      basePath,
    );
    
    //TODO:Replace with kinesis
    // fetch(`${Config.parseServer.apiUrl}/class-pass-events`, {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     event: 'class',
    //     uid: record.id,
    //     classId,
    //     active: true,
    //     date: new Date(),
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });

    setIsModalOpen(false);
  };

  const openDeleteConfirm = (classObject) => {
    setClassToDelete(classObject);
    setIsConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setClassToDelete(null);
    setIsConfirmOpen(false);
  };

  const handleDeleteClass = () => {
    crudUpdate(
      resource,
      record.id,
      {
        ...record,
        classes: {
          ...record.classes,
          available: record.classes?.available?.filter((item) => item !== classToDelete.id),
        },
      },
      record,
      basePath,
    );

    //TODO:Replace with kinesis
    // fetch(`${Config.parseServer.apiUrl}/class-pass-events`, {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     event: 'class',
    //     uid: record.id,
    //     classId: classToDelete.id,
    //     active: false,
    //     date: new Date(),
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });

    setIsConfirmOpen(false);
  };

  return (
    <Paper className={classes.container} style={{ borderRadius: 0 }}>
      <Typography variant="subtitle1" gutterBottom>
        Cours
      </Typography>
      {userClasses.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Mentor</Typography>
              </TableCell>
              <TableCell>
                <Typography>Enseigne</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {userClasses.map((classObject, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography>{classObject.mentor.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{classObject.mentor.teaches.fr}</Typography>
                </TableCell>
                <TableCell>
                  <Button onClick={() => openDeleteConfirm(classObject)}>
                    <DeleteIcon style={{ color: 'red' }} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <Button color="primary" className={classes.button} style={{ border: ' 1px solid #5383ff', fontWeight: '400', color: '#5383ff' }} onClick={() => setIsModalOpen(true)}>
        Ajouter un cours
      </Button>

      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} aria-labelledby="simple-dialog-title">
        <DialogTitle id="simple-dialog-title">Ajouter un cours</DialogTitle>
        <DialogContent>
          <div id="alert-dialog-description" style={{ fontFamily: 'sans-serif' }}>
            <p style={{ margin: '0px' }}>Sélectionnez le cours que vous souhaitez ajouter à l'utilisateur</p>
            <List>
              {allClasses.map((classObject) => (
                <ListItem button onClick={() => handleAddClass(classObject.id)} key={classObject.id}>
                  <ListItemText primary={`${classObject?.mentor?.name} ${classObject?.mentor?.teaches.fr}`} />
                </ListItem>
              ))}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>
            <span>Annuler</span>
          </Button>
        </DialogActions>
      </Dialog>

      {!!classToDelete && (
        <Confirm
          isOpen={isConfirmOpen}
          title={`Révoquer l'accès au cours ${classToDelete.mentor.name}`}
          content={`Êtes-vous sûr de voiloir supprimer l'accès à cet utilisateur au cours ${classToDelete.mentor.name} ?`}
          onConfirm={handleDeleteClass}
          onClose={closeDeleteConfirm}
        />
      )}
    </Paper>
  );
};

UserClassesList.propTypes = {
  allClasses: PropTypes.array,
  basePath: PropTypes.any.isRequired,
  crudGetAll: PropTypes.func.isRequired,
  crudUpdate: PropTypes.func.isRequired,
  record: PropTypes.object,
  resource: PropTypes.any.isRequired,
  userClasses: PropTypes.array,
};

UserClassesList.defaultProps = {
  allClasses: [],
  record: null,
  userClasses: [],
};

const mapStateToProps = (state, ownProps) => {
  const allClasses = Object.values(state.admin.resources['content/mentorshow/classes'].data).filter((item) => item && item.id && ownProps.record.classes?.available?.indexOf(item.id) === -1);

  const userClasses = Object.keys(state.admin.resources['content/mentorshow/classes'].data)
    .filter((key) => ownProps.record.classes?.available?.indexOf(key) > -1)
    .map((key) => {
      const classObject = state.admin.resources['content/mentorshow/classes'].data[key];

      if (!classObject.id) {
        return null;
      }

      return classObject;
    });

  return {
    allClasses,
    userClasses,
  };
};

export default connect(mapStateToProps, { crudGetAll: crudGetAllAction, crudUpdate: crudUpdateAction })(UserClassesList);
