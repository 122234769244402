import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Route, useHistory } from 'react-router-dom';

import { Drawer, makeStyles } from '@material-ui/core';
import {
  Datagrid,
  List,
  Filter,
  Link,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

import ApproveButton from './utils/ApproveButton';
import CombinedNameField from '../../fields/CombinedNameField';
import CommentEdit from './CommentEdit';
import Config from '../../../config';
import DateTimeField from '../../fields/DateTimeField';
import { useFirebase } from '../../../firebase';
import MyList from '../../layout/MyList';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
}));

const PageFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      source="approved"
      label="Statut"
      choices={[{ id: true, name: 'Approuvés' }, { id: false, name: 'Non approuvés' }]}
      alwaysOn
    />

    <TextInput label="Cours" source="classId" alwaysOn />
  </Filter>
);

const CommentTextField = ({ record }) => {
  if (!record) {
    return null;
  }

  return (
    <>
      {record.parentId && (
        <h5>En réponse à <Link to={`/comments/${record.parentId}`}>{record.user.firstName} {record.user.lastName}</Link></h5>
      )}
      <p>{record.text}</p>
    </>
  );
};

const CommentLessonLinkField = ({ record }) => {
  if (!record) {
    return null;
  }

  return (
    <a
      href={`${Config.frontUrl}/programme/${record.classId}/lessons/${record.lessonId}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', color: '#5383ff' }}
    >
      {record.lessonId}
    </a>
  );
};

const CommentsList = (props) => {

  const classes = useStyles();
  const history = useHistory();

  const firebase = useFirebase();

  React.useEffect(() => {
    firebase.adminStats().set({
      unreviewedComments: 0,
    }, { merge: true });
  }, [firebase]);

  const handleClose = React.useCallback(() => {
    history.push('/comments');
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path="/comments/:id">
        {({ match }) => {
          const isMatch = !!(
            match
            && match.params
            && match.params.id !== 'create'
          );

          return (
            <>
              <MyList
                {...props}
                title="Commentaires"
                filters={<PageFilter />}
                className={classnames(classes.list, {
                  [classes.listWithDrawer]: isMatch,
                })}
              >
                <Datagrid>
                  <DateTimeField source="date" label="Date" />
                  <TextField source="classId" label="Cours" />

                  <CommentLessonLinkField source="lessonId" label="Leçon" />
                  <TextField source="lessonId" label="Leçon" />

                  <ReferenceField label="Utilisateur" source="user.id" reference="users">
                    <CombinedNameField source="name" />
                  </ReferenceField>

                  <CommentTextField source="text" label="Commentaire" />

                  <ApproveButton label="" />
                </Datagrid>
              </MyList>

              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {isMatch ? (
                  <CommentEdit
                    id={match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </>
          );
        }}
      </Route>
    </div>
  );
};

CommentLessonLinkField.propTypes = {
  record: PropTypes.object,
};

CommentLessonLinkField.defaultProps = {
  record: null,
};

CommentTextField.propTypes = {
  record: PropTypes.object,
};

CommentTextField.defaultProps = {
  record: null,
};

export default CommentsList;
