import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { crudGetOne as crudGetOneAction } from 'react-admin';

import { makeStyles } from '@material-ui/core';

import UserClassesList from './UserClassesList';
import UserCurrentWatchingList from './UserCurrentWatchingList';
import UserEditAsideActions from './UserEditAsideActions';
import UserPass from './UserPass';
import UserTransactionsList from './UserTransactionsList';
import UserPassMsplus from './UserPassMsplus';
import UserPassBundle from './UserPassBundle';

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    marginLeft: '1em',
    marginTop: '1em',
    padding: '1em',
  },
  rightAlignedCell: { textAlign: 'right' },
  boldCell: { fontWeight: 'bold' },
}));

const UserEditAside = ({ crudGetOne, ...props }) => {
  const { record } = props;

  const classes = useStyles();

  if (!record) {
    return null;
  }

  return (
    <div className={classes.container}>
      <UserEditAsideActions {...props} />
      <UserPass {...props} />
      <UserPassMsplus {...props} />
      <UserPassBundle {...props} />
      <UserClassesList {...props} />
      <UserCurrentWatchingList {...props} />
      {/* <UserTransactionsList {...props} /> */}
    </div>
  );
};

UserEditAside.propTypes = {
  basePath: PropTypes.any.isRequired,
  crudGetOne: PropTypes.func.isRequired,
  record: PropTypes.any,
  resource: PropTypes.string.isRequired,
};

UserEditAside.defaultProps = {
  record: null,
};

export default connect(null, { crudGetOne: crudGetOneAction })(UserEditAside);
